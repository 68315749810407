import React, { useState, useEffect } from "react";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
import { message } from "antd";
import { getCurrentUser, studentRegisterWithGoogle } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { GET_CURRENT_USER } from "../../actions/types";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const GoogleAuthentication = ({ getCurrentUser }) => {
  const [onSuccessGoogle, setOnSuccessGoogle] = useState({});
  const [profile, setProfile] = useState({});
  const clientId = "588111276925-r1ungh1vbfau303p8urch3icn3brfu98.apps.googleusercontent.com";

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: clientId,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);
  const onSuccess = (res) => {
    setOnSuccessGoogle(res);
    setProfile(res.profileObj);
  };

  console.log(onSuccessGoogle);

  useEffect(() => {
    if (profile) {
      const content = {
        // userName: data?.userName,
        fullName: profile?.name,
        email: profile?.email,
        phoneNumber: "01111111111",
        password: "12341234",
      };

      if (content.email) {
        studentRegisterWithGoogle(content)
          .then((res) => {
            if (res?.data?.success) {
              message.success(res?.data?.message);
              console.log(res?.data?.data?._id);

              getCurrentUser(res?.data?.data?._id);
              typeof window !== undefined &&
                localStorage.setItem("userId", res?.data?.data?._id);

              dispatch({
                type: GET_CURRENT_USER,
                payload: res?.data?.data,
              });

              sessionStorage.getItem("redirectURL")
                ? history.push(sessionStorage.getItem("redirectURL"))
                : history.push("/");
              // history.push(`/`);
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message === undefined) {
              message.error(err?.response?.data);
            } else {
              message.error(err?.response?.data?.message);
            }
          });
      }
    }
  }, [profile]);

  const onFailure = (err) => {
    console.log("failed:", err);
  };

  return (
    <div>
      <GoogleLogin
        clientId={clientId}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
      />
    </div>
  );
};

GoogleAuthentication.prototype = {
  getCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentUser })(GoogleAuthentication);
