import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import "./SupportTicket.css";
import { useSelector } from "react-redux";
import { message } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { UploadOutlined } from "@ant-design/icons";
import { publicImageUpload } from "../../actions/fileUpload";

const SupportTicketList = () => {
  const [supportSubject, setSupportSubject] = useState("");
  const [supportMessage, setSupportMessage] = useState("");
  const [supportTickets, setSupportTickets] = useState([]);
  const [certificate, setCertificate] = useState(null);
  const [change, setChange] = useState(true);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(null);
  const [supportImage, setSupportImage] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DEV_URL}/api/support/user/get-all/${user?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res?.json())
      .then((data) => {
        if (data?.success) {
          setSupportTickets(data?.data);
        } else {
          //   message.error(data?.message, 3, "Support Ticket");
        }
      })
      .catch((err) => {
        // message.error(err?.response?.data?.message, "Review");
      });
  }, [user, change]);

  const handleSubmit = () => {
    if (supportSubject === "" || supportMessage === "") {
      alert("Please, Fill all the required field");
      return;
    }
    const data = {
      userId: user?._id,
      subject: supportSubject,
      message: supportMessage,
      image: certificate,
    };

    fetch(`${process.env.REACT_APP_DEV_URL}/api/support/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => res?.json())
      .then((data) => {
        if (data?.success) {
          message.success(data?.message, 3, "Support Ticket");
          setSupportMessage("");
          setSupportSubject("");
          setChange(!change);
        } else {
          message.error(data?.message, 3, "Support Ticket");
          setSupportMessage("");
          setSupportSubject("");
          setChange(!change);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message, "Support Ticket");
      });
  };

  const handleCertificateUpload = (e) => {
    setError(null);
    const extension = e.target.files[0]?.name?.split(".").pop();

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG" ||
      extension === "pdf" ||
      extension === "PDF"
    ) {
      setStatus(true);
      publicImageUpload(e)
        .then((res) => res.json())
        .then((data) => {
          setSupportImage(data?.Location);
          if (data?.success === undefined) {
            setCertificate(data?.Location);
            setStatus(false);
          } else {
            if (!data?.success) {
              setStatus(false);
              message.error(data?.message);
            } else {
              // setCertificate(data?.key);
              setCertificate(data?.Location);
              setStatus(false);
            }
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message === undefined) {
            message.error(err?.response?.data);
          } else {
            message.error(err?.response?.data?.message);
          }
        });
    } else {
      setError("Unknown type! try to select png, jpg or pdf type!");
    }
  };

  const fileExtension =
    certificate !== null &&
    certificate?.substr(certificate?.lastIndexOf(".") + 1);
  const certificateViewer = () => {
    if (fileExtension !== undefined) {
      if (
        fileExtension === "png" ||
        fileExtension === "PNG" ||
        fileExtension === "jpg" ||
        fileExtension === "JPG" ||
        fileExtension === "jpeg" ||
        fileExtension === "JPEG"
      ) {
        return (
          <img
            // width={200}
            style={{
              width: "100px",
              height: "90px",
            }}
            src={certificate}
            // src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
            alt="SupportImage"
          />
        );
      } else {
        return (
          <>
            {/* <Document
              file={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document> */}

            <a
              style={{
                background: "#38aba1",
                display: "inline-block",
                color: "white",
                padding: "10px 20px",
                borderRadius: "6px",
              }}
              href={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
              download
            >
              Download Your Support Image
            </a>
          </>
        );
      }
    }
  };

  return (
    <Layout footer={true}>
      <div className="mt-2 mb-5 supportTicketListDiv">
        <div className="d-flex justify-content-between">
          <h3 className="mt-4">Support Ticket</h3>

          <button
            type="button"
            className="newSupportTicketButton"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            New Support Ticket
          </button>

          <div
            className="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div
              id="supportModal"
              className="modal-dialog modal-dialog-centered"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Support Ticket
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body supportTicketModal">
                  <label>
                    Subject{" "}
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <input
                    type="text"
                    placeholder="Enter subject"
                    onChange={(e) => {
                      setSupportSubject(e.target.value);
                    }}
                  ></input>
                  <label>
                    Message{" "}
                    <span
                      style={{
                        color: "red",
                      }}
                    >
                      *
                    </span>
                  </label>
                  <textarea
                    name="Message"
                    onChange={(e) => {
                      setSupportMessage(e.target.value);
                    }}
                    rows="3"
                    cols="10"
                    className="input2"
                    type="textarea"
                    placeholder="Your Question"
                  ></textarea>

                  <label htmlFor="certificate">
                    <p>
                      Upload your question{" "}
                      <span className="ms-2">
                        <UploadOutlined />
                      </span>
                    </p>
                  </label>
                  <input
                    onChange={(e) => handleCertificateUpload(e)}
                    style={{ display: "none" }}
                    id="certificate"
                    type="file"
                    accept=".png, .jpg, .jpeg, .pdf, .zip"
                  />

                  {/* {error && <span className="error">{error}</span>} */}

                  <div style={{ marginTop: "5px" }}>
                    {certificate !== undefined
                      ? certificate !== null
                        ? certificateViewer()
                        : null
                      : null}
                  </div>
                </div>

                <div className="modal-footer">
                  {/* <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Send
                  </button> */}

                  <button
                    style={{ backgroundColor: status ? "gray" : "#21d093" }}
                    type="submit"
                    disabled={status ? true : false}
                    className="btn btn-primary"
                    onClick={handleSubmit}
                  >
                    {status ? "Uploading..." : "Send"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {supportTickets.length > 0 ? (
          <div className="table-responsive mt-3 table-bordered border-primary">
            <table className=" table supportTicketTable">
              <thead className="">
                <th>#</th>
                <th>Subject</th>
                <th>Message</th>
                <th>Action</th>
              </thead>

              <tbody>
                {supportTickets?.map((ticket, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>
                        {ticket.subject.length > 10
                          ? ticket.subject.slice(0, 10) + "..."
                          : ticket.subject}
                      </td>
                      <td>
                        {ticket.message.length > 10
                          ? ticket.message.slice(0, 10) + "..."
                          : ticket.message}
                      </td>
                      <td>
                        <button
                          className="replyButton"
                          onClick={() => {
                            history.push(`/supportTicketDetails/${ticket._id}`);
                          }}
                        >
                          See Reply
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="noSupportTicketDiv">
            <h5 className="text-danger">No support tickets right now</h5>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default SupportTicketList;
