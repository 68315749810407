import React from 'react';
import '../../pages/LandingPage/LandingPage.css';
import RegisteredStudent from '../../images/registered-students.svg';
import RegisteredTeacher from '../../images/registered-teacher.svg';
import Visitors from '../../images/visitors.svg';

const Overview = ({ information }) => {
  const overviewComponents = (path, number, title) => {
    return (
      <div>
        <img src={path} alt={title} width={130} height={130} />
        <h4>{number}</h4>
        <p>{title}</p>
      </div>
    );
  };

  return (
    <div className="overview">
      <div className="overview__wrapper container">
        {overviewComponents(
          RegisteredStudent,
          information?.studentCounts + '+',
          'Registered Students'
        )}
        {overviewComponents(
          RegisteredTeacher,
          information?.instructorCounts + '+',
          'Registered Teachers'
        )}
        {overviewComponents(
          Visitors,
          information?.information?.visitors + '+',
          'Total Visitors'
        )}
      </div>
    </div>
  );
};

export default Overview;
