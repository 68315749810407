import React, { useState, useEffect } from 'react';
import { UploadOutlined, CameraOutlined } from '@ant-design/icons';
import { imageUpload, certificateUpload } from '../../actions/fileUpload';
import { updateProfile } from '../../actions/profile';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { getCurrentUser } from '../../actions/auth';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Image } from 'antd';
import '../../pages/Profile/Profile.css';
import CourseImage from '../../images/course.jpg';
import { Document, Page } from 'react-pdf';

const ProfileForm = ({ getCurrentUser }) => {
  const [username, setUsername] = useState('');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [nidNumber, setNidNumber] = useState('');
  const [image, setImage] = useState(null);
  const [certificate, setCertificate] = useState(null);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(null);
  const [profileImageError, setProfileImageError] = useState(null);
  const [mobileNumberError, setMobileNumberError] = useState(null);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { user } = useSelector((state) => state.auth);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setUsername(user?.userName);
    setFullName(user?.fullName);
    setEmail(user?.email);
    setMobileNumber(user?.phoneNumber);
    setNidNumber(user?.NID);
    setImage(user?.profile_picture);
    setCertificate(user?.Upload_academic_certificate);
  }, [user]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // if (mobileNumber?.length !== 11) return setMobileNumberError(true);

    const values = {
      fullName,
      userName: username,
      email,
      phoneNumber: mobileNumber,
      NID: nidNumber,
      Upload_academic_certificate: certificate,
      profile_picture: image,
    };

    updateProfile(user?._id, values)
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
          getCurrentUser(user?._id);
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message === undefined) {
          message.error(err?.response?.data);
        } else {
          message.error(err?.response?.data?.message);
        }
      });
  };

  const handleProfileImageUpload = (e) => {
    const extension = e.target.files[0]?.name?.split('.').pop();

    if (
      extension === 'png' ||
      extension === 'PNG' ||
      extension === 'jpg' ||
      extension === 'JPG' ||
      extension === 'jpeg' ||
      extension === 'JPEG'
    ) {
      setStatus(true);
      imageUpload(e)
        .then((res) => res.json())
        .then((data) => {
          if (data?.success === undefined) {
            setImage(data?.key);
            setStatus(false);
          } else {
            if (!data?.success) {
              setStatus(false);
              message.error(data?.message);
            } else {
              setImage(data?.key);
              setStatus(false);
            }
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message === undefined) {
            message.error(err?.response?.data);
          } else {
            message.error(err?.response?.data?.message);
          }
        });
    } else {
      setProfileImageError(
        'Unknown type! try to select jpg, png or jpeg type!'
      );
    }
  };

  const handleCertificateUpload = (e) => {
    setError(null);
    const extension = e.target.files[0]?.name?.split('.').pop();

    if (
      extension === 'png' ||
      extension === 'PNG' ||
      extension === 'jpg' ||
      extension === 'JPG' ||
      extension === 'jpeg' ||
      extension === 'JPEG' ||
      extension === 'pdf' ||
      extension === 'PDF'
    ) {
      setStatus(true);
      certificateUpload(e)
        .then((res) => res.json())
        .then((data) => {
          if (data?.success === undefined) {
            setCertificate(data?.key);
            setStatus(false);
          } else {
            if (!data?.success) {
              setStatus(false);
              message.error(data?.message);
            } else {
              setCertificate(data?.key);
              setStatus(false);
            }
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message === undefined) {
            message.error(err?.response?.data);
          } else {
            message.error(err?.response?.data?.message);
          }
        });
    } else {
      setError('Unknown type! try to select png, jpg or pdf type!');
    }
  };

  const fileExtension =
    certificate !== null &&
    certificate?.substr(certificate?.lastIndexOf('.') + 1);

  const certificateViewer = () => {
    if (fileExtension !== undefined) {
      if (
        fileExtension === 'jpg' ||
        fileExtension === 'jpeg' ||
        fileExtension === 'png'
      ) {
        return (
          <Image
            width={200}
            src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
            alt="Certificate"
          />
        );
      } else {
        return (
          <>
            {/* <Document
              file={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page pageNumber={pageNumber} />
            </Document> */}

            <a
              style={{
                background: '#38aba1',
                display: 'inline-block',
                color: 'white',
                padding: '10px 20px',
                borderRadius: '6px',
              }}
              href={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
              download
            >
              Download Your Last Academic Certificate
            </a>
          </>
        );
      }
    }
  };

  return (
    <div className="profileForm container">
      <div className="profileForm__wrapper">
        <form onSubmit={handleSubmit}>
          <div className="profileForm__imageDiv__wrapper">
            <div className="profileForm__image__wrapper">
              <Image
                src={
                  image !== null
                    ? image !== undefined
                      ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${image}`
                      : CourseImage
                    : CourseImage
                }
                alt="Profile"
              />
            </div>

            <label htmlFor="profile">
              <div className="profileFrom__image__collect">
                <CameraOutlined />
              </div>
            </label>
          </div>

          <input
            onChange={(e) => handleProfileImageUpload(e)}
            style={{ display: 'none' }}
            id="profile"
            type="file"
            accept=".png, .jpg, .jpeg"
          />

          {profileImageError && (
            <span
              style={{
                display: 'block',
                marginTop: '50px',
                textAlign: 'center',
              }}
              className="error"
            >
              {profileImageError}
            </span>
          )}
          <div style={{ paddingTop: '75px' }}>
            <label className="profile__label" htmlFor="name">
              Username
            </label>
            <input
              id="name"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              placeholder="Username"
              required
            />
            <label className="profile__label" htmlFor="name">
              Name
            </label>
            <input
              id="name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              type="text"
              placeholder="Full Name"
              required
            />
            <label className="profile__label" htmlFor="email">
              Email
            </label>
            <input
              value={email}
              id="email"
              type="email"
              placeholder="Email"
              readOnly
            />
            <label className="profile__label" htmlFor="mobileNumber">
              Mobile Number
            </label>
            <input
              id="mobileNumber"
              value={mobileNumber}
              onChange={(e) => {
                setMobileNumber(e.target.value);
                // setMobileNumberError(false);
              }}
              type="number"
              onWheel={(e) => e.target.blur()}
              min="0"
              placeholder="Mobile Number"
            />
            {/* {mobileNumberError && (
              <span style={{ marginBottom: '10px' }} className="error">
                Number should be 11 characters
              </span>
            )} */}
            <label className="profile__label" htmlFor="NID">
              NID Number
            </label>
            <input
              id="NID"
              value={nidNumber}
              onChange={(e) => setNidNumber(e.target.value)}
              type="number"
              onWheel={(e) => e.target.blur()}
              min="0"
              placeholder="NID Number"
            />

            <label className="profile__label" htmlFor="">
              Certificate
            </label>

            <label htmlFor="certificate">
              <p>
                Upload your last academic certificate{' '}
                <span className='ms-2'>
                  <UploadOutlined />
                </span>
              </p>
            </label>
            <input
              onChange={(e) => handleCertificateUpload(e)}
              style={{ display: 'none' }}
              id="certificate"
              type="file"
              accept=".png, .jpg, .jpeg, .pdf, .zip"
            />

            {/* {error && <span className="error">{error}</span>} */}

            <div style={{ marginTop: '15px' }}>
              {certificate !== undefined
                ? certificate !== null
                  ? certificateViewer()
                  : null
                : null}
            </div>
            {/* <input type="password" placeholder="Password" />
            <input type="password" placeholder="Confirm Password" /> */}

            <div className="profileForm__wrapper__button">
              <button
                style={{ backgroundColor: status ? 'gray' : '#21d093' }}
                type="submit"
                disabled={status ? true : false}
              >
                {status ? 'Uploading...' : 'Save'}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

ProfileForm.prototype = {
  getCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentUser })(ProfileForm);
