import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Banner from "../../components/CoursePageComponents/Banner";
import CourseInfo from "../../components/CoursePageComponents/CourseInfo";
// import ReviewAndRatings from '../../components/SharedComponents/ReviewAndRatings';
// import UserReview from '../../components/SharedComponents/UserReview';
import { useParams } from "react-router-dom";
import {
  getSpecificBundleCourse,
  getSpecificCourse,
  getSpecificSection,
  applyCoupon,
} from "../../actions/course";
import "./CourseDetails.css";
import { Helmet } from "react-helmet";
import renderHTML from "react-render-html";
import { message } from "antd";
import { useSelector } from "react-redux";
import Countdown from "./CountDown";
const CourseDetails = () => {
  const [courseDetails, setCourseDetails] = useState(null);
  const [sectionDetails, setSectionDetails] = useState([]);
  const [previewLessons, setPreviewLessons] = useState([]);
  const [filterPreviewLessons, setFilterPreviewLessons] = useState([]);
  const [review, setReview] = useState("");

  const [coupon, setCoupon] = useState("");
  const [applied, setApplied] = useState(false);
  const [price, setPrice] = useState(
    courseDetails?.sale_price !== undefined
      ? courseDetails?.sale_price
      : courseDetails?.price
  );
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  useEffect(() => {
    setPrice(
      courseDetails?.sale_price !== undefined
        ? courseDetails?.sale_price
        : courseDetails?.price
    );
  }, [courseDetails]);

  const handleApply = () => {
    if (coupon) {
      applyCoupon(coupon, user?.email, courseDetails?._id)
        .then((res) => {
          if (res?.data?.success) {
            console.log("amount", res?.data?.message);
            let discount = res?.data?.message;
            let amount = res?.data?.amount ?? 0;
            discount = parseInt(discount.split(/(\s+)/)[2]);
            discount = Number(price) * (discount / 100);

            const finalPrice = Math.ceil(
              Number(price) - Number(discount) - Number(amount)
            );
            if (finalPrice <= 10) {
              message.error(
                "Course Price can not be less than 10tk even after using coupon. Contact support."
              );
            } else {
              setPrice(finalPrice);
              setApplied(true);
              message.success(`You have get ${discount + amount} TK discount`);
            }
          } else {
            message.error(res?.data?.message);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const { slug } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const bundle = urlParams.get("bundle");

  useEffect(() => {
    if (bundle === "true") {
      getSpecificBundleCourse(slug)
        .then((res) => {
          if (res?.data?.success) {
            setCourseDetails(res?.data?.data);
          }
        })
        .catch((err) => console.log(err));
    } else {
      getSpecificCourse(slug)
        .then((res) => {
          if (res?.data?.success) {
            setCourseDetails(res?.data?.data);
            setReview(res?.data?.reviewData);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [slug, bundle]);

  useEffect(() => {
    courseDetails?.chapter?.map((sec) => {
      getSpecificSection(sec).then((res) => {
        if (res?.data?.success) {
          setSectionDetails((prevSec) => [...prevSec, res?.data?.message]);
        }
      });
    });
  }, [courseDetails]);

  useEffect(() => {
    const key = "_id";
    const arrayUniqueByKey = [
      ...new Map(previewLessons.map((item) => [item[key], item])).values(),
    ];
    setFilterPreviewLessons(arrayUniqueByKey);
  }, [previewLessons]);

  useEffect(() => {
    sectionDetails?.map((sec) => {
      sec?.lessons?.length > 0 &&
        sec?.lessons?.map((les) => {
          if (les?.preview === true) {
            setPreviewLessons((prevLes) => [...prevLes, les]);
          }
        });
    });
  }, [sectionDetails]);

  const renderMetaTag = (courseDetails) => {
    return (
      <div>
        <meta property="og:title" content={courseDetails?.title} />
        <meta
          property="og:description"
          content={renderHTML(
            courseDetails?.course_short_description
              ? courseDetails?.course_short_description
              : ""
          )}
        />
        <meta
          property="og:image"
          content={courseDetails?.upload_featured_image}
        />
      </div>
    );
  };

  useEffect(() => {
    renderMetaTag(courseDetails);
  }, [courseDetails]);

  console.log("courseDetails", courseDetails);

  return (
    <Layout footer={true}>
      <Helmet>
        {/* <meta property="og:title" content={courseDetails?.title} />
        <meta
          property="og:description"
          content={renderHTML(
            courseDetails?.course_short_description
              ? courseDetails?.course_short_description
              : ""
          )}
        />
        <meta
          property="og:image"
          content={courseDetails?.upload_featured_image}
        /> */}

        <title>{courseDetails?.title}</title>
        <meta
          name="description"
          property="og:description"
          content={renderHTML(
            courseDetails?.course_short_description
              ? courseDetails?.course_short_description
              : ""
          )}
        />
        <meta
          name="image"
          property="og:image"
          content={courseDetails?.upload_featured_image}
        />

        {/* <meta
          name="keywords"
          
          content={renderHTML(
            courseDetails?.course_short_description
              ? courseDetails?.course_short_description
              : ""
          )}
        /> */}
      </Helmet>
      <div className="CoursePage">
        <div className="CoursePage__wrapper">
          <div className="CoursePage__wrapper__header">
            <h2>
              {courseDetails?.title !== undefined
                ? courseDetails?.title
                : courseDetails?.name}
            </h2>
          </div>
          {courseDetails?.visibleNotice ? (
            courseDetails?.noticeDateVisible ? (
              <div
                style={{
                  backgroundColor: "#11B166",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingTop: "0px",
                  paddingLeft: "15%",
                  paddingRight: "15%",
                  paddingBottom: "0px"
                }}
              >
                {" "}
                {renderHTML(
                  courseDetails?.courseNotice ? courseDetails?.courseNotice : ""
                )}
                {/* {courseDetails?.noticeEndDate?.slice(0, 10)} */}
                <Countdown targetDate={courseDetails?.noticeEndDate}></Countdown>
              </div>
            ) : (
              <div
                style={{
                    backgroundColor: "#11B166",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "20px",
                  direction: "rtl",
                }}
                className=" p-1 moving-text-container"
              >
                {/* <div className="moving-text-animation pt-3"> */}
                <div>
                    {renderHTML(
                      courseDetails?.courseNotice ? courseDetails?.courseNotice : ""
                    )}
                </div>
              </div>
            )
          ) : null}
          <Banner
            previewLessons={filterPreviewLessons}
            courseDetails={courseDetails}
            bundle={bundle}
          />
          <CourseInfo
            bundle={bundle}
            courseDetails={courseDetails}
            review={review}
            price={price}
            applied={applied}
            coupon={coupon}
            setCoupon={setCoupon}
            handleApply={handleApply}
          />
          {/* <ReviewAndRatings coursePage={true} /> */}
          {/* <UserReview /> */}
        </div>
      </div>
    </Layout>
  );
};
export default CourseDetails;
