import axios from 'axios';

export const checkCertificate = (body) => {
  return axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/check_certificate`,
    body
  );
};

export const getCertificate = (courseId, body) => {

  return axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/get_certificate/${courseId}`,
    body
  );
};

export const saveCertificate = (courseId, body) => {
  return axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/save_certificate/${courseId}`,
    body
  );
};
