import { getCurrentUser } from '../actions/auth';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const WrapperComponent = ({ children, getCurrentUser }) => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    if (typeof window !== undefined) {
      const id = localStorage.getItem('userId');
      setUserId(id);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      getCurrentUser(userId);
    }
  }, [userId]);

  return children;
};

WrapperComponent.prototype = {
  getCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentUser })(WrapperComponent);
