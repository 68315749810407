import React, { useEffect, useState } from 'react';
import Avatar from 'antd/lib/avatar/avatar';
import Layout from '../../components/Layout';
import { useSelector } from 'react-redux';
import './Certificate.css';
import { useParams } from 'react-router-dom';
import { getSpecificCourse } from '../../actions/course';
import { Helmet } from 'react-helmet';

function Certificate() {
  const [courseDetails, setCourseDetails] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { id } = useParams();

  useEffect(() => {
    getSpecificCourse(id)
      .then((res) => {
        if (res?.data?.success) {
          setCourseDetails(res?.data?.message);
        }
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <Layout>
      <Helmet>
        <title>Certificate</title>
        <meta name="description" property="og:description" content="" />
      </Helmet>
      <div className="certificate__wrapper container">
        <div className="certificate__wrapper__one">
          <img
            src="https://udemy-certificate.s3.amazonaws.com/image/UC-dfb531c9-0746-48f4-8d8e-a299b1aaf542.jpg?v=1630865735000"
            alt=""
          />
        </div>
        <div className="certificate__wrapper__two">
          <h3>Certificate Recipient:</h3>
          <div className="user_details">
            <Avatar
              src={
                user?.profile_picture !== undefined
                  ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${user?.profile_picture}`
                  : ""
              }
              alt=""
            />
            <p>{user?.fullName}</p>
          </div>
          <div className="courseDetails">
            <h3>About this course:</h3>
            <img
              style={{ border: "1px solid gray" }}
              width="240"
              height="135"
              src={
                courseDetails?.upload_featured_image !== undefined
                  ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${courseDetails?.upload_featured_image}`
                  : courseDetails?.image !== undefined
                  ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${courseDetails?.image}`
                  : "/course.jpg"
              }
              alt=""
            />
            <h4 style={{ marginTop: "6px" }}>
              {courseDetails?.title !== undefined
                ? courseDetails?.title
                : courseDetails?.name}
            </h4>
            <div style={{ display: "flex", alignItems: "center" }}>
              {courseDetails?.course_paid === false ? (
                <p style={{ fontSize: "14px", margin: "0" }}>Free</p>
              ) : (
                <p style={{ fontSize: "14px", margin: "0" }}>
                  <span>৳</span>
                  <span>
                    {courseDetails?.course_paid === false
                      ? "Free"
                      : courseDetails?.sale_price !== undefined
                      ? courseDetails?.sale_price
                      : courseDetails?.price}
                  </span>
                </p>
              )}
              <span style={{ margin: "0px 5px" }}>.</span>
              <p style={{ fontSize: "14px", margin: "0" }}>
                {courseDetails?.course_duration}
              </p>
            </div>
          </div>
          <div className="download_button">
            <button>Download</button>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Certificate;
