import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { Collapse, message } from "antd";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  checkCourseBlocked,
  getCourseCompletion,
  getMarkedLesson,
  getSpecificCourseById,
  markAsCompleted,
  updateLastLesson,
  getLastLesson,
} from "../../actions/course";
import { WarningFilled } from "@ant-design/icons";
import { Modal } from "antd";
import Lesson from "./Lesson";
import { useSelector } from "react-redux";
import { Progress } from "antd";
import "./Watching.css";
import handCraf from "../../images/handcarf.svg";
import renderHTML from "react-render-html";
import ReactPlayer from "react-player";
import BlockedPopup from "./BlockedPopup";
import useLessonHandler from "./useLessonHandler";
import useCertificateHandler from "./useCertificateHandler";
import { async } from "@firebase/util";
import { Helmet } from "react-helmet";

const { Panel } = Collapse;

const Watching = () => {
  const [path, setPath] = useState(null);
  const [activeSectionId, setActiveSectionId] = useState("");
  const [courseDetails, setCourseDetails] = useState(null);
  const [visible, setVisible] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [description, setDescription] = useState(null);
  const [url, setUrl] = useState(null);
  const [markedLessons, setMarkedLessons] = useState([]);
  const [courseCompletion, setCourseCompletion] = useState(0);
  const [completionStatus, setCompletionsStatus] = useState(false);
  const [currentNumber, setCurrentNumber] = useState(0);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [prevButtonDisabled, setPrevButtonDisabled] = useState(false);
  const [lesId, setLesId] = useState(null);
  const [status, setStatus] = useState(false);

  const [blocked, setBlocked] = useState(false);
  const [lastLesson, setLastLesson] = useState(null);
  const [findLessonLoading, setFindLessonLoading] = useState(true);
  const [coursePaid, setCoursePaid] = useState(true);
  const [isQuizPass, setIsQuizPass] = useState(false);

  const [isDurationOVer, setIsDurationOver] = useState(false);

  const [gotAllData, setGotAllData] = useState(false);
  const [goingToExpire, setIsGoingToExpire] = useState(false);

  const [index, setIndex] = useState(-1);

  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);

  const { sections, filterAllLessons } = useLessonHandler(courseDetails);
  const { savedCertificate, handleDownloadCertificate, handleGetCertificate } =
    useCertificateHandler(id, user);

  let history = useHistory();

  useEffect(() => {
    if (courseDetails && user?._id !== undefined && index >= 0) {
      var current_date = new Date();
      var course = user?.enrolled_courses[index];

      // console.log("index", index);

      // console.log("enrolled course", course);
      var year = course?.buyingDate?.slice(0, 4);
      var month = course?.buyingDate?.slice(5, 7);
      var date = course?.buyingDate?.slice(8, 10);

      // console.log(date, month, year);

      const boughtDate = new Date(`${month}/${date}/${year}`);

      // console.log(course?.buyingDate, boughtDate);
      var diff = current_date.getTime() - boughtDate?.getTime();

      var dayDifference = diff / (1000 * 60 * 60 * 24);

      // console.log("current Date", current_date);
      // console.log("boughtDate", boughtDate);
      // console.log("dayDifference", dayDifference);
      // console.log("valid Days", courseDetails?.validDays);
      if (
        courseDetails?.validDays - dayDifference > 0 &&
        courseDetails?.validDays - dayDifference < 2
      ) {
        console.log("going to expire");
        setIsGoingToExpire(true);
      }
      if (dayDifference > parseInt(courseDetails?.validDays)) {
        console.log("duration over");
        setIsDurationOver(true);
      }
    }
  }, [user, courseDetails, index]);

  useEffect(() => {
    if (id) {
      getSpecificCourseById(id)
        .then((res) => {
          if (res?.data?.success) {
            setCourseDetails(res?.data?.message);
            setCoursePaid(res.data.message.course_paid);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  // Get Section Details

  useEffect(() => {
    if (courseDetails && user?._id !== undefined) {
      getMarkedLesson(user?._id, courseDetails?._id)
        .then((res) => {
          if (res?.data?.success) {
            setMarkedLessons(res?.data?.message[0]?.lessons);
            setGotAllData(true);
            setStatus(false);
          }
        })
        .catch((err) => console.log(err));

      user?.enrolled_courses.map((course, i) => {
        // console.log("course", course?._id, courseDetails?._id);
        if (course._id === courseDetails?._id) {
          setIndex(i);
        }
      });
    }
  }, [user, courseDetails, status]);

  // console.log("index", index);

  useEffect(() => {
    if (user?._id !== undefined) {
      getCourseCompletion(user?._id, id).then((res) => {
        if (res?.data?.success) {
          setCourseCompletion(res?.data?.message);
          setCompletionsStatus(false);
          setStatus(false);
        }
      });
    }
  }, [id, user, status, completionStatus]);

  useEffect(() => {
    if (!findLessonLoading) {
      if (
        lastLesson !== "changing" &&
        lastLesson !== undefined &&
        lastLesson !== null
      ) {
        const findLes = filterAllLessons?.find((fl) => fl?._id === lastLesson);

        if (findLes && findLes !== undefined) {
          setUrl(findLes?.url);
          setPath(`_${findLes?._id}`);
          setLesId(findLes?._id);
          setVideoTitle(findLes?.title);
          setDescription(findLes?.description);
        }
      } else {
        const now =
          filterAllLessons?.length > 0
            ? filterAllLessons[currentNumber]
            : undefined;

        if (now !== undefined) {
          setUrl(now?.url);
          setPath(`_${now?._id}`);
          setLesId(now?._id);
          setVideoTitle(now?.title);
          setDescription(now?.description);

          updateLastLesson(courseDetails?._id, user?._id, now?._id)
            .then((res) => {
              setLastLesson(null);
            })
            .catch((err) => console.log(err));
        }
      }
    }
  }, [
    currentNumber,
    findLessonLoading,
    lastLesson,
    courseDetails,
    user,
    filterAllLessons,
  ]);

  // Handle Previous Function
  const handlePrevious = () => {
    setLastLesson("changing");
    setPrevButtonDisabled(false);
    setNextButtonDisabled(false);
    const totalNumber = filterAllLessons?.length - 1;
    if (totalNumber >= currentNumber) {
      setCurrentNumber(currentNumber - 1);
    }
  };

  // Handle Next Function
  const handleNext = () => {
    setLastLesson("changing");
    setPrevButtonDisabled(false);
    setNextButtonDisabled(false);
    const totalNumber = filterAllLessons?.length - 1;
    if (currentNumber < totalNumber) {
      setCurrentNumber(currentNumber + 1);
    }
  };

  //** Get Last lesson from the backend */
  useEffect(() => {
    if (lastLesson !== "changing" && courseDetails && user) {
      setFindLessonLoading(true);
      getLastLesson(courseDetails?._id, user?._id)
        .then((res) => {
          if (res?.data?.success) {
            setLastLesson(res?.data?.data?.lesson_id);
            setFindLessonLoading(false);
          } else {
            setLastLesson(null);
            setFindLessonLoading(false);
          }
        })
        .catch((err) => {
          setFindLessonLoading(false);
        });
    }
  }, [courseDetails, user]);

  useEffect(() => {
    if (filterAllLessons?.length > 0) {
      const total = filterAllLessons?.length - 1;

      if (currentNumber !== 0 && currentNumber === total) {
        setNextButtonDisabled(true);
      } else if (currentNumber !== total && currentNumber === 0) {
        setPrevButtonDisabled(true);
      } else {
        setNextButtonDisabled(false);
        setPrevButtonDisabled(false);
      }
    }
  }, [currentNumber]);

  useEffect(() => {
    if (lesId) {
      const index = filterAllLessons?.findIndex((obj) => obj?._id === lesId);
      if (index && index !== -1) {
        setLastLesson("changing");
        setCurrentNumber(index);
      }
    }
  }, [lesId, filterAllLessons]);

  useEffect(() => {
    if (lesId) {
      const findSectionId = sections.find((sec) =>
        sec?.lessons?.find((les) => les?._id === lesId)
      );
      if (findSectionId && findSectionId !== undefined) {
        console.log({ findSectionId });
        setActiveSectionId(findSectionId?._id);
      }
    }
  }, [lesId, sections]);

  const handleMark = () => {
    const values = {
      user_id: user?._id,
      lesson_id: lesId,
      course_id: id,
    };

    markAsCompleted(values)
      .then((res) => {
        if (res?.data?.success) {
          // message.success(res?.data?.message);
          setStatus(true);
          setCompletionsStatus(true);
        }
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DEV_URL}/api/course/completion/${user?._id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          course_id: id,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setIsQuizPass(data?.data?.quizPass);
      });
  }, [user?._id, id]);

  const handleQuizButton = () => {
    if (parseFloat(courseCompletion) >= 50) {
      if (isQuizPass) {
        history.push(`/preserveQuiz/${id}`);
      } else {
        history.push(`/quiz/${id}`);
        // history.push(`/preserveQuiz/${id}`)
      }
    } else {
      console.log("not greater than 50");
      message.error("Please complete at least 50% of course");
    }
  };

  const handleReviewButton = () => {
    if (parseFloat(courseCompletion) >= 50) {
      history.push(`/review/${id}`);
    } else {
      message.error("Please complete at least 50% of course");
    }
  };

  // console.log("active section id", activeSectionId);

  console.log({ coursePaid, savedCertificate, courseCompletion });
  console.log(Number(courseCompletion));

  console.log(Number(courseCompletion) === 100);
  return (
    <Layout footer={true}>
      <Helmet>
        <title>Course Watching</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      {isDurationOVer ? (
        <>
          <h2 className="text-center mt-3 mb-3 text-danger font-weight-bold">
            Your course is expired
          </h2>
          <div
            id="notClickAble"
            style={{ filter: blocked ? "blur(10px)" : null }}
            className="watching"
          >
            <BlockedPopup open={blocked ? true : false} />
            <div className="watching__wrapper">
              <div className="watching__wrapper__header">
                <h2>{courseDetails?.title}</h2>
              </div>
              <div className="watching__wrapper__body container">
                <div className="watching__wrapper__left">
                  {url && (
                    <div className="player-wrap">
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        className="react_player"
                        onEnded={handleMark}
                        controls
                        url={url}
                      />
                    </div>
                  )}
                  {/* <iframe
                    title="URL"
                    allowfullscreen=""
                    frameborder="0"
                    // style={{ width: '100%', height: '400px' }}
                    src={url}
                    width="420"
                  ></iframe> */}
                  <div className="watching__wrapper__left__bottom">
                    <h4>{videoTitle}</h4>
                    <div className="watching__wrapper__left__bottom__button">
                      <button
                        style={{
                          cursor: prevButtonDisabled
                            ? "not-allowed"
                            : "pointer",
                        }}
                        disabled={prevButtonDisabled}
                        onClick={handlePrevious}
                      >
                        Previous
                      </button>
                      <button
                        style={{
                          cursor: nextButtonDisabled
                            ? "not-allowed"
                            : "pointer",
                        }}
                        disabled={nextButtonDisabled}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                      <button
                        className="quizButton text-center"
                        onClick={handleQuizButton}
                      >
                        <p className="quizText">Quiz</p>
                      </button>

                      <button
                        className="quizButton2 text-center"
                        onClick={handleReviewButton}
                      >
                        <p className="quizText">Review</p>
                      </button>
                      {/* <Link className="quizButton text-center" to={`/quiz/${id}`}>
                    <p className="quizText">Quiz</p>
                  </Link> */}
                    </div>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    {description &&
                      description?.length > 0 &&
                      renderHTML(description)}
                  </div>
                  <div>
                    <p
                      onClick={() => setVisible(true)}
                      style={{
                        color: "red",
                        fontSize: "18px",
                        textDecoration: "underline",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <WarningFilled /> Copyright warning
                    </p>
                  </div>
                </div>
                <div className="watching__wrapper__right">
                  <div className="watching__wrapper__right__wrapper">
                    <div style={{ marginBottom: "10px" }}>
                      <h4 style={{ marginBottom: "0px", fontSize: "20px" }}>
                        {Number(courseCompletion) === 100
                          ? "Congratulations to complete the course!"
                          : "Your progress"}
                      </h4>

                      {coursePaid && Number(courseCompletion) === 100 ? (
                        savedCertificate ? (
                          <button
                            onClick={handleDownloadCertificate}
                            style={{
                              background: "#1c659ee0",
                              color: "white",
                              border: "none",
                              padding: "5px 25px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginTop: "7px",
                            }}
                          >
                            Download certificate
                          </button>
                        ) : (
                          <button
                            onClick={handleGetCertificate}
                            style={{
                              background: "#1c659ee0",
                              color: "white",
                              border: "none",
                              padding: "5px 25px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginTop: "7px",
                            }}
                          >
                            Get certificate
                          </button>
                        )
                      ) : (
                        <Progress
                          percent={
                            courseCompletion === "NaN"
                              ? 0
                              : Number(courseCompletion)
                          }
                          status="active"
                        />
                      )}
                    </div>

                    <div className="watching__wrapper__right__wrapper__box">
                      <Collapse
                        expandIconPosition={"right"}
                        defaultActiveKey={[activeSectionId]}
                      >
                        {sections?.length > 0 &&
                          sections?.map((sec) => (
                            <Panel header={sec?.chapter_title} key={sec?._id}>
                              {sec?.lessons?.map((les, i) => (
                                <Lesson
                                  key={i}
                                  les={les}
                                  setUrl={setUrl}
                                  setPath={setPath}
                                  setVideoTitle={setVideoTitle}
                                  setDescription={setDescription}
                                  courseId={id}
                                  markedLessons={markedLessons}
                                  setCompletionsStatus={setCompletionsStatus}
                                  setCurrentNumber={setCurrentNumber}
                                  status={status}
                                  setStatus={setStatus}
                                  lesId={lesId}
                                  setLesId={setLesId}
                                  index={i}
                                />
                              ))}
                            </Panel>
                          ))}
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              title="Qlearn Copyright Warning"
              centered
              open={visible}
              onCancel={() => setVisible(false)}
              width={1000}
              footer={null}
            >
              <div
                style={{
                  textAlign: "center",
                  display: "block",
                  marginBottom: "30px",
                }}
              >
                <img src={handCraf} alt="" />
              </div>
              <p style={{ fontSize: "16px", lineHeight: "30px" }}>
                Qlearn এর সাথে সম্পৃক্ত যেকোনো ভিডিও, টেক্সট বা কনটেন্ট অন্য
                কারো সঙ্গে অর্থের বিনিময়ে বা বিনামূল্যে আদান-প্রদান বা শেয়ার
                করা, কারও সাথে ইমেইল-একাউন্ট পাসওয়ার্ড শেয়ার করা আইনত দন্ডনীয়
                অপরাধ। এজন্য অন্তত ৫ বছর থেকে শুরু করে সর্বোচ্চ ১৪ বছরের জেল হতে
                পারে। শুধু তাই না, একই সাথে ৫ লাখ টাকা থেকে শুরু করে সর্বোচ্চ ৫০
                লাখ টাকা জরিমানাও গুনতে হতে পারে। সাইবার সিকিউরিটি কেইসে কাউকে
                গ্রেফতার করার জন্য কোনো মামলার প্রয়োজন হয় না। এমনকি কোনো
                ওয়ারেন্টও ইস্যু করতে হয় না। সরাসরি গ্রেফতার করে হাজতে নিয়ে যাওয়া
                হয়। সুতরাং সামান্য কিছু টাকার জন্য দুই নাম্বারি করতে গিয়ে কত বছর
                জেলে থাকতে হবে, সেটাও একবার ভালো করে হিসাব করে নিন!! অন্য নামে
                একাউন্ট খুলে অথবা ফেইসবুকে ফেইক একাউন্ট করে অথবা ইনকগনিটো মুডে
                একাউন্ট খুললে আপনাকে ট্র‍্যাক করা যাবে না। এমনটা যদি ভেবে থাকো
                তাহলে তুমি বোকার স্বর্গে বাস করছো। তোমার ISP, তোমার IP এড্রেস,
                তোমার ফোন এর প্রত্যেকটা কল, প্রত্যেকটা মেসেজ রেকর্ড থাকে। শুধু
                সেটাই না, ফেইসবুক, জিমেইল, গুগোল সরকারের সাইবার ক্রাইম টিম কে
                যেকোনো ডিলিটেড তথ্য সাথে সাথে প্রোভাইড করা হয়ে থাকে। এছাড়াও
                আপনার একাউন্ট কোন আইপি এড্রেস থেকে লগইন করা হয়েছে বা কোন জায়গা
                লগইন করা হয়েছে সেটাও আমাদের সার্ভারে আছে। সুতরাং একটু চেষ্টা
                করলেই, ২৪ ঘন্টার মধ্যে আপনাকে ধরে ফেলার ফেলার ক্ষমতা সাইবার
                ক্রিমিনাল ডিপার্টমেন্ট রাখে। আমরা অনেকদিন ধরে চিন্তা করছি
                দুই-একজনকে কোর্স চুরি করে বিক্রি করার জন্য দৃষ্টান্তমূলক শাস্তি
                দিব। যেন দেশের অন্য সবাই সেটা দেখে সতর্ক হয়ে যায়। সুতরাং, আপনি
                যদি নিজেকে ১৫ বছর জেলের ভিতরে দেখতে না চান, তাহলে আমাদের কোর্স
                এর ভিডিও ডাউনলোড করে কারো সাথে অর্থের বিনিময়ে বা বিনামূল্যে দেয়া
                থেকে বিরত থাকুন। আপনার ভাব-মূর্তি এবং আপনার ফ্যামিলি'র কথা
                চিন্তা করুন একবার ফেঁসে গেলে কিন্তু কেউ আপনাকে বাঁচাতে আসবে না।
              </p>
            </Modal>
          </div>
        </>
      ) : goingToExpire ? (
        <>
          <h4 className="text-center mt-2 mb-2 text-danger font-weight-bold">
            Your course is going to be expired soon..
          </h4>
          <div
            style={{ filter: blocked ? "blur(10px)" : null }}
            className="watching"
          >
            <BlockedPopup open={blocked ? true : false} />
            <div className="watching__wrapper">
              <div className="watching__wrapper__header">
                <h2>{courseDetails?.title}</h2>
              </div>
              <div className="watching__wrapper__body container">
                <div className="watching__wrapper__left">
                  {url && (
                    <div className="player-wrap">
                      <ReactPlayer
                        width="100%"
                        height="100%"
                        className="react_player"
                        onEnded={handleMark}
                        controls
                        url={url}
                      />
                    </div>
                  )}
                  {/* <iframe
                    title="URL"
                    allowfullscreen=""
                    frameborder="0"
                    // style={{ width: '100%', height: '400px' }}
                    src={url}
                    width="420"
                  ></iframe> */}
                  <div className="watching__wrapper__left__bottom">
                    <h4>{videoTitle}</h4>
                    <div className="watching__wrapper__left__bottom__button">
                      <button
                        style={{
                          cursor: prevButtonDisabled
                            ? "not-allowed"
                            : "pointer",
                        }}
                        disabled={prevButtonDisabled}
                        onClick={handlePrevious}
                      >
                        Previous
                      </button>
                      <button
                        style={{
                          cursor: nextButtonDisabled
                            ? "not-allowed"
                            : "pointer",
                        }}
                        disabled={nextButtonDisabled}
                        onClick={handleNext}
                      >
                        Next
                      </button>
                      <button
                        className="quizButton text-center"
                        onClick={handleQuizButton}
                      >
                        <p className="quizText">Quiz</p>
                      </button>

                      <button
                        className="quizButton2 text-center"
                        onClick={handleReviewButton}
                      >
                        <p className="quizText">Review</p>
                      </button>
                      {/* <Link className="quizButton text-center" to={`/quiz/${id}`}>
                    <p className="quizText">Quiz</p>
                  </Link> */}
                    </div>
                  </div>
                  <div style={{ marginTop: "15px" }}>
                    {description &&
                      description?.length > 0 &&
                      renderHTML(description)}
                  </div>
                  <div>
                    <p
                      onClick={() => setVisible(true)}
                      style={{
                        color: "red",
                        fontSize: "18px",
                        textDecoration: "underline",
                        marginTop: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <WarningFilled /> Copyright warning
                    </p>
                  </div>
                </div>
                <div className="watching__wrapper__right">
                  <div className="watching__wrapper__right__wrapper">
                    <div style={{ marginBottom: "10px" }}>
                      <h4 style={{ marginBottom: "0px", fontSize: "20px" }}>
                        {Number(courseCompletion) === 100
                          ? "Congratulations to complete the course!"
                          : "Your progress"}
                      </h4>

                      {coursePaid && Number(courseCompletion) === 100 ? (
                        savedCertificate ? (
                          <button
                            onClick={handleDownloadCertificate}
                            style={{
                              background: "#1c659ee0",
                              color: "white",
                              border: "none",
                              padding: "5px 25px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginTop: "7px",
                            }}
                          >
                            Download certificate
                          </button>
                        ) : (
                          <button
                            onClick={handleGetCertificate}
                            style={{
                              background: "#1c659ee0",
                              color: "white",
                              border: "none",
                              padding: "5px 25px",
                              borderRadius: "4px",
                              cursor: "pointer",
                              marginTop: "7px",
                            }}
                          >
                            Get certificate
                          </button>
                        )
                      ) : (
                        <Progress
                          percent={
                            courseCompletion === "NaN"
                              ? 0
                              : Number(courseCompletion)
                          }
                          status="active"
                        />
                      )}
                    </div>

                    <div className="watching__wrapper__right__wrapper__box">
                      <Collapse
                        expandIconPosition={"right"}
                        defaultActiveKey={[activeSectionId]}
                      >
                        {sections?.length > 0 &&
                          sections?.map((sec) => (
                            <Panel header={sec?.chapter_title} key={sec?._id}>
                              {sec?.lessons?.map((les, i) => (
                                <Lesson
                                  key={i}
                                  les={les}
                                  setUrl={setUrl}
                                  setPath={setPath}
                                  setVideoTitle={setVideoTitle}
                                  setDescription={setDescription}
                                  courseId={id}
                                  markedLessons={markedLessons}
                                  setCompletionsStatus={setCompletionsStatus}
                                  setCurrentNumber={setCurrentNumber}
                                  status={status}
                                  setStatus={setStatus}
                                  lesId={lesId}
                                  setLesId={setLesId}
                                  index={i}
                                />
                              ))}
                            </Panel>
                          ))}
                      </Collapse>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Modal
              title="Qlearn Copyright Warning"
              centered
              open={visible}
              onCancel={() => setVisible(false)}
              width={1000}
              footer={null}
            >
              <div
                style={{
                  textAlign: "center",
                  display: "block",
                  marginBottom: "30px",
                }}
              >
                <img src={handCraf} alt="" />
              </div>
              <p style={{ fontSize: "16px", lineHeight: "30px" }}>
                Qlearn এর সাথে সম্পৃক্ত যেকোনো ভিডিও, টেক্সট বা কনটেন্ট অন্য
                কারো সঙ্গে অর্থের বিনিময়ে বা বিনামূল্যে আদান-প্রদান বা শেয়ার
                করা, কারও সাথে ইমেইল-একাউন্ট পাসওয়ার্ড শেয়ার করা আইনত দন্ডনীয়
                অপরাধ। এজন্য অন্তত ৫ বছর থেকে শুরু করে সর্বোচ্চ ১৪ বছরের জেল হতে
                পারে। শুধু তাই না, একই সাথে ৫ লাখ টাকা থেকে শুরু করে সর্বোচ্চ ৫০
                লাখ টাকা জরিমানাও গুনতে হতে পারে। সাইবার সিকিউরিটি কেইসে কাউকে
                গ্রেফতার করার জন্য কোনো মামলার প্রয়োজন হয় না। এমনকি কোনো
                ওয়ারেন্টও ইস্যু করতে হয় না। সরাসরি গ্রেফতার করে হাজতে নিয়ে যাওয়া
                হয়। সুতরাং সামান্য কিছু টাকার জন্য দুই নাম্বারি করতে গিয়ে কত বছর
                জেলে থাকতে হবে, সেটাও একবার ভালো করে হিসাব করে নিন!! অন্য নামে
                একাউন্ট খুলে অথবা ফেইসবুকে ফেইক একাউন্ট করে অথবা ইনকগনিটো মুডে
                একাউন্ট খুললে আপনাকে ট্র‍্যাক করা যাবে না। এমনটা যদি ভেবে থাকো
                তাহলে তুমি বোকার স্বর্গে বাস করছো। তোমার ISP, তোমার IP এড্রেস,
                তোমার ফোন এর প্রত্যেকটা কল, প্রত্যেকটা মেসেজ রেকর্ড থাকে। শুধু
                সেটাই না, ফেইসবুক, জিমেইল, গুগোল সরকারের সাইবার ক্রাইম টিম কে
                যেকোনো ডিলিটেড তথ্য সাথে সাথে প্রোভাইড করা হয়ে থাকে। এছাড়াও
                আপনার একাউন্ট কোন আইপি এড্রেস থেকে লগইন করা হয়েছে বা কোন জায়গা
                লগইন করা হয়েছে সেটাও আমাদের সার্ভারে আছে। সুতরাং একটু চেষ্টা
                করলেই, ২৪ ঘন্টার মধ্যে আপনাকে ধরে ফেলার ফেলার ক্ষমতা সাইবার
                ক্রিমিনাল ডিপার্টমেন্ট রাখে। আমরা অনেকদিন ধরে চিন্তা করছি
                দুই-একজনকে কোর্স চুরি করে বিক্রি করার জন্য দৃষ্টান্তমূলক শাস্তি
                দিব। যেন দেশের অন্য সবাই সেটা দেখে সতর্ক হয়ে যায়। সুতরাং, আপনি
                যদি নিজেকে ১৫ বছর জেলের ভিতরে দেখতে না চান, তাহলে আমাদের কোর্স
                এর ভিডিও ডাউনলোড করে কারো সাথে অর্থের বিনিময়ে বা বিনামূল্যে দেয়া
                থেকে বিরত থাকুন। আপনার ভাব-মূর্তি এবং আপনার ফ্যামিলি'র কথা
                চিন্তা করুন একবার ফেঁসে গেলে কিন্তু কেউ আপনাকে বাঁচাতে আসবে না।
              </p>
            </Modal>
          </div>
        </>
      ) : (
        <div
          style={{ filter: blocked ? "blur(10px)" : null }}
          className="watching"
        >
          <BlockedPopup open={blocked ? true : false} />
          <div className="watching__wrapper">
            <div className="watching__wrapper__header">
              <h2>{courseDetails?.title}</h2>
            </div>
            <div className="watching__wrapper__body container">
              <div className="watching__wrapper__left">
                {url && (
                  <div className="player-wrap">
                    <ReactPlayer
                      width="100%"
                      height="100%"
                      className="react_player"
                      onEnded={handleMark}
                      controls
                      url={url}
                    />
                  </div>
                )}
                {/* <iframe
                    title="URL"
                    allowfullscreen=""
                    frameborder="0"
                    // style={{ width: '100%', height: '400px' }}
                    src={url}
                    width="420"
                  ></iframe> */}
                <div className="watching__wrapper__left__bottom">
                  <h4>{videoTitle}</h4>
                  <div className="watching__wrapper__left__bottom__button">
                    <button
                      style={{
                        cursor: prevButtonDisabled ? "not-allowed" : "pointer",
                      }}
                      disabled={prevButtonDisabled}
                      onClick={handlePrevious}
                    >
                      Previous
                    </button>
                    <button
                      style={{
                        cursor: nextButtonDisabled ? "not-allowed" : "pointer",
                      }}
                      disabled={nextButtonDisabled}
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <button
                      className="quizButton text-center"
                      onClick={handleQuizButton}
                    >
                      <p className="quizText">Quiz</p>
                    </button>

                    <button
                      className="quizButton2 text-center"
                      onClick={handleReviewButton}
                    >
                      <p className="quizText">Review</p>
                    </button>
                    {/* <Link className="quizButton text-center" to={`/quiz/${id}`}>
                    <p className="quizText">Quiz</p>
                  </Link> */}
                  </div>
                </div>
                <div style={{ marginTop: "15px" }}>
                  {description &&
                    description?.length > 0 &&
                    renderHTML(description)}
                </div>
                <div>
                  <p
                    onClick={() => setVisible(true)}
                    style={{
                      color: "red",
                      fontSize: "18px",
                      textDecoration: "underline",
                      marginTop: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <WarningFilled /> Copyright warning
                  </p>
                </div>
              </div>
              <div className="watching__wrapper__right">
                <div className="watching__wrapper__right__wrapper">
                  <div style={{ marginBottom: "10px" }}>
                    <h4 style={{ marginBottom: "0px", fontSize: "20px" }}>
                      {Number(courseCompletion) === 100
                        ? "Congratulations to complete the course!"
                        : "Your progress"}
                    </h4>

                    {coursePaid && Number(courseCompletion) === 100 ? (
                      savedCertificate ? (
                        <button
                          onClick={handleDownloadCertificate}
                          style={{
                            background: "#1c659ee0",
                            color: "white",
                            border: "none",
                            padding: "5px 25px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            marginTop: "7px",
                          }}
                        >
                          Download certificate
                        </button>
                      ) : (
                        <button
                          onClick={handleGetCertificate}
                          style={{
                            background: "#1c659ee0",
                            color: "white",
                            border: "none",
                            padding: "5px 25px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            marginTop: "7px",
                          }}
                        >
                          Get certificate
                        </button>
                      )
                    ) : (
                      <Progress
                        percent={
                          courseCompletion === "NaN"
                            ? 0
                            : Number(courseCompletion)
                        }
                        status="active"
                      />
                    )}
                  </div>

                  <div className="watching__wrapper__right__wrapper__box">
                    <Collapse
                      expandIconPosition={"right"}
                      defaultActiveKey={[activeSectionId]}
                    >
                      {sections?.length > 0 &&
                        sections?.map((sec) => (
                          <Panel header={sec?.chapter_title} key={sec?._id}>
                            {sec?.lessons?.map((les, i) => (
                              <Lesson
                                key={i}
                                les={les}
                                setUrl={setUrl}
                                setPath={setPath}
                                setVideoTitle={setVideoTitle}
                                setDescription={setDescription}
                                courseId={id}
                                markedLessons={markedLessons}
                                setCompletionsStatus={setCompletionsStatus}
                                setCurrentNumber={setCurrentNumber}
                                status={status}
                                setStatus={setStatus}
                                lesId={lesId}
                                setLesId={setLesId}
                                index={i}
                              />
                            ))}
                          </Panel>
                        ))}
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal
            title="Qlearn Copyright Warning"
            centered
            open={visible}
            onCancel={() => setVisible(false)}
            width={1000}
            footer={null}
          >
            <div
              style={{
                textAlign: "center",
                display: "block",
                marginBottom: "30px",
              }}
            >
              <img src={handCraf} alt="" />
            </div>
            <p style={{ fontSize: "16px", lineHeight: "30px" }}>
              Qlearn এর সাথে সম্পৃক্ত যেকোনো ভিডিও, টেক্সট বা কনটেন্ট অন্য কারো
              সঙ্গে অর্থের বিনিময়ে বা বিনামূল্যে আদান-প্রদান বা শেয়ার করা, কারও
              সাথে ইমেইল-একাউন্ট পাসওয়ার্ড শেয়ার করা আইনত দন্ডনীয় অপরাধ। এজন্য
              অন্তত ৫ বছর থেকে শুরু করে সর্বোচ্চ ১৪ বছরের জেল হতে পারে। শুধু তাই
              না, একই সাথে ৫ লাখ টাকা থেকে শুরু করে সর্বোচ্চ ৫০ লাখ টাকা
              জরিমানাও গুনতে হতে পারে। সাইবার সিকিউরিটি কেইসে কাউকে গ্রেফতার
              করার জন্য কোনো মামলার প্রয়োজন হয় না। এমনকি কোনো ওয়ারেন্টও ইস্যু
              করতে হয় না। সরাসরি গ্রেফতার করে হাজতে নিয়ে যাওয়া হয়। সুতরাং
              সামান্য কিছু টাকার জন্য দুই নাম্বারি করতে গিয়ে কত বছর জেলে থাকতে
              হবে, সেটাও একবার ভালো করে হিসাব করে নিন!! অন্য নামে একাউন্ট খুলে
              অথবা ফেইসবুকে ফেইক একাউন্ট করে অথবা ইনকগনিটো মুডে একাউন্ট খুললে
              আপনাকে ট্র‍্যাক করা যাবে না। এমনটা যদি ভেবে থাকো তাহলে তুমি বোকার
              স্বর্গে বাস করছো। তোমার ISP, তোমার IP এড্রেস, তোমার ফোন এর
              প্রত্যেকটা কল, প্রত্যেকটা মেসেজ রেকর্ড থাকে। শুধু সেটাই না,
              ফেইসবুক, জিমেইল, গুগোল সরকারের সাইবার ক্রাইম টিম কে যেকোনো ডিলিটেড
              তথ্য সাথে সাথে প্রোভাইড করা হয়ে থাকে। এছাড়াও আপনার একাউন্ট কোন
              আইপি এড্রেস থেকে লগইন করা হয়েছে বা কোন জায়গা লগইন করা হয়েছে সেটাও
              আমাদের সার্ভারে আছে। সুতরাং একটু চেষ্টা করলেই, ২৪ ঘন্টার মধ্যে
              আপনাকে ধরে ফেলার ফেলার ক্ষমতা সাইবার ক্রিমিনাল ডিপার্টমেন্ট রাখে।
              আমরা অনেকদিন ধরে চিন্তা করছি দুই-একজনকে কোর্স চুরি করে বিক্রি করার
              জন্য দৃষ্টান্তমূলক শাস্তি দিব। যেন দেশের অন্য সবাই সেটা দেখে সতর্ক
              হয়ে যায়। সুতরাং, আপনি যদি নিজেকে ১৫ বছর জেলের ভিতরে দেখতে না চান,
              তাহলে আমাদের কোর্স এর ভিডিও ডাউনলোড করে কারো সাথে অর্থের বিনিময়ে
              বা বিনামূল্যে দেয়া থেকে বিরত থাকুন। আপনার ভাব-মূর্তি এবং আপনার
              ফ্যামিলি'র কথা চিন্তা করুন একবার ফেঁসে গেলে কিন্তু কেউ আপনাকে
              বাঁচাতে আসবে না।
            </p>
          </Modal>
        </div>
      )}
    </Layout>
  );
};

export default Watching;
