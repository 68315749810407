import {
  ArrowRightOutlined,
  MailTwoTone,
  MobileTwoTone,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import renderHTML from "react-render-html";
import { Link } from "react-router-dom";
import { getInfo } from "../../actions/information";
import logo from "../../images/color-white.png";
import SSLIMG3 from "../../images/ssl3-min.webp";
import SSLIMG6 from "../../images/ssl6-min.webp";
import BottomHeader from "./BottomHeader";
import "./Footer.css";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const Footer = ({ footer }) => {
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [information, setInformation] = useState(null);

  useEffect(() => {
    getInfo()
      .then((r) => r.json())
      .then((res) => setInformation(res?.data))
      .catch((err) => console.log(err));
  }, []);

  const handleBecomeAnAffiliateClick = () =>{

    if(user){
      history.push('/affiliate')
    }
    else{
      sessionStorage.setItem(
        "redirectURL",
        `/affiliate`
      );
      history.push('/login')
    }

  }

  return (
    <>
      {footer && (
        <div className="footer">
          <div className="sub__footer__wrapper container">
            <div className="sub__footer__wrapper__first">
              <div
                style={{
                  marginTop: "15px",
                  marginLeft: "15px",
                  marginBottom: "7px",
                  cursor: "pointer",
                }}
              >
                <a
                  target="_blank"
                  href="https://geostruc.com/"
                  rel="noreferrer"
                >
                  <img
                    className="sub__footer__wrapper__fourth__logo"
                    src={logo}
                    alt="Qlearn"
                  />
                </a>
              </div>
              <div>
                <div>
                  Declearation: qlearn.com.bd is owned by Geotech and Structures
                  Limited and managed by its Training Division
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/ourprofessors/"
                  >
                    <p
                      style={{
                        background: "white",
                        padding: "4px 12px",
                        borderRadius: "10px",
                        color: "var(--primary-color)",
                        fontWeight: 700,
                        cursor: "pointer",
                      }}
                    >
                      Facebook
                    </p>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/c/ourPROFESSORs"
                  >
                    <p
                      style={{
                        background: "white",
                        padding: "4px 12px",
                        borderRadius: "10px",
                        color: "var(--primary-color)",
                        fontWeight: 700,
                        cursor: "pointer",
                        marginLeft: "10px",
                      }}
                    >
                      Youtube
                    </p>
                  </a>
                </div>
              </div>
            </div>
            <div className="sub__footer__wrapper__second">
              <h4>USEFUL LINKS </h4>
              <div>
                <ul>
                  <li>
                    <Link to="/blogs">
                      <ArrowRightOutlined /> Blogs
                    </Link>
                  </li>
                  <li>
                    <Link to="/instructors">
                      {" "}
                      <ArrowRightOutlined /> Our Instructor
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://forms.gle/6SBBzmQ8cLZSaDqo7"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <ArrowRightOutlined /> Join as a Trainer
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://ourprofessors.live/"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <ArrowRightOutlined /> PHYSICAL TRAINING
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://www.ourprofessors.com/"
                    >
                      {" "}
                      <ArrowRightOutlined /> OLD WEBSITE
                    </a>
                  </li>
                  <li>
                    <Link to="/checkCertificate">
                      {" "}
                      <ArrowRightOutlined /> CHECK CERTIFICATE
                    </Link>
                  </li>
                  <li>
                    <button
                      style={{
                        backgroundColor: "#093054",
                        border: "0px",
                        marginLeft: "-3px",
                      }}

                      onClick={()=>handleBecomeAnAffiliateClick()}
                    >
                      {" "}
                      <ArrowRightOutlined /> BECOME A AFFILIATOR
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sub__footer__wrapper__third">
              <h4>ABOUT Qlearn</h4>
              <div>
                <ul>
                  <li>
                    <Link to="/support">
                      <ArrowRightOutlined /> Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <ArrowRightOutlined /> FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="/about-us">
                      <ArrowRightOutlined /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      <ArrowRightOutlined /> Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/refund-policy">
                      <ArrowRightOutlined /> Refund Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/seminar">
                      <ArrowRightOutlined /> JOIN SEMINAR
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className={"sub__footer__wrapper__fourth"}>
              <h4>SUPPORT</h4>
              <div>
                {/* <div>
                  <a href="tel:01324-433239">01324-433239</a>{' '}
                </div>
                <div>
                  <a href="tel:01885-973767">01885-973767</a>{' '}
                </div>
                <div>
                  <a href="tel:01845-828253">01845-828253</a> (bKash - Personal){' '}
                </div> */}

                <div>
                  <MobileTwoTone
                    style={{
                      paddingBottom: "5px",
                      marginRight: "10px",
                      fontSize: "20px",
                    }}
                  />
                  <a href="tel:09638-441144">09638-441144</a>
                </div>

                {information && renderHTML(information?.information?.phone)}
                <div>
                  <MailTwoTone
                    style={{
                      paddingBottom: "5px",
                      marginRight: "10px",
                      fontSize: "20px",
                    }}
                  />
                  <a href="mailto:qlearncs@gmail.com">qlearncs@gmail.com</a>
                </div>
                <div>
                  {information && renderHTML(information?.information?.email)}
                </div>

                <div>
                  {/* qlearncs@gmail.com <br /> admin@geostruc.com <br /> */}
                  Level 15-A, Saiham Sky View Tower 45 Bijoy Nagar Road, Dhaka -
                  1000
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "0px 50px 10px 50px" }}>
            <a
              without
              rel="noreferrer"
              target="_blank"
              href="https://www.sslcommerz.com/"
              title="SSLCommerz"
              alt="SSLCommerz"
            >
              <img
                className="laptopViewSSL"
                style={{ width: "100%", height: "auto" }}
                //src="https://securepay.sslcommerz.com/public/image/SSLCommerz-Pay-With-logo-All-Size-03.png"
                src={SSLIMG3}
                alt=""
              />

              <img
                className="mobileViewSSL"
                style={{ width: "100%", height: "auto" }}
                //src="https://securepay.sslcommerz.com/public/image/SSLCommerz-Pay-With-logo-All-Size-06.png"
                src={SSLIMG6}
                alt=""
              />
            </a>
          </div>
          <div className="main__footer">
            <div className="main__footer__wrapper container">
              <p>
                © qlearn.com.bd {new Date().getFullYear()} All right reserved
              </p>
              <div className="main_footer_wrapper_term">
                <ul>
                  <li>
                    <Link to="/terms&conditions">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/">Sitemap</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <BottomHeader />
    </>
  );
};

export default Footer;
