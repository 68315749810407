import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import logo from '../../images/logo.png';

const ResponsiveTopHeader = () => {
  return (
    <div className="ResponsiveTopHeader">
      <div className="ResponsiveTopHeader__wrapper">
        <div className="ResponsiveTopHeader__wrapper__left">
          <Link style={{ display: 'block' }} to="/">
            <div style={{ cursor: 'pointer', marginTop: '8px' }}>
              <img src={logo} alt="Our Professor" width={250} height={60} />
            </div>
          </Link>
        </div>
        {/* <div className="ResponsiveTopHeader__wrapper__right">
          <SearchOutlined />
          <BellOutlined />
          <MoreOutlined />
        </div> */}
      </div>
    </div>
  );
};

export default ResponsiveTopHeader;
