import { message } from 'antd';
import { useState, useEffect } from 'react';
import {
  checkCertificate,
  getCertificate,
  saveCertificate,
} from '../../actions/certificate';

const useCertificateHandler = (id, user) => {
  const [savedCertificate, setSavedCertificate] = useState(false);

  useEffect(() => {
    if (user && id) {
      const body = {
        email: user?.email,
        course_id: id,
      };
      checkCertificate(body).then((res) => {
        if (res?.data?.success) {
          setSavedCertificate(res?.data?.message);
        }
      });
    }
  }, [user, id]);

  const handleGetCertificate = () => {
    const body = { email: user?.email };
    getCertificate(id, body)
      .then((res) => {
        if (res?.data?.key) {
          saveCertificate(id, {
            email: user?.email,
            certificate_image: res?.data?.key,
          });
          window.location.replace(
            `${process.env.REACT_APP_DEV_URL}/api/image/download/${res?.data?.key}`
          );
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message === undefined) {
          message.error(err?.response?.data);
        } else {
          message.error(err?.response?.data?.message);
        }
      });
  };

  const handleDownloadCertificate = () => {
    const body = { email: user?.email };
    getCertificate(id, body)
      .then((res) => {
        window.location.replace(
          `${process.env.REACT_APP_DEV_URL}/api/image/download/${res?.data?.key}`
        );
      })
      .catch((err) => {
        if (err?.response?.data?.message === undefined) {
          message.error(err?.response?.data);
        } else {
          message.error(err?.response?.data?.message);
        }
      });
  };

  return { savedCertificate, handleGetCertificate, handleDownloadCertificate };
};

export default useCertificateHandler;
