import React from "react";
import "../../pages/LandingPage/LandingPage.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HumenImage from "../../images/humen.jpg";
import User1 from "../../images/user1.jpg";
import User2 from "../../images/user2.jpg";
import QuoteImage from "../../images/quote.svg";

const Review = () => {
  const ReviewComponents = (image, name, time, content) => {
    return (
      <div className="review__wrapper__body__card">
        <div className="review__wrapper__body__card__top">
          {/* <img src={image} alt="Syket Bhattachergee" width={70} height={70} /> */}
          <div style={{ marginLeft: "10px" }}>
            <h5>{name}</h5>
            <p>{time}</p>
          </div>
        </div>
        <div className="review__wrapper__body__card__bottom">
          <p>{content}</p>
        </div>
      </div>
    );
  };

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="review">
      <div className="review__wrapper container">
        <div className="review__wrapper__intro">
          <h2>
            Hear it Directly <span>from our Students</span>{" "}
          </h2>

          <img src={QuoteImage} alt="Review" />
        </div>
        <div className="review__wrapper__body">
          <Carousel
            infinite={true}
            renderArrowsWhenDisabled={true}
            responsive={responsive}
          >
            {ReviewComponents(
              HumenImage,
              "MD Sifat Islam",
              "Student, BSc. in Civil Engineering",
              "I have completed Diploma in Civil Engineering and currently having study in Bachelor of Science in Civil Engineering in Presidency University. I am part time student. But alhamdulillah , I have worked 76 56 piles which were cast in situ. I have faced similar situation , and I also learned a lot from Sir. and some tips and tricks also . I highly recommend this site for all of Civil Engineer ."
            )}
            {ReviewComponents(
              User1,
              "Mohammad Ibrahim Miah",
              "Executive Engineer, LGED, Sylhet",
              "Thanking You for this course BNBC 2020 Live. I am learning many therotical & practical experience from this course. I hope this course will help us to understand the BNBC 2020. This training will guide us for the uniformity of Building Design all over the country to achieve the Sustainable Development. This BNBC course can help me go a long way in my career. Thanks to Jahangir Sir."
            )}
            {ReviewComponents(
              User2,
              "Md.Kamrul Islam,",
              "Instructor (Tech) Civil, Jhenaidah Polytechnic Institute, Jhenaidah",
              "স্যারকে ধন্যবাদ জানাচ্ছি BNBC-2020 অনলাইন কোর্সে অংশ গ্রহনের সুযোগ দেওয়ার জন্য।আমরা যারা ঢাকার বাইরে থাকি তাদের জন্য এই ধরনের কোর্সের প্রয়োজন ছিল। আমার কাছে স্যার এর ক্লাস গুলো ভালো লাগছে।আশা করছি BNBC-2020 Vol. II এর বি্যয় গুলো সম্পর্কে ভালো ধারনা লাভ করতে পারবো। স্যার এর প্রতি অনুরোধ প্রতিটি টপিকস এর সাথে কমপক্ষে এক বা এর অধিক Example সংযুক্ত করলে আমাদের পক্ষে বুঝতে সুবিধা হবে।"
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default Review;
