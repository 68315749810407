import React from 'react';
import { Result } from 'antd';
import { useHistory } from 'react-router-dom';

const Fail = () => {
  const history = useHistory();
  return (
    <div>
      <Result
        status="error"
        title="Something went wrong"
        subTitle="We are sorry! we did not complete the purchase process. please try again"
        extra={[
          <button
            onClick={() => history.push('/')}
            style={{
              background: '#ff4d4f',
              color: 'white',
              border: 'none',
              padding: '5px 20px',
              borderRadius: '3px',
              cursor: 'pointer',
            }}
            type="primary"
            key="console"
          >
            Home
          </button>,
        ]}
      />
    </div>
  );
};

export default Fail;
