import React, { useState, useEffect } from 'react';
import { getCurrentNotice } from '../../actions/notice';
import renderHTML from 'react-render-html';

function Announcement() {
  const [notice, setNotice] = useState(null);

  useEffect(() => {
    getCurrentNotice()
      .then((res) => {
        if (res?.data?.success && res?.data?.message === null) {
          setNotice(null);
        } else if (res?.data?.success && res?.data?.message) {
          setNotice(res?.data?.message);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return notice ? (
    <div
      style={{
        background: '#e5e5e5',
        padding: '13px',
        fontWeight: 600,
        textAlign: 'center',
      }}
    >
      {renderHTML(notice?.text ? notice?.text : '')}
    </div>
  ) : null;
}

export default Announcement;
