import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import "./SeminarForm.css";
import toastr from "toastr";
import { message } from "antd";
const SeminarJoinForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [address, setAddress] = useState("");
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [seminarData, setSeminarData] = useState(null);
  const { seminarId } = useParams();
  console.log(seminarId);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_URL}/api/seminar/get/${seminarId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("seminar", data);
        setSeminarData(data.data);
      })
      .catch((err) => console.log(err));
  }, [seminarId]);

  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    const body = {
      name,
      phone: number,
      email,
      address,
      seminarId,
      question,
    };

    if (!name || !number || !email) {
      alert("Name, Email and Phone No is required");
      setIsLoading(false);
      return;
    }
    fetch(`${process.env.REACT_APP_DEV_URL}/api/seminar-student/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data?.success) {
          message.success(data?.message, 3, "Seminar");
          setIsLoading(false);
        } else {
          message.error(data?.message, 3, "Seminar");
          setIsLoading(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong", "Seminar");
        setIsLoading(false);
      });
  };
  return (
    <Layout footer={true}>
      <div className="seminar_form_div">
        <h2 className="seminar_form_heading">জয়েন ফ্রি সেমিনার</h2>
        <h2
          style={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "20px",
          }}
          className="seminar_form_heading_seminar2"
        >
          {seminarData?.title}
        </h2>
        <h2
          style={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "20px",
            marginTop: "15px",
          }}
          className="seminar_form_heading_seminar2"
        >
          {seminarData?.date} {seminarData?.month} {seminarData?.year}
        </h2>
        <h2
          style={{
            textAlign: "center",
            fontWeight: "600",
            fontSize: "20px",
            marginTop: "15px",
          }}
          className="seminar_form_heading_seminar2"
        >
          {seminarData?.type} : {seminarData?.zone} - {seminarData?.time} টা
        </h2>
        <div className="form_div">
          <form>
            <label>
              Name{" "}
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </label>
            <input
              type="text"
              placeholder="Your Name"
              onChange={(e) => setName(e.target.value)}
            ></input>
            <label>
              Phone Number{" "}
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </label>
            <input
              type="number"
              placeholder="Your Phone Number"
              onChange={(e) => setNumber(e.target.value)}
            ></input>
            <label>
              Email{" "}
              <span
                style={{
                  color: "red",
                }}
              >
                *
              </span>
            </label>
            <input
              type="email"
              placeholder="Your Email"
              onChange={(e) => setEmail(e.target.value)}
            ></input>
            <label>Question (Not mandatory)</label>
            <textarea
              style={{
                marginTop: "0px",
              }}
              className="input2"
              type="textarea"
              placeholder="If you have any question, feel free to ask"
              onChange={(e) => setQuestion(e.target.value)}
            ></textarea>

            <button onClick={handleSubmit} className="seminar_join_button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default SeminarJoinForm;
