import { Modal } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';

function BlockedPopup({ open }) {
  return (
    <div>
      <Modal
        footer={null}
        header={null}
        centered={true}
        closable={false}
        visible={open}
      >
        <h2 style={{ textAlign: 'center' }}>
          This course is blocked. <br /> Please contact with admin
        </h2>
        <div style={{ textAlign: 'center' }}>
          <Link to="/dashboard">
            <button
              style={{
                background: 'var(--primary-color)',
                color: 'white',
                border: 'none',
                padding: '10px 25px',
                borderRadius: '3px',
                cursor: 'pointer',
              }}
            >
              Go to dashboard
            </button>
          </Link>
        </div>
      </Modal>
    </div>
  );
}

export default BlockedPopup;
