import React, { useEffect } from 'react';
import WrapperComponent from './components/WrapperComponent';
import Routes from './Routes';
import { connect, useSelector } from 'react-redux';
import { loggedOut } from './actions/auth';
import PropTypes from 'prop-types';

function App({ loggedOut }) {
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (user) {
      if (user?.blocked) {
        loggedOut();
      }
    }
  }, [user]);

  return (
    <WrapperComponent>
      <Routes />
    </WrapperComponent>
  );
}

App.prototype = {
  loggedOut: PropTypes.func.isRequired,
};

export default connect(null, { loggedOut })(App);
