import axios from 'axios';

/**
 *
 * * Get All Section Details
 */
export const getAllCategories = async () => {
  return await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/course/category/all`
  );
};
