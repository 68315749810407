import axios from 'axios';

/**
 *
 * * Get All Section Details
 */
export const getAllBooks = async () => {
  return await axios.get(`${process.env.REACT_APP_DEV_URL}/api/book/getall`);
};

/**
 *
 * * Get Specific Course Details
 */
export const getSpecificBook = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/book/get/slug/${slug}`
  );
};
