import React from 'react';
import Layout from '../../components/Layout';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
  return (
    <Layout footer={true}>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          property="og:description"
          content="Qlearn is an online learning platform for learners. Paid and free online courses are offered here by top professionals, experts, and professors for the benefit of young students, engineers, professionals who want to develop their career and lead an honest living. "
        />
      </Helmet>
      <div className="container">
        <h2 style={{ textAlign: "center", padding: "10px 0px" }}>About Us</h2>
        <div style={{ padding: "20px 20px 80px 20px" }}>
          <div>
            <p>
              Qlearn is an online learning platform for learners. Paid and free
              online courses are offered here by top professionals, experts, and
              professors for the benefit of young students, engineers,
              professionals who want to develop their career and lead an honest
              living. The skills which could not be learned in academic life
              will be available in this interactive online learning platform
              from anywhere, anytime. These free online courses will be the
              starting point of your highly paid professional career. Free
              online courses will lead you to paid online courses. online course
              with a certificate will increase your credibility among
              professionals. online certificate courses are designed for
              engineers to sharpen their current skills and to develop new
              skills.
            </p>
          </div>

          <div>
            <h3>Necessity of online short course</h3>
            <p>
              After COVID-19, schools, colleges, and universities are offering
              online classes to learners around the world. Besides,
              professionals, while sitting idle at home during locked down,
              started teaching their skills online to the young generations.
              Distance learning, online learning, online short courses, online
              seminar, webinar, online meetings are booming day by day. Hence,
              smartphones and laptops became the learning tool in addition to
              communication and entertainment. Physical classes will be obsolete
              in the future. Online classes will be more frequent and normal.
              You can learn from top experts, top professors, and top
              professionals without traveling. Anyone can learn. From anywhere
              you can learn. Anytime, you can learn. You can learn from your
              desired expert. Here are some advantages of the online courses,
              online learning, online training, online short courses, online
              seminars, webinars are explained briefly.
            </p>
          </div>
          <div>
            <h3>Advantages of online learning</h3>
            <h4>1. Cheaper</h4>
            <p>
              Conducting a physical training session needs a lot of
              arrangements. Organizers work hard. Learners must travel long
              distances and attend the physical classes during the scheduled
              time. Physical classes come with many expenses. Courses offered by
              lecturers, professors in physical universities and institutions
              expensive to cover the cost of maintaining these campuses and the
              personnel involved. Traveling and staying incur the personal cost
              of the learner. In summary, online short courses are much cheaper
              than physical training sessions. Short courses of Qlearn is much
              cheaper than you imagined. free short courses are also available
              at Qlearn.
            </p>
            <h4>1. Cheaper</h4>
            <p>
              Conducting a physical training session needs a lot of
              arrangements. Organizers work hard. Learners must travel long
              distances and attend the physical classes during the scheduled
              time. Physical classes come with many expenses. Courses offered by
              lecturers, professors in physical universities and institutions
              expensive to cover the cost of maintaining these campuses and the
              personnel involved. Traveling and staying incur the personal cost
              of the learner. In summary, online short courses are much cheaper
              than physical training sessions. Short courses of Qlearn is much
              cheaper than you imagined. free short courses are also available
              at Qlearn.
            </p>{" "}
            <h4>2. Convenient for Those Employed</h4>
            <p>
              Most of the profession need continuing education even when they
              are employed. They need to learn new skills to develop their
              career and get promotions and more paid in the profession.
              Employed persons don’t have the luxury of attending physical
              seminars and workshops during the day. They don’t have time to
              travel long distances to get their desired training from the
              desired top experts. So, online short courses are the best option
              for busy professionals and engineers. you don’t need to take leave
              from the job and you don’t need to sacrifice your holidays for
              attending seminars. You can learn online short courses from top
              experts from your home at your convenient time. Qlearn is giving
              excellent opportunity to busy professionals and engineers to learn
              a new skill in a convenient time. free short courses at Qlearn
              will your starting point of career development journey in
              professional life.
            </p>{" "}
            <h4>3. Opportunity to Acquire New Skills</h4>
            <p>
              This is the age of technology. This is the age of competition. The
              most competitive professionals/engineers are those who
              consistently learn new skills and sharpen their skills. Online
              short courses allow you to achieve new skills or sharpen your
              current skill without leaving work or traveling to a distant
              place. Now you can learn anything, from anywhere and at your
              schedule. The new skill which you could not learn in university
              can be learned at Qlearn. Although the paid online courses are
              more attractive and beneficial, you can start with free online
              courses to judge our short courses at Qlearn.
            </p>{" "}
            <h4>4. Study at Your Own Pace</h4>
            <p>
              You are a unique person. You should not compare yourself with
              others. Each person has unique learning capabilities on different
              subjects. Learning capacity depends on the topic and capability of
              the learner. That means that one professional may require a week
              to complete a topic while another takes several weeks. It would be
              stressful if a system forces you to learn faster than you can
              manage.
              <br />
              The online courses allow you to choose the pace at which you
              complete the course. If you have more time to study, you can learn
              faster. If your job and works are keeping you away from learning,
              you can take longer and still complete the course. You will be in
              peace while you are studying online. When you get free time, you
              may compensate for the slow pace while you were busy. Qlearn is
              giving you a chance to learn new skills at your own pace. Don’t
              start with paid online courses, rather start with free online
              courses to become familiar with the system of the online learning
              platform.
            </p>{" "}
            <h4>5. Habituated with Self-Discipline and Responsibility</h4>
            <p>
              There is none to pressurize you to learn in online short courses.
              You must be self-disciplined and have a sense of responsibility to
              learn. You have set targets and follow some timetables which would
              help you to complete the online short course. Completing lessons
              and submitting assignments becomes personal responsibility.
              Learning online gives you an opportunity to take charge of your
              life and progress. These lessons are vital for successful adult
              living, especially for engineers and professionals.
              Self-disciplined engineers are encouraged to join Qlearn for free.
              There are some free online courses that will benefit you. Test
              yourself by enrolling in a free online course at Qlearn.
            </p>{" "}
            <h4>6. Variety of Learning Materials</h4>
            <p>
              Physical class, training, course utilize restricted learning and
              teaching methods. They involve lectures and discussions with
              students. Online learning provides multiple ways of teaching and
              aids. You can use videos, infographics, and live chats in addition
              to reading. Teaching online becomes easier with the help of videos
              and animations. An online course will let you understand concepts
              that would have taken a lot of time for lecturers to explain.
              Video lessons, software demonstrations, pdf course materials, and
              quizzes are learning tools at Qlearn. Free online courses will not
              provide course materials. Paid online course will provide
              everything you need.
            </p>
            <h4>7. Does Not Disrupt Your Life</h4>
            <p>
              You are a responsible person. You want to take care of your
              business, family, job, and other responsibilities? Online
              learning, online short courses are for you. It allows you to set
              up a convenient schedule that will not disrupt other duties. It is
              a wonderful option for parents and adults who need to enhance
              their earning potential without disrupting their current life.
              Qlearn encourage you to lead a balanced life where you take care
              of yourself, your family, relatives, job, society, country. Try
              one online course at Qlearn and see whether you can manage
              yourself to learn without sacrificing responsibility to your
              family, job, and society.
            </p>
          </div>

          <div>
            <h3>History of online courses at Qlearn</h3>
            <p>
              Since 2018, “Geotech and Structures”, a specialized civil
              engineering consultancy firm, arranged a lot of workshops and
              professional training for practicing civil engineers. They offered
              short courses on various topics of civil engineering which are
              needed for professional civil engineers for their professional
              development. The venue was at BUET and the “Geotech and
              Structures” office at Kakrail. Civil Engineers who attended those
              training courses and workshops expressed their gratitude and
              interest for more courses on various topics that are related to
              design and construction in the field of civil engineering. A lot
              of professional civil engineers who expressed their interest but
              failed to attend due to the busy life of engineers. Generally,
              engineers are working 10-12 hours per day in Bangladesh. It is
              tough for them to attend a training course for one or two full
              days.
            </p>
            <p>
              Traffic congestion of Dhaka city is becoming worse to worst which
              is another barrier to attend training courses. A lot of civil
              engineers who do job or practice in divisional towns, district
              towns, or Upazilla towns. It is almost impossible for them to
              attend training courses in Dhaka. An instructor, specialist, or
              resource person deliver a lecture for 8-10 hours per day for this
              type of professional training courses. Usually, university
              professors, who are specialized in certain subjects, are requested
              to offer workshops and training courses. They are very busy. It is
              difficult for them to manage one or two full days for the course.
            </p>
            <p>
              Considering all these problems, “Geotech and Structures” developed
              and launched this online professional training platform for civil
              engineers residing all over the world. Initially, civil
              engineering will be focused on. Later other subjects shall be
              covered. Initially, Professor Dr. Jahangir Alam offered online
              courses on various topics of civil engineering. Later other
              professors and experienced engineers are showing interest to offer
              online courses on civil engineering and other disciplines and
              subjects.
            </p>
          </div>
          <div>
            <h3>Why Qlearn is Different?</h3>
            <h5>Main features of Qlearn’ online courses</h5>
            <p>
              <ol>
                <li>
                  Fully interactive. The opportunity of face to face
                  question-answer with course teacher and experts of the related
                  subject. During watching video lessons and reading pdf course
                  materials, you may have some doubts and unclear concepts which
                  is very natural. You shall attend weekly VIRTUAL CLASSROOM of
                  Qlearn to clear all the doubts, confusion, and unclear
                  concepts of the subject. You have the opportunity to discuss
                  with your fellow engineers from every corner of the world.
                  Virtual Classroom is fully interactive, cordial, vivid, and
                  lively. To join Virtual Classroom, please create your free
                  account on our website
                </li>
                <li>
                  No commercial Ads, No distraction. paid and free online
                  courses are free from commercial Ads.
                </li>
                <li>
                  Learning anywhere anytime. You can learn from the office,
                  home, park, hotel, public transport, etc. you can learn on
                  your schedule. The only exception, you have to attend the
                  Virtual Classroom weekly one hour as per our schedule. That
                  means 95% of the learning time is flexible.
                </li>
                <li>
                  An authentic and reliable source of knowledge. You will find
                  tons of free information and free knowledge if you search in
                  google or other search engines. However, it is difficult to
                  separate authentic knowledge from tons of pages. Our every
                  online course is peer-reviewed by experts, practitioners, and
                  university professors. So, it is 100% authentic and reliable.
                </li>
                <li>
                  Fulfill the needs of professional engineers. We are all
                  engineers, civil engineers. We know what the practicing
                  engineers need during their professional carrier. Online
                  courses are developed to fulfill the need for professional
                  engineers.
                </li>
                <li>
                  Worked out examples. These will make a difference. These
                  worked out examples will enhance your learning experience
                  without any doubt.
                </li>
                <li>
                  Downloadable course materials. pdf, excel or word files of
                  online course materials are available in all online courses.
                </li>
                <li>
                  There are some quizzes that will evaluate your learning
                  experience and will help you to evaluate yourself.
                </li>
                <li>
                  Downloadable certificates. A certificate will be available in
                  your account after completion of each online certificate
                  course.
                </li>
                <li>
                  No prerequisite qualification. No certificates, the degree is
                  required to enroll in any online certificate course.
                </li>
              </ol>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutUs;
