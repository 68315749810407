export const getInfo = async () => {
  return await fetch(`${process.env.REACT_APP_DEV_URL}/api/information/all`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then((res) => {
    return res;
  });
};
