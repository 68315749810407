import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-multi-carousel/lib/styles.css';
import { Provider } from 'react-redux';
import store from './store';
import { hydrate, render } from "react-dom";

if (process.env.NODE_ENV !== 'development') console.log = () => {};

const APP = (
   <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
)


const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  render(APP, rootElement);
}

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
