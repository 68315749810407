import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Landing Page
import LandingPage from "./pages/LandingPage/LandingPage";
//Auth Page
import Login from "./pages/Login/Login";
import Signup from "./pages/Signup/Signup";
//Course Page
import Courses from "./pages/Courses/Courses";
import CourseDetails from "./pages/CourseDetails/CourseDetails";
//Book Page
import AllPDF from "./pages/AllPdf/AllPdf";
import BookDetails from "./pages/BookDetails/BookDetails";
//Dashboard
import Dashboard from "./pages/Dashboard/Dashboard";
//Success & Fail
import Fail from "./pages/Fail.js/Fail";
import Success from "./pages/Success/Success";
// Teacher
import Teachers from "./pages/Teachers/Teachers";
//Profile
import Profile from "./pages/Profile/Profile";
// Watching Page

import Watching from "./pages/Watching/Watching";
import BecomeAInstructor from "./pages/BecomeAInstructor/BecomeAInstructor";
import Faq from "./pages/FAQ/Faq";
import Blogs from "./pages/Blogs/Blogs";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import AboutUs from "./pages/AboutUs/AboutUs";
import Support from "./pages/Support/Support";
import NoMatch from "./pages/NoMatch/NoMatch";
import Certificate from "./pages/Certificate/Certificate";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Announcement from "./components/Annoucement/Announcement";
import ScrollToTop from "./components/ScrollToTop";
import BlogDetails from "./pages/BlogDetails";
import Quiz from "./pages/Quiz/Quiz";
import CheckCertificate from "./pages/CheckCertificate/CheckCertificate";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PreserveQuiz from "./pages/Quiz/PreserveQuiz";
import RefundPolicy from "./pages/RefundPolicy/RefundPolicy";
import Seminar from "./pages/Seminar/Seminar";
import SeminarJoinForm from "./pages/Seminar/SeminarJoinForm";
import GiveReview from "./pages/Review/GiveReview";
import SupportTicketList from "./pages/SupportTicket/SupportTicketList";
import SupportTicket from "./pages/SupportTicket/SupportTicket";
import TransactionHistory from "./pages/TransactionHistory/TransactionHistory";
import Invoice from "./pages/TransactionHistory/Invoice";
import Affiliate from "./pages/Affiliate/Affiliate";
import AffiliateList from "./pages/Affiliate/AffiliateList";

const Routes = () => {
  return (
    <div>
      {/* <NewsTicker /> */}
      <Announcement />
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/signup">
              <Signup />
            </Route>
            <Route exact path="/courses">
              <Courses />
            </Route>
            <Route path="/courses/:slug">
              <CourseDetails />
            </Route>
            <Route path="/quiz/:courseID">
              <Quiz></Quiz>
            </Route>
            <Route path="/preserveQuiz/:courseId">
              <PreserveQuiz></PreserveQuiz>
            </Route>
            <Route path="/checkCertificate">
              <CheckCertificate></CheckCertificate>
            </Route>
            <Route path="/books">
              <AllPDF />
            </Route>
            <Route path="/book-details/:slug">
              <BookDetails />
            </Route>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/fail">
              <Fail />
            </Route>
            <Route path="/success/:id">
              <Success />
            </Route>
            <Route path="/instructors">
              <Teachers />
            </Route>
            <Route path="/profile">
              <Profile />
            </Route>
            <Route path="/transactionHistory">
              <TransactionHistory />
            </Route>
            <Route path="/watching/:id">
              <Watching />
            </Route>
            <Route path="/become-a-instructor">
              <BecomeAInstructor />
            </Route>
            <Route path="/faq">
              <Faq />
            </Route>
            <Route path="/about-us">
              <AboutUs />
            </Route>
            <Route path="/support">
              <Support />
            </Route>

            <Route path="/seminar">
              <Seminar />
            </Route>
            <Route path="/seminarFom/:seminarId">
              <SeminarJoinForm></SeminarJoinForm>
            </Route>

            <Route path="/supportTicket">
              <SupportTicketList></SupportTicketList>
            </Route>

            <Route path="/supportTicketDetails/:supportTicketId">
              <SupportTicket></SupportTicket>
            </Route>
            <Route path="/review/:courseId">
              <GiveReview></GiveReview>
            </Route>
            <Route path="/privacy-policy">
              <PrivacyPolicy />
            </Route>
            <Route path="/refund-policy">
              <RefundPolicy></RefundPolicy>
            </Route>
            <Route path="/terms&conditions">
              <TermsAndConditions></TermsAndConditions>
            </Route>
            <Route exact path="/blogs">
              <Blogs />
            </Route>

            <Route path="/blogs/:slug">
              <BlogDetails />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/verify-email">
              <VerifyEmail />
            </Route>
            <Route path="/certificate/:id">
              <Certificate />
            </Route>

            <Route path="/invoice/:id">
              <Invoice />
            </Route>

            <Route path="/affiliate">
              <Affiliate/>
            </Route>

            <Route path="/affiliateList/:affiliateId">
              <AffiliateList/>
            </Route>
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
};

export default Routes;
