import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import CourseCard from "../../components/SharedComponents/CourseCard";
import Education from "../../components/SharedComponents/Education";
import { connect, useSelector } from "react-redux";
import { getAllCourses } from "../../actions/course";
import PropTypes from "prop-types";
import { getAllCategories } from "../../actions/category";
import "../LandingPage/LandingPage.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Courses.css";
import Search from "antd/lib/input/Search";
import { Helmet } from "react-helmet";
import axios from "axios";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 768, min: 400 },
    items: 2,
  },
  mobile1: {
    breakpoint: { max: 400, min: 0 },
    items: 1,
  },
};

const Courses = ({ getAllCourses }) => {
  const [searchText, setSearchText] = useState("");

  const [categories, setCategories] = useState([]);
  const [active, setActive] = useState(0);
  const [filterCourses, setFilterCourses] = useState([]);
  const [bundleCourses, setBundleCourses] = useState([]);
  const [finalFilters, setFinalFilters] = useState([]);
  const { courses, courseLoading } = useSelector((state) => state.course);
  const [courseList, setCourseList] = useState(filterCourses);
  const [loading, setLoading] = useState(true);

  // Get All Courses
  useEffect(() => {
    getAllCourses();
  }, [getAllCourses]);

  // Get All Categories
  useEffect(() => {
    getAllCategories()
      .then((res) => {
        if (res?.data?.success) {
          setCategories(res?.data?.message);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_URL}/api/bundle/getall`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setBundleCourses(data?.message);
      });
  }, []);

  const matchArray = (datas, data) => {
    const matchData = datas?.find((d) => d === data);
    return matchData;
  };

  // FIlter Courses
  useEffect(() => {
    if (active === 0) {
      setFilterCourses(courses);
    } else if (active === 1) {
      // setFilterCourses(bundleCourses);
      setFinalFilters(bundleCourses);
    } else {
      const filterCourse = courses?.filter(
        (c) =>
          c.category?.length > 0 &&
          matchArray(c?.category, active) !== undefined
      );

      setFilterCourses(filterCourse);
    }
  }, [active, bundleCourses, courses]);

  useEffect(() => {
    if (active !== 1) {
      const datas = filterCourses?.filter((c) => c?.course_publish === true);
      setFinalFilters(datas);
    } else if (active === 1) {
      setFinalFilters(bundleCourses);
    }
  }, [active, filterCourses, bundleCourses]);

  //** Search courses */
  useEffect(() => {
    const filterData =
      active === 1
        ? finalFilters.filter((fc) =>
            fc?.name?.toLowerCase()?.includes(searchText.toLowerCase())
          )
        : finalFilters.filter((fc) =>
            fc?.title?.toLowerCase()?.includes(searchText.toLowerCase())
          );
    setCourseList(filterData);
  }, [finalFilters, active, searchText]);

  console.log({ courseList });

  //affiliate
  const updateClickAffiliate = async (affNumber) => {

  console.log({affNumber});
    const config = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const response = await axios.put(
      `${process.env.REACT_APP_DEV_URL}/api/affiliate/click/update/${affNumber}`,
      config
    );

    console.log(response);
  };

  useEffect(() => {
    // Parse query parameters from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const affiliateNumber = searchParams.get("aff");


    if (affiliateNumber) {
      console.log({affiliateNumber});
      sessionStorage.setItem("affiliateNumber", affiliateNumber);
      updateClickAffiliate(affiliateNumber);
    }

    // update affiliate user's click
  }, []);

  return (
    <Layout footer={true}>
      <Helmet>
        <title>Courses</title>
        <meta name="description" property="og:description" content="" />
      </Helmet>
      <div className="course">
        <div className="course__wrapper course__arrow container">
          <div style={{ marginBottom: "20px" }}>
            <Search
              onChange={(e) => setSearchText(e.target.value)}
              value={searchText}
              placeholder="search "
              enterButton="Search"
              size="large"
            />
          </div>
          {loading ? (
            "Loading...."
          ) : (
            <Carousel swipeable={true} draggable={true} responsive={responsive}>
              <div
                style={{
                  minWidth: "200px",
                  cursor: "pointer",
                  background: active !== 0 && "lightgray",
                  borderRadius: "30px",
                }}
                className={
                  active === 0 ? "ant-btn" : "ant-btn-without-background"
                }
                onClick={() => {
                  setActive(0);
                  // setFilterCourses([]);
                }}
              >
                All
              </div>

              <div
                style={{
                  minWidth: "200px",
                  cursor: "pointer",
                  background: active !== 1 && "lightgray",
                  borderRadius: "30px",
                }}
                className={
                  active === 1 ? "ant-btn" : "ant-btn-without-background"
                }
                onClick={() => {
                  setActive(1);
                  setFilterCourses([]);
                }}
              >
                Bundle Courses
              </div>

              {categories?.length > 0 &&
                categories?.map((category) => (
                  <div
                    style={{
                      minWidth: "200px",
                      cursor: "pointer",
                      background: active !== category?._id && "lightgray",
                      borderRadius: "30px",
                    }}
                    key={category?._id}
                    className={
                      active === category?._id
                        ? "ant-btn"
                        : "ant-btn-without-background"
                    }
                    onClick={() => {
                      setActive(category?._id);
                      // setFilterCourses([]);
                    }}
                  >
                    {category?.category_name}
                  </div>
                ))}
            </Carousel>
          )}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
            }}
          >
            {courseLoading
              ? "Loading..."
              : courseList?.length > 0
              ? courseList?.map((course, i) => (
                  <CourseCard
                    bundle={course?.title === undefined ? true : false}
                    course={course}
                    key={i}
                  />
                ))
              : "No course found!"}
          </div>

          {/* <div style={{ marginTop: '20px' }}>
            <Pagination defaultCurrent={1} total={50} />
          </div> */}
          <Education />
        </div>
      </div>
    </Layout>
  );
};

Courses.prototype = {
  getAllCourses: PropTypes.func.isRequired,
};

export default connect(null, { getAllCourses })(Courses);
