import React from 'react';
import ProfileForm from '../../components/ProfilePageComponent/ProfileForm';
import Layout from '../../components/Layout';
import './Profile.css';
import { Helmet } from 'react-helmet';

const Profile = () => {
  return (
    <Layout footer={true}>
      <Helmet>
        <title>Profile</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      <div className="profile">
        <div className="profile__header">
          <h2>Profile</h2>
        </div>
        <ProfileForm />
      </div>
    </Layout>
  );
};

export default Profile;
