import { GET_ALL_COURSE } from '../actions/types';

const initialState = {
  courseLoading: true,
  courses: [],
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_COURSE:
      return { ...state, courseLoading: false, courses: action.payload };

    default:
      return { ...state };
  }
};

export default courseReducer;
