import React from 'react';
import renderHTML from 'react-render-html';
import '../../pages/CourseDetails/CourseDetails.css';

const Description = ({ courseDetails }) => {
  const description =
    courseDetails?.course_description !== undefined
      ? courseDetails?.course_description
      : courseDetails?.description;
  const finalDescription = description?.length > 0 ? description : '';

  return (
    <div className="courseContent">
      <div className="courseContent__wrapper">
        <h3>Description</h3>

        <div className="courseContent__body">
          {renderHTML(finalDescription)}
        </div>
      </div>
    </div>
  );
};

export default Description;
