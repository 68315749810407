import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FieldTimeOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import './CourseCard.css';
import { Tooltip } from 'antd';

const CourseCard = ({ course, bundle }) => {
  const [enrolled, setEnrolled] = useState(false);
  const [categoryName, setCategoryName] = useState('');

  const { user } = useSelector((state) => state.auth);

  const history = useHistory();

  useEffect(() => {
    if (course?.category && course?.category?.length > 0) {
      fetch(
        `${process.env.REACT_APP_DEV_URL}/api/course/category/${course?.category[0]}`,
        {
          method: 'GET',
        }
      )
        .then((r) => r.json())
        .then((res) => {
          setCategoryName(res?.message?.category_name);
        });
    }
  }, [course]);

  useEffect(() => {
    if (user?.enrolled_courses?.length > 0) {
      const findCourse = user?.enrolled_courses?.find(
        (us) => us?._id === course?._id
      );
      if (findCourse && findCourse !== undefined) {
        setEnrolled(true);
      } else {
        setEnrolled(false);
      }
    }
  }, [user, course]);

  const name = course?.title !== undefined ? course?.title : course?.name;
  const text = name;
  const cutName = name?.length > 0 ? name.substr(0, 56) : '';

  return (
    <Tooltip placement="right" title={text}>


      <div
        onClick={() => {
          history.push(`/courses/${course?.slug}?bundle=${bundle}`)
          // course?.enrollOff ? ""  : history.push(`/courses/${course?.slug}?bundle=${bundle}`)

        }}
        className="courseCard"
      >
        <div className="courseCard__wrapper">
          <div>
            <div className="courseCard__wrapper__image">
              <img
                src={
                  course?.upload_featured_image !== undefined
                    ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${course?.upload_featured_image}`
                    : course?.image !== undefined
                      ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${course?.image}`
                      : '/course.jpg'
                }
                alt="Blog"
              // style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
              <div className="overlay"></div>
              {course?.course_paid === false && <p className="free">Free</p>}
              {course?.course_live === true && <p className="live">Live</p>}
            </div>
            <div className="courseCard__wrapper__info__button__wrapper">
              {course?.enrollOff ? <button

                style={{
                  width: "150px",
                  height: "45px",
                  backgroundColor: "red",
                  cursor: "not-allowed",
                  color: "white",
                  border: '0px',
                  borderRadius: '30px'
                }}
                disabled={true}
              >
                Enroll Off Now
              </button> : <button
                className="button-2"
                style={{ background: enrolled ? '#45c881' : '' }}
              >
                {enrolled ? (
                  <span
                    onClick={() => history.push(`/watching/${course?._id}`)}
                  >
                    Enrolled
                  </span>
                ) : (
                  'Enroll Now'
                )}
              </button>}

            </div>
          </div>
          <div className="courseCard__wrapper__info">
            {categoryName && (
              <p style={{ marginTop: '20px' }} className="badge">
                {categoryName}
              </p>
            )}

            <div className="courseCard__name__wrapper">
              <h4>{`${cutName} ${name?.length > 56 ? '.....' : ''} `}</h4>
            </div>
          </div>
          <div className="courseCard__wrapper__action">
            <hr />
            <div className="courseCard__wrapper__action__info">
              <p>
                <span>৳</span>
                <span>
                  {course?.course_paid === false
                    ? 'Free'
                    : course?.sale_price !== undefined
                      ? course?.sale_price
                      : course?.price}
                </span>
                {course?.regular_price !== 0 &&
                  course?.regular_price !== course?.sale_price && (
                    <span
                      style={{
                        textDecoration: 'line-through',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        marginLeft: '4px',
                        color: 'red',
                      }}
                    >
                      {course?.regular_price}
                    </span>
                  )}
              </p>
              {!bundle && (
                <>
                  {' '}
                  <p>
                    <span>
                      <UsergroupAddOutlined />
                    </span>
                    <span>{course?.enrollement_count}</span>
                  </p>
                  <p>
                    <span>
                      <FieldTimeOutlined />
                    </span>
                    <span>{course?.course_duration}</span>
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default CourseCard;
