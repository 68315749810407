import React, { useEffect, useState, useRef } from 'react';
import Layout from '../../components/Layout';
import { message } from 'antd';
import { useForm } from 'react-hook-form';
import {
  getCurrentUser,
  forgotPassword,
  authResetPassword,
} from '../../actions/auth';
import { useHistory } from 'react-router-dom';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../Signup/Auth.css';
import LoginImage from '../../images/loginImage.svg';

const Login = ({ getCurrentUser }) => {
  const { register, handleSubmit, errors, reset, watch } = useForm();
  const [refreshToken, setRefreshToken] = useState(null);
  const [email, setEmail] = useState(null);
  const [resetPassword, setResetPassword] = useState(false);
  const [status, setStatus] = useState(false);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const password = useRef({});
  password.current = watch('newPassword', '');

  useEffect(() => {
    if (typeof window !== undefined) {
      setRefreshToken(localStorage.getItem('auth_refresh_token'));
    }
  }, []);

  const onSubmit = async (data) => {
    if (!resetPassword) {
      setStatus(true);
      setEmail(data?.email);
      forgotPassword(data?.email)
        .then((res) => {
          if (res?.data?.success) {
            setStatus(false);
            message.success(res?.data?.message);
            setResetPassword(true);
            reset();
          } else {
            message.error(res?.data?.message);
            setStatus(false);
          }
        })
        .catch((err) => {
          message.error(err?.response?.data);
          setStatus(false);
        });
    } else {
      if (data?.code && data?.newPassword) {
        setStatus(true);
        authResetPassword(email, data?.code, data?.newPassword)
          .then((res) => {
            
            if (res?.data?.success) {
              setStatus(false);
              message.success(res?.data?.message);
              reset();
              history.push('/login');
            } else {
              message.error(res?.data?.message);
              setStatus(false);
            }
          })
          .catch((err) => {
            message.error(err?.response?.data);
            setStatus(false);
          });
      }
    }
  };

  if (refreshToken) {
    history.push('/');
  }

  return (
    <Layout footer={false}>
      <section className="login">
        <div className="login__wrapper container">
          <div className="login__wrapper__left">
            <img
              className="login__wrapper__left__loginImage"
              src={LoginImage}
              alt="Login"
            />
          </div>
          <div className="login__wrapper__right">
            <div className="login__wrapper__right__container">
              <h2>
                {resetPassword
                  ? 'Reset your password'
                  : 'Forgot your password?'}
              </h2>

              <form onSubmit={handleSubmit(onSubmit)}>
                {!resetPassword ? (
                  <>
                    <input
                      ref={register({
                        require: true,
                        validate: (value) => {
                          return !!value.trim();
                        },
                      })}
                      type="text"
                      name="email"
                      placeholder="Enter email"
                      required
                    />
                    {errors.email && (
                      <span className="error">This field is required</span>
                    )}
                    <br />
                    <br />
                  </>
                ) : (
                  <>
                    <input
                      ref={register({
                        require: true,
                        validate: (value) => {
                          return !!value.trim();
                        },
                      })}
                      type="text"
                      name="code"
                      placeholder="Enter code"
                      required
                    />
                    {errors.code && (
                      <span className="error">This field is required</span>
                    )}
                    <br />
                    <div style={{ position: 'relative' }}>
                      <input
                        ref={register({
                          required: 'You must specify a password',
                          minLength: {
                            value: 8,
                            message: 'Password must have at least 8 characters',
                          },
                          validate: (value) => {
                            return !!value.trim();
                          },
                        })}
                        type={show ? 'text' : 'password'}
                        name="newPassword"
                        placeholder="Enter new password"
                        required
                      />
                      <p
                        style={{
                          position: 'absolute',
                          top: '32px',
                          right: '20px',
                          cursor: 'pointer',
                          marginBottom: '0px',
                        }}
                      >
                        {show ? (
                          <span onClick={() => setShow(false)}>Hide</span>
                        ) : (
                          <span onClick={() => setShow(true)}>Show</span>
                        )}
                      </p>
                    </div>
                    {errors.newPassword && (
                      <span className="error">
                        {errors.newPassword.message}
                      </span>
                    )}

                    <input
                      name="password_repeat"
                      type="password"
                      ref={register({
                        validate: (value) =>
                          value === password.current ||
                          'The passwords do not match',
                      })}
                      placeholder="Confirm Password"
                    />
                    {errors.password_repeat && (
                      <span className="error">
                        {errors.password_repeat.message}
                      </span>
                    )}
                    <br />
                    <br />
                  </>
                )}

                <div>
                  <button
                    style={{ borderRadius: '4px', width: '100%' }}
                    className="button-2"
                  >
                    {!status ? 'Send' : 'Sending...'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

Login.prototype = {
  getCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentUser })(Login);
