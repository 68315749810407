import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import axios from "axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { get } from "react-hook-form";

const AffiliateList = () => {
  const { affiliateId } = useParams();
  const { user } = useSelector((state) => state.auth);

  const [affiliateHistory, setAffiliateHistory] = useState([]);

  const getAffiliateHistory = async (userId) => {
    try {
      const config = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const getResponse = await axios.get(
        `${process.env.REACT_APP_DEV_URL}/api/affiliate/get/all/user/payment/${affiliateId}`,

        config
      );
      const affData = getResponse?.data.data;
      console.log({ affData });

      setAffiliateHistory(affData);
    } catch {
      console.log("error");
    }
  };

  useEffect(() => {
    if (affiliateId) {
      getAffiliateHistory();
    }
  }, [affiliateId]);
  return (
    <Layout footer={true}>
      <div>
        <h2
          style={{
            color: "#093054",
            opacity: "0.8",
            fontWeight: "700",
          }}
          className="text-center mt-3 mb-3"
        >
          {" "}
          Affiliate History
        </h2>
        <div className="table-responsive mb-3">
          {affiliateHistory?.length > 0 ? (
            <table className="table table-bordered table-hover w-75 mx-auto">
              <thead className="thead  tablehead">
                <tr>
                  <th scope="col">Serial</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Bkash Number</th>
                  <th scope="col">Request Date</th>
                  <th scope="col">Paid Date</th>
                  <th scope="col">Transaction Id</th>
                  <th scope="col">Comment</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody className="tablebody">
                {affiliateHistory?.map((d, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{d?.amount}</td>
                      <td>
                        {d?.bkashNumber ? d?.bkashNumber : "No number provided"}
                      </td>
                      <td>{d?.requestDate?.slice(0, 10)}</td>
                      <td>{d?.paidDate?.slice(0, 10)}</td>
                      <td>{d?.transactionId}</td>
                      <td>
                        {/* {d?.comment?.length >= 30 ? (
                                  <BreakStringIntoLines text={d?.comment} />
                                ) : ( */}
                        {d?.comment}
                      </td>
                      <td>{d?.status}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="mt-5 mb-5 d-flex justify-content-center align-items-center">
              <h2 className="text-danger font-bold">No History Now</h2>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default AffiliateList;
