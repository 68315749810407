import axios from 'axios';
import { GET_ALL_COURSE } from './types';

/**
 *
 * * Get All Section Details
 */
export const getAllCourses = () => async (dispatch) => {
  const res = await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/course/get`
  );
  if (res?.data?.success) {
    dispatch({
      type: GET_ALL_COURSE,
      payload: res?.data?.message,
    });
  }
};

/**
 *
 * * Get Specific Course Details
 */
export const getSpecificCourse = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/course/get/slug/${slug}`
  );
};

/**
 *
 * * Get Specific Course Details
 */
export const getSpecificCourseById = async (id) => {
  return await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/course/get/${id}`
  );
};

//** Get Specific Bundle Course */
export const getSpecificBundleCourse = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/bundle/get/slug/${slug}`
  );
};

/**
 * * Get Specific Section Details
 */
export const getSpecificSection = async (sectionId) => {
  return await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/chapter/get/${sectionId}`
  );
};

/**
 * * Enroll Free Course
 */

export const enrollCourse = async (courseId, email) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { email };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/enrolment/${courseId}`,
    body,
    config
  );
};

/**
 * * Enroll Paid Course
 */

export const enrollPaidCourse = async (courseId, values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = values;
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/checkout/${courseId}`,
    body,
    config
  );
};

/**
 * * Enroll Bundle course
 */

export const enrollBundleCourse = async (id, values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = values;
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/enrolment/courses/bundle/${id}`,
    body,
    config
  );
};

/**
 * * Get the all enrolled course by specific user
 */

export const getEnrolledCourse = async (email) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { email };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/enrolement/coursedetails`,
    body,
    config
  );
};

//** markAsCompleted lesson */

export const markAsCompleted = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = values;
  return await axios.put(
    `${process.env.REACT_APP_DEV_URL}/api/lesson/completed`,
    body,
    config
  );
};

//** markAsInCompleted lesson */

export const markAsInCompleted = async (values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = values;
  return await axios.put(
    `${process.env.REACT_APP_DEV_URL}/api/lesson/notcompleted`,
    body,
    config
  );
};

//** Get Completed lesson by userId */

export const getMarkedLesson = async (userId, course_id) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { course_id };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/lesson/${userId}`,
    body,
    config
  );
};

//** Get the course completion percentage */

export const getCourseCompletion = async (userId, courseId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = { course_id: courseId };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/course/completion/${userId}`,
    body,
    config
  );
};

//** get Featured Course */

export const getFeaturedCourse = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/course/all/featured`,
    config
  );
};

//** Apply coupon */

export const applyCoupon = async (coupon, email, courseId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = {
    coupon,
    email,
    course_id: courseId,
  };
  return await axios.put(
    `${process.env.REACT_APP_DEV_URL}/api/coupon-apply`,
    body,
    config
  );
};

//** Check course blocked or not */
export const checkCourseBlocked = async (courseId, userId) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  const body = {
    course_id: courseId,
    user_id: userId,
  };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/students/course/isblocked`,
    body,
    config
  );
};

export const getLessonList = async (sectionId) => {
  return await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/lesson/getall/${sectionId}`
  );
};

//** Last Lesson update */

export const updateLastLesson = async (course_id, user_id, lesson_id) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  const body = {
    course_id,
    user_id,
    lesson_id,
  };
  return await axios.put(
    `${process.env.REACT_APP_DEV_URL}/api/last-stream-lesson/update`,
    body,
    config
  );
};

//** Get Last Lesson update */

export const getLastLesson = async (course_id, user_id) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  const body = {
    course_id,
    user_id,
  };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/last-stream-lesson/find`,
    body,
    config
  );
};
