import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import EnrolledCourse from '../../components/DashboardComponent/EnrolledCourse';
import { useSelector } from 'react-redux';
import './Dashboard.css';
import { Helmet } from 'react-helmet';

const Dashboard = () => {
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const { user } = useSelector((state) => state.auth);

  // Get Enrolled Course
  useEffect(() => {
    if (user?.enrolled_courses !== undefined) {
      const filterEnrolledCourses =
        user?.enrolled_courses?.length > 0
          ? [
              ...new Map(
                user?.enrolled_courses.map((item) => [item['_id'], item])
              ).values(),
            ]
          : [];

      setEnrolledCourses(filterEnrolledCourses);
    }
  }, [user]);

  return (
    <Layout footer={true}>
      <Helmet>
        <title>Dashboard</title>
        <meta
          property="og:description"
          content="This is an interactive online learning platform for all professionals and engineers all over the world. Those who want to grow and develop professional skills should be connected with us."
          data-react-helmet="true"
        />
      </Helmet>
      <div style={{ minHeight: "100vh" }} className="">
        <div className="container">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
              marginBottom: "100px",
            }}
          >
            {enrolledCourses?.length > 0
              ? enrolledCourses?.map((course, i) => (
                  <EnrolledCourse
                    bundle={course?.bundle}
                    key={i}
                    course={course}
                  />
                ))
              : "No enrolled course found!"}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
