import React from "react";
import "../../pages/LandingPage/LandingPage.css";
import AccessImage from "../../images/access.svg";
import LearnImage from "../../images/learn.svg";
import TeacherImage from "../../images/teacher.svg";
import Iso from "../../images/iso.svg";
const ChooseUs = () => {
  const ChooseUsCard = (link, title, subtitle) => (
    <div>
      <img src={link} alt="Unlimited Access" width={130} height={130} />
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </div>
  );

  return (
    <section className="chooseUs">
      <div className="chooseUs__wrapper container">
        <div className="chooseUs__wrapper__intro">
          <h2>
            Why Choose Us <span>To Learn</span>
          </h2>
          <p>
            With the help these features you can learn the needed lessons as per
            your need.
          </p>
        </div>
        <div className="chooseUs__wrapper__body">
          {ChooseUsCard(
            AccessImage,
            "Learn Anytime",
            "Anytime you can see the course content through internet"
          )}
          {ChooseUsCard(
            LearnImage,
            "Learn Anywhere",
            "Switch between your computer, tablet, or mobile device"
          )}
          {ChooseUsCard(
            TeacherImage,
            "Expert Teacher",
            "Learn from industry experts who are passionate about teaching"
          )}
          <div>
            <img src={Iso} alt="Unlimited Access" width={130} height={130} />
            <h3>ISO Certified</h3>
            <p >
              For the first Time in Bangladesh, Qlearn Achieve
              <span
                style={{
                  fontWeight: "bolder",
                  fontFamily: "20px",
                }}
              >
                {" "}
                ISO 21001:2018 (EOMS)
              </span>
              Certificate. Learn from ISO Certified Institute
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ChooseUs;
