import axios from "axios";

export const createAffiliate = async(userId) =>{
    const payload = {
        userId
    }
    const config = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };
    return await axios.post(
        `${process.env.REACT_APP_DEV_URL}/api/affiliate/create`,
        payload,
        config
    );
}


export const getAffiliate = async (userId) =>{
    const config = {
        "Content-Type": "application/json",
        Accept: "application/json",
    };
    return await axios.get(
        `${process.env.REACT_APP_DEV_URL}/api/affiliate/get/${userId}`,
       
        config
    );
}