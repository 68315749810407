import React, { useRef, useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { studentRegister } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { getCurrentUser, studentLogin } from "../../actions/auth";

import { LOGIN, GET_CURRENT_USER } from "../../actions/types";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SignupImage from "../../images/signupImage.svg";
import "./Auth.css";
import GoogleAuthentication from "../GoogleLogin/GoogleAuthentication";
import GoogleFirebase from "../GoogleFirebase/GoogleFirebase";
import { Helmet } from "react-helmet";

const Signup = ({ getCurrentUser }) => {
  const [refreshToken, setRefreshToken] = useState(null);
  const [show, setShow] = useState(false);
  const { register, handleSubmit, watch, errors } = useForm();
  const password = useRef({});
  password.current = watch("password", "");

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== undefined) {
      setRefreshToken(localStorage.getItem("auth_refresh_token"));
    }
  }, []);

  const onSubmit = (data) => {
    const content = {
      // userName: data?.userName,
      fullName: data?.name,
      email: data?.email,
      phoneNumber: data?.number,
      password: data?.password,
    };

    console.log(content);

    studentRegister(content)
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);

          console.log(res?.data);

          getCurrentUser(res?.data?.message?._id);

          typeof window !== undefined &&
            localStorage.setItem("userId", res?.data?.message?._id);

          // history.push(`/verify-email?email=${data?.email}`);
          dispatch({
            type: GET_CURRENT_USER,
            payload: res?.data?.message,
          });
          sessionStorage.getItem("redirectURL")
            ? history.push(sessionStorage.getItem("redirectURL"))
            : history.push("/");
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message === undefined) {
          message.error(err?.response?.data);
        } else {
          message.error(err?.response?.data?.message);
        }
      });
  };

  if (refreshToken) {
    history.push("/");
  }

  return (
    <Layout footer={false}>
      <Helmet>
        <title>Sign Up</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      <section className="login">
        <div className="login__wrapper container">
          <div className="login__wrapper__left">
            <img
              className="login__wrapper__left__loginImage"
              src={SignupImage}
              alt="Signup"
            />
          </div>
          <div className="login__wrapper__right">
            <div className="login__wrapper__right__container">
              <h2>Create an account</h2>

              <form onSubmit={handleSubmit(onSubmit)}>
                {/* <input
                  ref={register({
                    required: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  })}
                  name="userName"
                  type="text"
                  placeholder="Username"
                />
                {errors.userName && (
                  <span className="error">This field is required</span>
                )} */}
                <input
                  ref={register({
                    required: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  })}
                  name="name"
                  type="text"
                  placeholder="Full Name"
                />
                {errors.name && (
                  <span className="error">This field is required</span>
                )}

                <input
                  ref={register({
                    required: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  })}
                  name="email"
                  type="email"
                  placeholder="Email"
                />
                {errors.email && (
                  <span className="error">This field is required</span>
                )}

                <input
                  ref={register({
                    required: "This field is required",
                    // minLength: {
                    //   value: 11,
                    //   message: "Mobile Number must have 11 digit",
                    // },
                    // maxLength: {
                    //   value: 11,
                    //   message: "Mobile Number must have 11 digit",
                    // },
                  })}
                  name="number"
                  type="number"
                  min="0"
                  onWheel={(e) => e.target.blur()}
                  placeholder="Mobile Ex: 01234567890"
                />

                {errors.number && (
                  <span className="error">{errors.number.message}</span>
                )}

                <div style={{ position: "relative" }}>
                  <input
                    type={show ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    ref={register({
                      required: "You must specify a password",
                      minLength: {
                        value: 8,
                        message: "Password must have at least 8 characters",
                      },
                      validate: (value) => {
                        return !!value.trim();
                      },
                    })}
                  />
                  <p
                    style={{
                      position: "absolute",
                      top: "32px",
                      right: "20px",
                      cursor: "pointer",
                    }}
                  >
                    {show ? (
                      <span onClick={() => setShow(false)}>Hide</span>
                    ) : (
                      <span onClick={() => setShow(true)}>Show</span>
                    )}
                  </p>
                </div>
                {errors.password && (
                  <span className="error">{errors.password.message}</span>
                )}

                {/* <input
                  name="password_repeat"
                  type="password"
                  ref={register({
                    validate: (value) =>
                      value === password.current ||
                      "The passwords do not match",
                  })}
                  placeholder="Confirm Password"
                />
                {errors.password_repeat && (
                  <span className="error">
                    {errors.password_repeat.message}
                  </span>
                )} */}

                <div>
                  <button
                    style={{
                      borderRadius: "4px",
                      width: "100%",
                      marginTop: "20px",
                    }}
                    className="button-2"
                  >
                    Sign up
                  </button>
                </div>
              </form>
              <p className="login__wrapper__right__container__or">Or </p>
              {/* <div className="login__wrapper__right__container__social__login">
                <button>
                  <img src={colorGoogle} alt="Google" /> Signin with Google
                </button>
                <button>
                  <img src={colorFacebook} alt="Google" /> Signin with Facebook
                </button>
              </div> */}
              <div className="d-flex justify-content-center">
                {/* <GoogleAuthentication></GoogleAuthentication> */}
                <GoogleFirebase></GoogleFirebase>
              </div>

              <p style={{ marginTop: "30px", textAlign: "center" }}>
                Already have an account?{" "}
                <Link to="/login">
                  <span>
                    <span style={{ color: "var(--secondary-color)" }}>
                      Login
                    </span>
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
Signup.prototype = {
  getCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentUser })(Signup);
