import React from 'react';
import Layout from '../../components/Layout';
import './BecomeAInstructor.css';

function BecomeAInstructor() {
  return (
    <Layout footer={false}>
      <div className="container">
        <div style={{ height: '90vh' }} className="becomeAInstructor__embed">
          <div class="container__iframe" style={{ height: '100%' }}>
            <iframe
              class="responsive-iframe"
              title="Become a instructor"
              src="https://docs.google.com/forms/d/e/1FAIpQLScLL2g0csx5SGUvh48VMeOJGLN1WvHHOY19RSsxdQfpCEPa5g/viewform?embedded=true"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BecomeAInstructor;
