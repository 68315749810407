import React from 'react';
import { Result } from 'antd';
import { useParams, useHistory } from 'react-router-dom';

const Success = () => {
  const { id } = useParams();
  const history = useHistory();
  return (
    <div>
      <Result
        status="success"
        title="Successfully Purchased!"
        subTitle={`Transaction Id: ${id} `}
        extra={[
          <button
            onClick={() => history.push('/dashboard')}
            style={{
              background: '#52c41a',
              color: 'white',
              border: 'none',
              padding: '5px 20px',
              borderRadius: '3px',
              cursor: 'pointer',
            }}
            type="primary"
            key="console"
          >
            Dashboard
          </button>,
        ]}
      />
    </div>
  );
};

export default Success;
