import React from 'react';
import teacherImage from '../../images/teacherImage.jpg';
import teacherFacebook from '../../images/teacher-facebook.svg';
import teacherYoutube from '../../images/teacher-youtube.svg';
import '../../pages/Teachers/Teachers.css';

const TeacherCard = ({ info }) => {
  const details = info?.short_details;
  const finalDetails = details?.length > 0 ? details?.substr(0, 256) : '';
  return (
    <div className="teacher__card">
      <div className="teacher__card__wrapper">
        <div className="teacher__card__wrapper__img">
          <img
            src={
              info?.image !== undefined
                ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${info?.image}`
                : teacherImage
            }
            alt="Teacher"
          />
        </div>
        <div className="teacher__card__wrapper__info">
          <h4>{info?.fullName}</h4>
          {/* <p>
            {finalDetails} {details?.length > 256 ? '....' : ''}
          </p> */}
          <div>
            <ul>
              {info?.facebookUrl && (
                <li>
                  <a rel="noreferrer" target="_blank" href={info?.facebookUrl}>
                    <img src={teacherFacebook} alt="Facebook" />
                  </a>
                </li>
              )}

              {info?.youtubeUrl && (
                <li>
                  <a rel="noreferrer" target="_blank" href={info?.youtubeUrl}>
                    <img src={teacherYoutube} alt="Youtube" />
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherCard;
