import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import BookInfo from '../../components/BookDetailsPageComponent/BookInfo';
import WriterInfo from '../../components/BookDetailsPageComponent/WriterInfo';
import Download from '../../components/BookDetailsPageComponent/Download';
// import UserReview from '../../components/SharedComponents/UserReview';
import Book from '../../components/SharedComponents/Book';
// import ReviewAndRatings from '../../components/SharedComponents/ReviewAndRatings';
import { useParams } from 'react-router-dom';
import { getSpecificBook } from '../../actions/book';
import './BookDetails.css';
import { Helmet } from 'react-helmet';

const BookDetails = () => {
  const [bookDetails, setBookDetails] = useState(null);

  const { slug } = useParams();

  useEffect(() => {
    getSpecificBook(slug)
      .then((res) => {
        if (res?.data?.success) {
          setBookDetails(res?.data?.data);
        }
      })
      .catch((err) => console.log(err));
  }, [slug]);

  return (
    <Layout footer={true}>
      <Helmet>
        <title>{bookDetails?.book_name}</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      <section className="title__wrapper">
        <h1>{bookDetails?.book_name}</h1>
      </section>
      <BookInfo bookDetails={bookDetails} />
      <Download bookDetails={bookDetails} />
      <WriterInfo bookDetails={bookDetails} />
      <Book title={`<h3> Recommended books for you </h3>`} />
      {/* <ReviewAndRatings />
      <UserReview /> */}
    </Layout>
  );
};
export default BookDetails;
