import Layout from "../../components/Layout";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./CheckCertificate.css";
import certificateSuccess from "../../images/certificateSuccess.webp";
import right from "../../images/right.png";
import wrong from "../../images/wrong.png";
import { Helmet } from "react-helmet";
const CheckCertificate = () => {
  const [certificateID, setCertificateID] = useState("");
  const [success, setSuccess] = useState(null);

  const handleCourseChange = () => {
    fetch(
      `${process.env.REACT_APP_DEV_URL}/api/certificate/verification/${certificateID}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setSuccess(data);
      });
  };
  return (
    <Layout footer={true}>
      <Helmet>
        <title>Check Certificate</title>
        <meta name="description" property="og:description" content="" />
      </Helmet>
      <div className="CertificateIDInputDiv">
        <label className="certificateLabel" htmlFor="certificateID">
          Certificate ID :
        </label>
        <input
          id="name"
          onChange={(e) => setCertificateID(e.target.value)}
          type="text"
          placeholder="Enter Certificate ID"
          required
        />
        <button className="saveButton" onClick={() => handleCourseChange()}>
          <span className="saveText">Search</span>
        </button>

        {success && success?.success ? (
          <div>
            <div className="d-flex justify-content-center certificateResult">
              <img className="rightImage" src={right} alt="Right"></img>
              <h2>Successfully Found the certificate</h2>
            </div>
            <div className="text-center mt-3">
              <h5>
                This certificate belongs to :{" "}
                <span className="certificateEmail">
                  {success?.data?.userName}
                </span>
              </h5>
              <h5>
                Complete the course :{" "}
                <span className="certificateEmail">
                  {success?.data?.courseName}
                </span>
              </h5>
            </div>
            <div className="d-flex justify-content-center">
              <img
                className="certificatePic"
                src={certificateSuccess}
                alt="Certificate_success"
              ></img>
            </div>
          </div>
        ) : null}
        {success && !success?.success ? (
          <div>
            <div className="d-flex justify-content-center certificateResult">
              <img className="rightImage" src={wrong} alt="Right"></img>
              <h2>Can't Find the certificate</h2>
            </div>
            <div className="d-flex justify-content-center">
              <img
                className="certificatePic"
                src={certificateSuccess}
                alt="Certificate_success"
              ></img>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  );
};

export default CheckCertificate;
