import { useEffect, useState } from 'react';
import { getSpecificSection } from '../../actions/course';

const useLessonHandler = (courseDetails) => {
  const [sectionDetails, setSectionDetails] = useState([]);
  const [sortedSections, setSortedSections] = useState([]);
  const [allLessons, setAllLessons] = useState([]);
  const chapter = courseDetails?.chapter;

  //* all about section
  useEffect(() => {
    if (sectionDetails?.length < chapter?.length && chapter?.length > 0) {
      chapter?.forEach((sec, index) => {
        getSpecificSection(sec).then((res) => {
          if (res?.data?.success) {
            setSectionDetails((sectionDetails) => [
              ...sectionDetails,
              res?.data?.message,
            ]);
          }
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chapter]);

  useEffect(() => {
    const sortedSecs = sectionDetails.sort(function (a, b) {
      var dateA = new Date(a.updated_at),
        dateB = new Date(b.updated_at);
      return dateA - dateB;
    });

    setSortedSections(sortedSecs);
  }, [sectionDetails]);

  //* all about lesson
  useEffect(() => {
    if (sectionDetails?.length > 0) {
      // flatting multiple lesson array
      const lessArray = sectionDetails?.map((sd) => sd?.lessons).flat();

      // sorting by date.
      const sortedLessArray = lessArray.sort(function (a, b) {
        var dateA = new Date(a.createdAt),
          dateB = new Date(b.createdAt);
        return dateA - dateB;
      });

      setAllLessons(sortedLessArray);
    }
  }, [sectionDetails]);

  return { sections: sortedSections, filterAllLessons: allLessons };
};
export default useLessonHandler;
