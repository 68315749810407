import React from 'react';
import '../../pages/BookDetails/BookDetails.css';

const WriterInfo = ({ bookDetails }) => {
  return (
    <div className="bookInfo">
      <div className="bookInfo__wrapper container">
        <div className="bookInfo__wrapper__left writterInfo__wrapper__left">
          <img
            src={
              bookDetails?.author_image
                ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${bookDetails?.author_image}`
                : ''
            }
            alt="Book Name"
          />
        </div>
        <div className="bookInfo__wrapper__right">
          <h2>Writer Details</h2>
          <h3 style={{ textAlign: 'center' }}>{bookDetails?.author_name}</h3>
          {bookDetails?.author_description}
        </div>
      </div>
    </div>
  );
};

export default WriterInfo;
