import axios from 'axios';

/**
 *
 * * Get All Blog list
 */
export const getBlogList = async () => {
  return await axios.get(`${process.env.REACT_APP_DEV_URL}/api/blog/getall`);
};

/**
 *
 * * Get specific blog
 */
export const getSpecificBlog = async (slug) => {
  return await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/blog/get/slug/${slug}`
  );
};
