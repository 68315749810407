import React, { useEffect, useState } from 'react';
import CourseCard from '../SharedComponents/CourseCard';
import { RightCircleOutlined } from '@ant-design/icons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getAllCourses } from '../../actions/course';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import '../../pages/LandingPage/LandingPage.css';

const FeaturedCourse = ({ getAllCourses }) => {
  const history = useHistory();
  useEffect(() => {
    getAllCourses();
  }, []);

  // const { courses, courseLoading } = useSelector((state) => state.course);
  const [courses, setCourses] = useState([]);
  const [title, setTitle] = useState('');
  const [courseLoading, setCourseLoading] = useState(false);
  const [finalFilter, setFinalFilter] = useState([]);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_URL}/api/course/featured`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.success) {
          setTitle(data?.message[0]?.featureCourseTitle);
          setCourses(data?.message?.length > 0 ? data?.message[0]?.course : []);
        }
      })
      .catch((err) => console.log(err?.response?.data));
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const datas = courses?.filter((c) => c?.course_publish === true);
    setFinalFilter(datas);
  }, [courses]);

  return (
    <div className="course">
      <div className="course__wrapper container">
        <h2>{title}</h2>

        <div className="course__wrapper__body">
          {courseLoading ? (
            'Loading...'
          ) : finalFilter?.length > 0 ? (
            <Carousel
              infinite={true}
              renderArrowsWhenDisabled={true}
              responsive={responsive}
            >
              {finalFilter?.map((course, i) => (
                <CourseCard
                  bundle={course?.name !== undefined ? true : false}
                  course={course}
                  key={i}
                />
              ))}
            </Carousel>
          ) : (
            'No course found!'
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          <button onClick={() => history.push(`/courses`)} className="see-all">
            See All <RightCircleOutlined style={{ fontSize: '17px' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

FeaturedCourse.prototype = {
  getAllCourses: PropTypes.func.isRequired,
};

export default connect(null, { getAllCourses })(FeaturedCourse);
