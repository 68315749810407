import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";
import { createAffiliate, getAffiliate } from "../../actions/affiliate";
import { useSelector } from "react-redux";
import {
  BarChartOutlined,
  HistoryOutlined,
  InfoOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import toastr from "toastr";
import axios from "axios";

import "./Affiliate.css";
import { message } from "antd";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const Affiliate = () => {
  const { user } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState({});
  const [isHovered, setIsHovered] = useState(false);
  const [divShow, setDivShow] = useState(false);

  const [state, setState] = useState({ amount: "", bkashNumber: "" });
const history = useHistory();
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (user?.affiliateNumber && !userData) {
      console.log("get affiliate data");
      getAffiliate(user?._id);
    } else {
      console.log("create affiliate data");
      createAffiliate(user?._id)
        .then((res) => {
          setUserData(res?.data?.data);
        })
        .catch((err) => {
          getAffiliate(user?._id);
        });
    }
  }, [user]);

  console.log({ userData });
  console.log({ user });

  const getAffiliate = async (userId) => {
    try {
      const config = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const getResponse = await axios.get(
        `${process.env.REACT_APP_DEV_URL}/api/affiliate/get/${userId}`,

        config
      );
      const affData = getResponse?.data.data;
      console.log({ affData });

      setUserData(affData);
    } catch {
      console.log("error");
    }
  };

  const [copied, setCopied] = useState(false);

  const handleCopyLink = (str) => {
    setCopied(true);
    const element = document.createElement("textarea");
    element.value = str;
    document.body.appendChild(element);
    element.select();
    document.execCommand("copy");
    document.body.removeChild(element);
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  console.log(state);

  const handleFromSubmit = async () => {
    if (state.amount > userData?.balance) {
      message.error("Can't withdraw money more than you have");
      return;
    }

    const payload = {
      amount: state.amount,
      bkashNumber: state.bkashNumber,
      affiliateNumber: userData?.affiliateNumber,
      requestDate: new Date(),
    };

    console.log({ payload });

    const config = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    const response = await axios.post(
      `${process.env.REACT_APP_DEV_URL}/api/affiliate/request/payment`,
      payload,
      config
    );

    if (response?.data?.success) {
      message.success(response?.data?.message);
      setState({ amount: "", bkashNumber: "" });
      setDivShow(false);
      getAffiliate(user?._id);
    }
    console.log(response);
  };
  return (
    <Layout footer={true}>
      <Helmet>
        <title>Affiliate</title>
        <meta
          name="description"
          property="og:description"
          content="Qlearn is an online learning platform for learners. Paid and free online courses are offered here by top professionals, experts, and professors for the benefit of young students, engineers, professionals who want to develop their career and lead an honest living. "
        />
      </Helmet>
      <div
        style={{
          width: "75%",
          margin: "auto",
        }}
        className="row gx-5 mt-3 mb-3 "
      >
        <div className="col-12 col-md-12  col-lg-4 border border-0 rounded">
          <div
            style={{
              backgroundColor: "#5906C2",
              padding: "20px",
              color: "gray",
            }}
            className="border border-0 rounded"
          >
            <p>Available commissions balance</p>
            <p
              style={{
                fontSize: "30px",
                fontWeight: "700",
                color: "white",
              }}
            >
              BDT {userData?.balance?.toFixed(2)} Tk
            </p>
            <hr></hr>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2,1fr)",
                gap: "20px",
              }}
            >
              <p>Commissions Pending Maturation</p>
              <p>BDT {userData.pendingAmount}Tk</p>

              <p>Total Amount Withdrawn</p>
              <p>BDT {userData?.withdraw}Tk</p>
            </div>

            {divShow ? (
              <div className="mb-0 mt-2">
                <form>
                  <div className="d-flex justify-content-between">
                    <label className="text-white">Amount</label>{" "}
                    <button
                      onClick={() => setDivShow(false)}
                      style={{
                        backgroundColor: "#f56e6c",
                        color: "white",
                      }}
                      className="border-0 px-2 rounded-circle"
                    >
                      X
                    </button>
                  </div>
                  <input
                    type="number"
                    name="amount"
                    onChange={(e) => handleChange(e)}
                    className="mt-1 w-100"
                    placeholder="Enter the amount want to withdraw"
                  ></input>
                  <br></br>

                  <label className="text-white mt-1">
                    Bkash Account Number{" "}
                  </label>
                  <input
                    type="number"
                    name="bkashNumber"
                    onChange={(e) => handleChange(e)}
                    className="mt-1 w-100"
                    placeholder="Enter bkash account number"
                  ></input>
                </form>
              </div>
            ) : null}

            <div className="d-flex justify-content-center mt-2">
              <button
                onClick={() => {
                  if (divShow) {
                    handleFromSubmit();
                  } else {
                    setDivShow(true);
                  }
                }}
                disabled={parseInt(userData?.balance) < 475}
                className={`border border-3 rounded px-3 py-1 text-center text-dark ${
                  parseInt(userData?.balance) < 475 ? "opacity-25" : ""
                }`}
              >
                Request Withdrawal
              </button>
            </div>

            <div className="d-flex justify-content-center mt-2">
              <button
                onClick={() => {
                  history.push(`/affiliateList/${userData?._id}`)
                }}

                style={{
                  backgroundColor: "#5906C2",
                  color:  "white",
                  borderRadius: "15px",
                  border: "1px solid white",
                  marginTop: "5px",
                  width: "60%",
                  maxWidth: "120px",
                }}
              
              >
                History
              </button>
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#E3EFFC",
            }}
            className="d-flex p-2 border border-0 rounded"
          >
            <InfoOutlined />
            <p
              style={{
                fontSize: "10px",
                color: "#378AE4",
              }}
            >
              You will be able to request a withdrawal as soon as your balance
              reaches the minimum required amount of BDT 475.00 Tk.
            </p>
          </div>
        </div>

        <div className="col-12 col-md-12 col-lg-8">
          <div className=" row gap-3 g-lg-3">
            <div
              className="col-12 col-md-12 col-lg-4 text-center p-3 border border-primary rounded"
              style={{}}
            >
              <div className="text-end">
                <HistoryOutlined />
              </div>
              <p
                style={{
                  fontSize: "50px",
                  fontWeight: "700",
                  margin: 0,
                  padding: 0,
                }}
              >
                {userData?.clicks}{" "}
              </p>
              <span className="mt-0">Clicks</span>
            </div>

            <div
              className="col-12 col-md-12 col-lg-4 text-center p-3 border border-primary rounded"
              style={{}}
            >
              <div className="text-end">
                <ShoppingCartOutlined />
              </div>
              <p
                style={{
                  fontSize: "50px",
                  fontWeight: "700",
                  margin: 0,
                  padding: 0,
                }}
              >
                {userData?.buyCourses}{" "}
              </p>
              <span>Course Buys</span>
            </div>

            <div
              className="col-12 col-md-12 col-lg-4 text-center p-3 border border-primary rounded "
              style={{}}
            >
              <div className="text-end">
                <BarChartOutlined />
              </div>
              <p
                style={{
                  fontSize: "50px",
                  fontWeight: "700",
                  margin: 0,
                  padding: 0,
                }}
              >
                {parseInt(userData?.clicks) === 0
                  ? 0
                  : (
                      parseInt(userData?.buyCourses) /
                      parseInt(userData?.clicks)
                    ).toFixed(2)}
                %{" "}
              </p>
              <span className="mt-0">Conversions</span>
            </div>

            {/* <div className="col-12 "> */}
            <div
              className="border border-2 rounded"
              style={{
                backgroundColor: "#F6F7F8",
                width: "100%",

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className=" mt-2 rounded d-sm-flex flex-sm-row"
                style={{
                  width: "98%",

                  backgroundColor: "white",
                }}
              >
                <p
                  style={{
                    fontSize: "0.70rem",
                    height: "full",
                  }}
                  className=" p-2 border border-2 rounded"
                >
                  Your unique referal Link
                </p>{" "}
                <p
                  style={{
                    fontSize: "0.75rem",
                    fontWeight: "600",
                  }}
                  className=" p-2 "
                >
                  {userData?.affiliateLink}
                </p>
              </div>
            </div>
            <button
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="p-2"
              style={
                copied
                  ? {
                      backgroundColor: "#F6F7F8",
                      color: "black",
                      borderRadius: "15px",
                      border: "2px solid #5906C2",
                      marginTop: "5px",
                      width: "60%",
                      maxWidth: "120px",
                      opacity: "0.5",
                    }
                  : {
                      backgroundColor: isHovered ? "#ffffffff" : "#5906C2",
                      color: isHovered ? "#002" : "white",
                      borderRadius: "15px",
                      border: isHovered ? "2px solid black" : "0px",
                      marginTop: "5px",
                      width: "60%",
                      maxWidth: "120px",
                    }
              }
              onClick={() => {
                handleCopyLink(userData?.affiliateLink);
              }}
            >
              {copied ? "Copied" : "Copy Link"}
            </button>
            {/* </div> */}
          </div>
        </div>
      </div>

      <div className="table-responsive mt-5 mb-3">
        {userData?.affiliateCourseId?.length > 0 ? (
          <table className="table table-bordered table-hover w-75 mx-auto">
            <thead className="thead  tablehead">
              <tr>
                <th scope="col" className="text-center">
                  Serial
                </th>
                <th scope="col" className="text-start">
                  Course Name
                </th>
                <th scope="col" className="text-start">
                  Price
                </th>
                <th scope="col" className="text-center">
                  Buying Date
                </th>
                <th scope="col" className="text-center">
                  Commision
                </th>
              </tr>
            </thead>
            <tbody className="tablebody">
              {userData?.affiliateCourseId?.map((d, index) => {
                return (
                  <tr key={index}>
                    <td data-label="No" className="text-center ">
                      {index + 1}
                    </td>
                    <td data-label="Course Name" className="text-start">
                      {d?.courseId?.title}
                    </td>
                    <td data-label="Price" className="text-center">
                      {d?.coursePrice}
                    </td>
                    <td data-label="Buying Date" className="text-center">
                      {d?.createdDate?.slice(0, 10)}
                    </td>
                    <td data-label="Comission" className="text-center">
                      {d?.commision}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <div className="mt-5 mb-5 d-flex justify-content-center align-items-center">
            <h2 className="text-danger font-bold">No History Now</h2>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Affiliate;
