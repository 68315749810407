import React from "react";
import { useHistory } from "react-router-dom";
import IntroImage from "../../images/Website-Hero-image-min.webp";
import "../../pages/LandingPage/LandingPage.css";

const Intro = () => {
  const history = useHistory();
  return (
    <section className="intro">
      <div className="intro__wrapper container">
        <div className="intro__wrapper__left">
          <h1>
            Quality <br /> Learning at Your Fingertip
          </h1>
          <p style={{ textAlign: "justify", fontWeight: "bold" }}>
            Qlearn is an online learning platform for learners. Paid and free
            online courses are offered here by top professionals, experts, and
            professors for the benefit of young students, engineers,
            professionals who want to develop their career and lead an honest
            living. The skills which could not be learned in academic life will
            be available in this interactive online learning platform from
            anywhere, anytime. These free online courses will be the starting
            point of your highly paid professional career.
          </p>
          <button
            onClick={() => {
              history.push("/seminar");
            }}
            style={{
              background: "#1c659ee0",
              color: "white",
              border: "none",
              padding: "5px 25px",
              borderRadius: "4px",
              cursor: "pointer",
              marginTop: "7px",
            }}
          >
            Free Seminar
          </button>
        </div>
        <div className="intro__wrapper__right">
          <img src={IntroImage} alt="Group" />
        </div>
      </div>
    </section>
  );
};

export default Intro;
