import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';

const Layout = ({ children, title, keywords, description, footer }) => {
  return (
    <div>
      {/* <Head>
        <title>{title}</title>
        <meta charSet="UTF-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />

        <meta property="og:title" content="Our Professor's" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.ourprofessors.com/" />
        <meta
          property="og:image"
          content="https://ia.media-imdb.com/images/rock.jpg"
        />

        
        
      </Head> */}
      <Header />
      {children}
      <Footer footer={footer} />
    </div>
  );
};

Layout.defaultProps = {
  title: 'Our Professors',
  description:
    'This is an interactive online learning platform for all professionals and engineers all over the world. Those who want to grow and develop professional skills should be connected with us.',
  keywords: 'Our Professor, Engineering, Course, Engineering Course, Professor',
};

export default Layout;
