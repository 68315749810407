import React, { useEffect, useState } from "react";
import "../../pages/CourseDetails/CourseDetails.css";
import CourseContent from "./CourseContent";
import CourseReview from "./CourseReview";
import Description from "./Description";
import PriceCard from "./PriceCard";

const CourseInfo = ({ courseDetails, bundle, review , price, applied, coupon, setCoupon, handleApply}) => {
  return (
    <div className="courseInfo">
      <div className="courseInfo__wrapper container">
        <div className="courseInfo__wrapper__left">
          <div style={{ marginTop: "40px" }}>
            {/* <div className="courseContent">
              <div className="courseContent__wrapper">
                <h3>Instructor</h3>

                <div className="courseContent__body">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        width: '100px',
                        height: '100px',
                        overflow: 'hidden',
                      }}
                    >
                      <img
                        style={{
                          width: '100%',
                          height: '100%',
                          borderRadius: '50%',
                          objectFit: 'fill',
                        }}
                        src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${instructorData?.image}`}
                        alt={instructorData?.fullName}
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: '20px' }}>
                    <h4>{instructorData?.fullName}</h4>
                    <p>
                      {finalDetails} {details?.length > 210 ? '.......' : ''}
                    </p>
                  </div>
                </div>
              </div>

=======
            </div> */}
          </div>
          <Description courseDetails={courseDetails} />
          {courseDetails?.course === undefined && (
            <CourseContent courseDetails={courseDetails} />
          )}
          <CourseReview review={review}></CourseReview>
        </div>

        <div id="price_card" className="courseInfo__wrapper__right">
          <PriceCard bundle={bundle} courseDetails={courseDetails} price={price}
            applied={applied}
            coupon={coupon}
            setCoupon={setCoupon}
            handleApply={handleApply} />
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
