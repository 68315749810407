import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import TeacherCard from '../../components/SharedComponents/TeacherCard';
import { Pagination } from 'antd';
import { getAllInstructors } from '../../actions/teachers';
import './Teachers.css';

const pageSize = 9;

const Teachers = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getAllInstructors().then((res) => {
      if (res?.data?.success) {
        setData(res?.data?.message);
      }
    });
  }, []);

  const [state, setState] = useState({
    totalPage: data?.length / pageSize,
    current: 1,
    minIndex: 0,
    maxIndex: pageSize,
  });



  const handleChange = (page) => {
    setState({
      ...state,
      current: page,
      minIndex: (page - 1) * pageSize,
      maxIndex: page * pageSize,
    });
  };

  return (
    <Layout footer={true}>
      <div className="teachers">
        <div className="teachers__wrapper">
          <div className="teachers__wrapper__header">
            <h2>Course Teachers</h2>
          </div>
          <div className="teachers__wrapper__body container">
            {data?.map(
              (info, index) =>
                index >= state.minIndex &&
                index < state.maxIndex && <TeacherCard info={info} />
            )}
          </div>
          <div className="pagination__wrapper">
            <Pagination
              pageSize={pageSize}
              current={state.current}
              total={data?.length}
              onChange={handleChange}
              style={{ bottom: '0px' }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Teachers;
