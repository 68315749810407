import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout';
import { ResendEmail, verifyEmail } from '../../actions/auth';
import { useHistory } from 'react-router-dom';
import '../Signup/Auth.css';
import LoginImage from '../../images/loginImage.svg';
import { message } from 'antd';

const VerifyEmailC = () => {
  const [status, setStatus] = useState(false);
  const [sendingStatus, setSendingStatus] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('token');
  const email = urlParams.get('email');
  const history = useHistory();
 
  useEffect(() => {
    if (token) {
      setStatus(true);
      verifyEmail(token).then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
          history.push('/login');
        }
      });
    }
  }, [history, token]);

  const resendMail = () => {
    setSendingStatus(true);
    ResendEmail(email)
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
          setSendingStatus(false);
        } else {
          message.error(res?.data?.message);
          setSendingStatus(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setSendingStatus();
      });
  };

  return (
    <Layout footer={false}>
      <section className="login">
        <div className="login__wrapper container">
          <div className="login__wrapper__left">
            <img
              className="login__wrapper__left__loginImage"
              src={LoginImage}
              alt="Login"
            />
          </div>
          <div className="login__wrapper__right">
            <div className="login__wrapper__right__container">
              <h2>Verify your email</h2>

              <div>
                {status ? (
                  'Loading...'
                ) : (
                  <p>
                    We have sent a email in your email address, please verify
                    your email! Didn't get email or expired token?{' '}
                    <span
                      onClick={resendMail}
                      style={{ color: 'blue', cursor: 'pointer' }}
                    >
                      {sendingStatus ? 'Resending...' : 'Resend'}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default VerifyEmailC;
