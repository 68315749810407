import React from 'react';
import {
  HomeOutlined,
  ReadOutlined,
  FilePdfOutlined,
  AppstoreOutlined,
  UserOutlined,
  LoginOutlined,
  InfoCircleOutlined,
  HistoryOutlined 
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './Footer.css';
import { Menu, Dropdown } from 'antd';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { loggedOut, loggedOutFromGoogle } from '../../actions/auth';

const BottomHeader = ({ loggedOut }) => {
  const { isAuthenticated, auth } = useSelector((state) => state.auth);
  const history = useHistory();

  const dropdownMenu = (
    <Menu>
      <Menu.Item>
        <Link to="/become-a-instructor">Become a instructor</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/instructors">Instructors</Link>
      </Menu.Item>
    </Menu>
  );

  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<AppstoreOutlined />}>
        <Link to="/dashboard">
          <span>My Courses</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="2" icon={<UserOutlined />}>
        <Link to="/profile">
          <span>Profile</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="3" icon={<HistoryOutlined />}>
        <Link to="/transactionHistory">
          <span>Transaction History</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        key="4"
        onClick={() => {
          if (auth?.user?.provider === "GOOGLE") {
            console.log("logged out");
            loggedOutFromGoogle();
          } else {
            loggedOut();
          }
          history.push("/");
        }}
        icon={<LoginOutlined />}
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="bottomHeader">
      <div className="bottomHeader__wrapper">
        <ul>
          <li>
            <Link to="/courses">
              <ReadOutlined />
              <span>Course</span>
            </Link>
          </li>
          <li>
            <Link to="/books">
              <FilePdfOutlined />
              <span>Books</span>
            </Link>
          </li>
          <li>
            <Link className="home" to="/">
              <HomeOutlined />
              <span>Home</span>
            </Link>
          </li>
          <li>
            <Link to="/support">
              <InfoCircleOutlined />
              Support
            </Link>
            {/* <Dropdown
              placement="topCenter"
              overlay={dropdownMenu}
              trigger={['click']}
            >
              <Link
                to="#"
                style={{ fontWeight: 'bold', cursor: 'pointer' }}
                onClick={(e) => e.preventDefault()}
              >
                <AppstoreOutlined /> More
              </Link>
            </Dropdown> */}
          </li>
          <li>
            {isAuthenticated ? (
              <Dropdown
                placement="topCenter"
                overlay={menu}
                trigger={['click']}
              >
                <Link to="#">
                  <UserOutlined />
                  Account
                </Link>
              </Dropdown>
            ) : (
              <Link to="/login">
                <UserOutlined />
                Account
              </Link>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

BottomHeader.prototype = {
  loggedOut: PropTypes.func.isRequired,
};

export default connect(null, { loggedOut })(BottomHeader);
