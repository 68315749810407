import React, {useEffect} from "react";
// import Switch from './Switch';
import { Link } from "react-router-dom";
import "./Header.css";
import {
  HomeOutlined,
  ReadOutlined,
  FilePdfOutlined,
  AppstoreOutlined,
  UserOutlined,
  LoginOutlined,
  InfoCircleOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import ResponsiveTopHeader from "./ResponsiveTopHeader";
import { Avatar, Menu, Dropdown } from "antd";
import { loggedOut, loggedOutFromGoogle } from "../../actions/auth";
import { useSelector, connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Logo from "../../images/Color Logo-01.png";
import PropTypes from "prop-types";
import { GoogleLogout } from "react-google-login";
import { useState } from "react";

import { signOut } from 'firebase/auth';
import goAuth from "../../firebase.init";
const Header = ({ loggedOut, loggedOutFromGoogle }) => {
  // const [active, setActive] = useState('bn');
  // const clientId = "588111276925-r1ungh1vbfau303p8urch3icn3brfu98.apps.googleusercontent.com";
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const history = useHistory();
  const location = useLocation();

  const auth = useSelector((state) => state.auth);

  const [student, setStudent] = useState({})
  useEffect(()=>{
    setStudent(auth?.user);
  },[(auth?.user)])


  const menu = (
    <Menu>
      <Menu.Item key="1" icon={<AppstoreOutlined />}>
        <Link to="/dashboard">
          <span>My Courses</span>
        </Link>
      </Menu.Item>

      <Menu.Item key="2" icon={<UserOutlined />}>
        <Link to="/profile">
          <span>Profile</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="3" icon={<HistoryOutlined />}>
        <Link to="/transactionHistory">
          <span>Transaction History</span>
        </Link>
      </Menu.Item>

      <Menu.Item
        key="4"
        onClick={() => {
          if (auth?.user?.provider === "GOOGLE") {
            console.log("logged out");
            loggedOutFromGoogle();
          } else {
            loggedOut();
          }
          history.push("/");
        }}
        icon={<LoginOutlined />}
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  const dropdownMenu = (
    <Menu>
      {/* <Menu.Item>
        <Link to="/become-an-instructor">Become a instructor</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/teachers">Instructors</Link>
      </Menu.Item> */}
      <Menu.Item>
        <Link to="/support">Support</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/faq">FAQ</Link>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <header className="header">
        <div className="header__wrapper container">
          <div className="header__wrapper__first">
            <Link style={{ display: "block" }} to="/">
              <div style={{ cursor: "pointer", marginTop: "8px" }}>
                <img
                  src={Logo}
                  alt="Our Professor"
                  width={250}
                  height={"auto"}
                />
              </div>
            </Link>
          </div>
          <div className="header__wrapper__middle">
            <ul>
              <li>
                <Link to="/">
                  <span
                    className={
                      location?.pathname === "/"
                        ? "ant-btn"
                        : "ant-btn-without-background"
                    }
                  >
                    <HomeOutlined /> Home
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/courses">
                  <span
                    className={`${
                      location?.pathname === "/courses"
                        ? "ant-btn"
                        : "ant-btn-without-background"
                    }`}
                  >
                    <ReadOutlined
                      style={{
                        marginTop:
                          location?.pathname === "/courses" ? "5px" : "2px",
                      }}
                    />
                    Courses
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/books">
                  <span
                    className={
                      location?.pathname === "/books"
                        ? "ant-btn"
                        : "ant-btn-without-background"
                    }
                  >
                    <FilePdfOutlined /> Books
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/support">
                  <span
                    className={`${
                      location?.pathname === "/support"
                        ? "ant-btn"
                        : "ant-btn-without-background"
                    }`}
                  >
                    <InfoCircleOutlined
                      style={{
                        marginTop:
                          location?.pathname === "/support" ? "5px" : "2px",
                      }}
                    />
                    Support
                  </span>
                </Link>
                {/* <Link to="/support">Support</Link> */}

                {/* <Dropdown overlay={dropdownMenu} trigger={['click']}>
                  <span
                    // className="ant-dropdown-link"
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={(e) => e.preventDefault()}
                  >
                    <AppstoreOutlined /> More
                  </span>
                </Dropdown> */}
              </li>
            </ul>
          </div>
          <div className="header__wrapper__last">
            {/* <Switch active={active} setActive={setActive} /> */}
            {isAuthenticated ? (
              <Dropdown overlay={menu} trigger={["click"]}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Avatar
                    src={
                      user?.profile_picture !== undefined
                        ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${user?.profile_picture}`
                        : ""
                    }
                    style={{
                      backgroundColor: "#87d068",
                      marginLeft: "20px",
                      cursor: "pointer",
                    }}
                    icon={<UserOutlined />}
                  />
                  <span style={{ marginLeft: "10px" }}>{user?.fullName}</span>
                </div>
              </Dropdown>
            ) : (
              <Link to="/login">
                <span className="ant-btn">LOGIN / REGISTER</span>
              </Link>
            )}
          </div>
        </div>
      </header>
      <ResponsiveTopHeader />
    </>
  );
};

Header.prototype = {
  loggedOut: PropTypes.func.isRequired,
  loggedOutFromGoogle: PropTypes.func.isRequired
};

export default connect(null, { loggedOut, loggedOutFromGoogle })(Header);
