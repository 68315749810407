import React from 'react';
import '../../pages/BookDetails/BookDetails.css';
import renderHTML from 'react-render-html';

const BookInfo = ({ bookDetails }) => {
  return (
    <div className="bookInfo">
      <div className="bookInfo__wrapper container">
        <div className="bookInfo__wrapper__left">
          <img
            src={
              bookDetails?.book_cover
                ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${bookDetails?.book_cover}`
                : ''
            }
            alt={bookDetails?.book_name}
          />
        </div>
        <div className="bookInfo__wrapper__right">
          <h2>Book Details</h2>
          <p>
            {bookDetails?.book_details
              ? renderHTML(bookDetails?.book_details)
              : ''}
          </p>
        </div>
      </div>
    </div>
  );
};

export default BookInfo;
