import { useState } from 'react';
import Layout from '../../components/Layout';
import Intro from '../../components/LandingPageComponent/Intro';
import ChooseUs from '../../components/LandingPageComponent/ChooseUs';
import Course from '../../components/LandingPageComponent/Course';
import Overview from '../../components/LandingPageComponent/Overview';
import Book from '../../components/SharedComponents/Book';
import Exam from '../../components/LandingPageComponent/Exam';
import Review from '../../components/LandingPageComponent/Review';
import Education from '../../components/SharedComponents/Education';
import FeaturedCourse from '../../components/LandingPageComponent/FeaturedCourse';
import Blog from '../../components/LandingPageComponent/Blog';
import { useEffect } from 'react';
import { getInfo } from '../../actions/information';
import { Helmet } from 'react-helmet';

const LandingPage = () => {
  const [information, setInformation] = useState(null);

  useEffect(() => {
    getInfo()
      .then((r) => r.json())
      .then((res) => setInformation(res?.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Layout footer={true}>
      <Helmet>
        <title>Qlearn | Quality Learning at Your Fingertip</title>
        <meta
          property="og:description"
          content="This is an interactive online learning platform for all professionals and engineers all over the world. Those who want to grow and develop professional skills should be connected with us."
          data-react-helmet="true"
        />
      </Helmet>
      <Intro />
      <ChooseUs />
      <FeaturedCourse />
      <Course />
      <Overview information={information} />
      <Book
        title={`<h2>Educational Interactive <span>Books</span></h2>`}
        subtitle={true}
        seeAll={true}
      />
      {/* <Blog /> */}
      <Exam />
      <Review />
      <Education />
    </Layout>
  );
};
export default LandingPage;
