import React from "react";
import Layout from "../../components/Layout";
import { MobileTwoTone, MailTwoTone } from "@ant-design/icons";
import { Helmet } from "react-helmet";
const RefundPolicy = () => {
  return (
    <Layout footer={true}>
      <Helmet>
        <title>Refund Policy</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      <div className="teachers__wrapper__header">
        <h2>Refund Policy</h2>
      </div>
      <div className="container">
        <div style={{ padding: "20px 20px 80px 20px" }}>
          <h1>Refund Policy for Qlearn</h1>
          <p>
            Qlearn is dedicated to providing high-quality education that meets
            the needs and expectations of our learners. We offer accessible
            "Course Introduction" and "Course Curriculum" videos for every
            course to help you make an information-based decision before
            enrolling. Yet, we ensure a 100% refund policy if you make a refund
            request within 7 days of enrollment.
          </p>
          <p>
            If you feel that the instructor has not met the course curriculum,
            or you have chosen the wrong course by mistake, you have the option
            to request a refund through our support team. We will review and
            respond to your request within 72 hours. If your request is
            approved, you will receive a refund within 7 days. Please note that
            you have to make a refund request through email mentioned in the
            website within 7 days of registration/enrollment.
          </p>
          <p>Refund Request Details:</p>
          <p>
            Email:{" "}
            <MailTwoTone
              style={{
                paddingBottom: "5px",
                marginRight: "10px",
                fontSize: "20px",
              }}
            />
            <a href="mailto:qlearncs@gmail.com">qlearncs@gmail.com</a>
          </p>
          <p>
            <MobileTwoTone
              style={{
                paddingBottom: "5px",
                marginRight: "10px",
                fontSize: "20px",
              }}
            />
            <a href="tel:09638-441144">09638-441144 (Call)</a>
          </p>
          <p>
            <MobileTwoTone
              style={{
                paddingBottom: "5px",
                marginRight: "10px",
                fontSize: "20px",
              }}
            />
            <a href="tel:01885-973770">01885-973770 (Call, Whatsapp)</a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default RefundPolicy;
