import React from 'react';
import '../../pages/LandingPage/LandingPage.css';
import ExamBanner from '../../images/exam-banner.svg';
import { Link } from 'react-router-dom';

const Exam = () => {
  return (
    <div className="exam">
      <div className="exam__wrapper container">
        <div className="exam__wrapper__left">
          <h3>Start Your Exam Preparation Now!</h3>
          <p>Practice, Analyze and Improve</p>
          <button>
            <Link to="/courses">Get Started For Free</Link>
          </button>
        </div>
        <div className="exam__wrapper__right">
          <img src={ExamBanner} alt="Exam Banner" width={300} height={300} />
        </div>
      </div>
    </div>
  );
};
export default Exam;
