import React, { useEffect, useState } from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from 'react-router';
import '../../pages/LandingPage/LandingPage.css';
import BlogCard from '../BlogCard';
import { getBlogList } from '../../actions/blog';

const Blog = () => {
  const history = useHistory();
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBlogList()
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.message?.length > 0) {
            const blogs = res?.data?.message?.filter(
              (bl) => bl.published === true
            );
            setBlogs(blogs);
            setLoading(false);
          } else {
            setBlogs(res?.data?.message);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 750, min: 520 },
      items: 2,
    },
    mobile1: {
      breakpoint: { max: 520, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="course">
      <div
        style={{ padding: '30px 0px' }}
        className="course__wrapper container"
      >
        <h2>
          <span>Blogs</span>
        </h2>

        <div style={{ paddingTop: '30px' }} className="course__wrapper__body">
          {loading ? (
            'Loading...'
          ) : blogs?.length > 0 ? (
            <Carousel
              infinite={true}
              renderArrowsWhenDisabled={true}
              responsive={responsive}
            >
              {blogs?.map((blog, i) => (
                <BlogCard key={blog?._id} blog={blog} />
              ))}
            </Carousel>
          ) : (
            'No course found!'
          )}
        </div>
        <div style={{ marginTop: '20px' }}>
          <button onClick={() => history.push(`/blogs`)} className="see-all">
            See All <RightCircleOutlined style={{ fontSize: '17px' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Blog;
