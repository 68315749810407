import React from 'react';
import './BlogCard.css';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

function BlogCard({ blog }) {
  const history = useHistory();
  const handleClick = (slug) => {
    history.push(`/blogs/${slug}`);
  };

  return (
    <div
      onClick={() => handleClick(blog?.slug)}
      title={blog?.title}
      className="blog__card"
    >
      <div className="blog__card__img__wrapper">
        <img
          src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${blog?.upload_featured_image}`}
          alt={blog?.title}
        />
      </div>
      <div className="blog__card__info__wrapper">
        <h4>{blog?.title}</h4>
        <p style={{ fontSize: '15px', fontStyle: 'italic' }}>
          {blog?.author_name}
        </p>
        <p style={{ fontSize: '15px', textAlign: 'right' }}>
          {moment(blog?.createdDate).fromNow()}
        </p>
      </div>
    </div>
  );
}

export default BlogCard;
