import React, { useState } from 'react';
import { Modal } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';
import '../../pages/CourseDetails/CourseDetails.css';

const Banner = ({ courseDetails, previewLessons, bundle }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [url, setUrl] = useState('');

  React.useEffect(() => {
    if (previewLessons?.length > 0) {
      setUrl(previewLessons[0]?.url);
    }
  }, [previewLessons]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  var stopAllYouTubeVideos = () => {
    var videos = document.querySelectorAll('iframe, video');
    Array.prototype.forEach.call(videos, function (video) {
      if (video.tagName.toLowerCase() === 'video') {
        video.pause();
      } else {
        var src = video.src;
        video.src = src;
      }
    });
  };

  const handleOk = () => {
    setIsModalVisible(false);
    stopAllYouTubeVideos();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    stopAllYouTubeVideos();
  };

  return (
    <div className="banner">
      <div className="banner__wrapper container">
        <div className="banner__wrapper__left">
          <h3>{courseDetails?.title}</h3>
          <h3>{courseDetails?.name}</h3>
          <div>{courseDetails?.course_short_description}</div>
          <div>{courseDetails?.short_description}</div>
          {/* <div>
            <button className="wishlist">
              Wishlist <HeartOutlined />
            </button>
            <button>
              Share <ShareAltOutlined />
            </button>
          </div> */}
        </div>
        <div className="banner__wrapper__right">
          <div
            style={{
              width: '100%',
              height: 'auto',
              overflow: 'hidden',
              borderRadius: '10px',
            }}
          >
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${
                courseDetails?.upload_featured_image !== undefined
                  ? courseDetails?.upload_featured_image
                  : courseDetails?.image
              }`}
              alt=""
            />
          </div>
          {previewLessons?.length > 0 && (
            <p
              style={{
                cursor: 'pointer',
                width: 'fit-content',
                margin: '0 auto',
                border: '1px solid white',
                padding: '10px 24px',
                marginTop: '16px',
                borderRadius: '5px',
              }}
              onClick={showModal}
            >
              Preview this course
            </p>
          )}
        </div>
      </div>

      <Modal
        title={courseDetails?.title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div>
          {url && url !== '' && (
            <iframe
              title="Embeded Video"
              allowfullscreen=""
              frameborder="0"
              style={{
                width: '100%',
                height: '250px',
                border: '1px solid lightgray',
              }}
              src={url ? url : ''}
              width="420"
              id="iframe"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowscriptaccess="always"
            ></iframe>
          )}
        </div>
        <div>
          {previewLessons?.map((pl) => (
            <div
              onClick={() => setUrl(pl?.url)}
              style={{
                padding: '20px 0px',
                borderBottom: '1px solid lightgray',
                color: ' #005e72',
                fontSize: '18px',
                cursor: 'pointer',
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <PlayCircleOutlined
                style={{ fontSize: '30px', marginRight: '10px' }}
              />{' '}
              {pl?.title}
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default Banner;
