import React from "react";
import Layout from "../../components/Layout";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import "./TransactionHistory.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const TransactionHistory = () => {
    const [date, setDate] = useState(new Date());
  const [userHistory, setUserHistory] = useState([]);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DEV_URL}/api/enrolment/transaction-history/${user?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res?.json())
      .then((data) => {
        if (data?.success) {
          console.log(data);
          setUserHistory(data?.message?.enrolled_courses);
        } else {
          console.log("failed to fetch");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);


  const History = useHistory();
  return (
    <Layout footer={true}>
      <div>
        <h2
          style={{
            color: "#093054",
            opacity: "0.8",
            fontWeight: "700",
          }}
          className="text-center mt-3 mb-3"
        >
          {" "}
          My Transaction History
        </h2>
        <div className="table-responsive mb-3">
          {userHistory?.length > 0 ? (
            <table className="table table-bordered table-hover w-75 mx-auto">
              <thead className="thead  tablehead">
                <tr>
                  <th scope="col" className="text-center">
                    Serial
                  </th>
                  <th scope="col" className="text-start">
                    Course Name
                  </th>
                  <th scope="col" className="text-center">
                    Transaction Date
                  </th>
                  <th scope="col" className="text-center">
                    Valid Days
                  </th>

                  <th scope="col" className="text-center">
                    Sale Price
                  </th>
                  <th scope="col" className="text-center">
                  Invoice
                  </th>
                </tr>
              </thead>
              <tbody className="tablebody">
                {userHistory?.map((history, index) => {
                  return (
                    <tr key={index}>
                      <td data-label="No" className="text-center ">
                        {index + 1}
                      </td>
                      <td data-label="Course Name" className="text-start">
                        {history?._id?.title}
                      </td>
                      <td data-label="Transaction Date" className="text-center">
                        {history?.buyingDate
                          ? history?.buyingDate?.slice(0, 10)
                          : "No data"}
                      </td>
                      <td data-label="Valid Days" className="text-center">
                        {history?._id?.validDays
                          ? history?._id?.validDays
                          : "-"}
                      </td>

                      <td data-label="Sale Price" className="text-center">
                        {history?._id?.sale_price}
                      </td>

                      <td data-label="Sale Price" className="text-center">
                        {history?.orderId ? <button style={{
                          background: '#50c78a',
                          padding: '4px 4px',
                          fontWeight: '200',
                          borderRadius: '5px',
                          color: "black",
                          fontSize: '13px'
                        }} className="btn" onClick={()=>{
                          History.push(`/invoice/${history?.orderId?._id}`)
                        }}>Invoice</button> : "-" }
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className="mt-5 mb-5 d-flex justify-content-center align-items-center">
              <h2 className="text-danger font-bold">No History Now</h2>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default TransactionHistory;
