/**
 * * Image Upload
 */

export const imageUpload = async (e) => {
  const formData = new FormData();
  formData.append('image', e.target.files[0]);

  return await fetch(`${process.env.REACT_APP_DEV_URL}/api/image/upload`, {
    method: 'POST',
    body: formData,
  }).then((res) => {
    return res;
  });
};
/**
 * * Certificate Upload
 */

export const certificateUpload = async (e) => {
  const formData = new FormData();
  formData.append('image', e.target.files[0]);

  return await fetch(
    `${process.env.REACT_APP_DEV_URL}/api/certificate/upload`,
    {
      method: 'POST',
      body: formData,
    }
  ).then((res) => {
    return res;
  });
};



export const publicImageUpload = async (e) => {
  const formData = new FormData();
  formData.append('image', e.target.files[0]);

  return await fetch(
    `${process.env.REACT_APP_DEV_URL}/api/image/upload/public`,
    {
      method: "POST",
      body: formData,
    }
  ).then((res) => {
    return res;
  });
};