import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Layout from "../../components/Layout";
import logo from "../../images/logo.png";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";


const Invoice = () => {
  const { id } = useParams();

  const [userHistory, setUserHistory] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DEV_URL}/api/enrolment/transaction-history/${user?._id}`,
      {
        method: "GET",
      }
    )
      .then((res) => res?.json())
      .then((data) => {
        if (data?.success) {
          console.log(data);
          setUserHistory(data?.message?.enrolled_courses);

          data?.message?.enrolled_courses?.map((c) => {
            if (c?.orderId?._id === id) {
              setInvoiceData(c);
            }
          });
        } else {
          console.log("failed to fetch");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user]);

  console.log(invoiceData);

  const pdfContainerRef = useRef(null);



  const generatePDF = () => {
    const input = pdfContainerRef.current;

    const originalDisplayStyle = input.style.display;
    input.style.display = 'block'; // Show the hidden div temporarily

    html2canvas(input).then((canvas) => {
      input.style.display = originalDisplayStyle; // Restore the original display style

      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();



      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      const topMargin = 20;

      pdf.addImage(imgData, 'PNG', 0, topMargin, pdfWidth, pdfHeight);
      pdf.save(`Invoice-${invoiceData?.orderId?._id}.pdf`);
    });
  };
  return (
    <Layout footer={true}>
      <div style={{ maxWidth: "1240px", margin: "auto" }}>
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div>
              <h2
                style={{
                  color: "#093054",
                  opacity: "0.8",
                  fontWeight: "700",
                }}
                className="text-center mt-3 mb-3"
              >
                {" "}
                Invoice
              </h2>
              <h5
                style={{
                  color: "blue",
                  opacity: "0.8",
                  fontWeight: "700",
                }}
                className="text-center mt-3 mb-3"
              >
                Invoice No: {invoiceData?.orderId?._id}
              </h5>
              <h5
                style={{
                  color: "#093054",
                  opacity: "0.8",
                  fontWeight: "700",
                }}
                className="text-center mt-3 mb-3"
              >
                {" "}
                Invoice Date: {invoiceData?.buyingDate?.slice(0, 10)}
              </h5>
              <h5
                style={{
                  color: "#093054",
                  opacity: "0.8",
                  fontWeight: "700",
                }}
                className="text-center mt-3 mb-3"
              >
                {" "}
                Time: {invoiceData?.buyingDate?.slice(11, 16)}
              </h5>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="d-flex justify-content-center col-md-6 col-sm-12">
              <div>
                <h4>Provided By:</h4>
                <h5>Qlearn</h5>
                <h5>09638-441144</h5>
                <h5>qlearncs@gmail.com</h5>
              </div>
            </div>
            <div className=" d-flex justify-content-center col-md-6 col-sm-12">
              <div>
                <h4>Provided To:</h4>
                <h5>{invoiceData?.orderId?.customerInfo?.cusName}</h5>
                <h5>{invoiceData?.orderId?.customerInfo?.cusPhone}</h5>
                <h5>{invoiceData?.orderId?.customerInfo?.cusEmail}</h5>
              </div>
            </div>
          </div>

          <div>
            <table className="table table-bordered table-hover w-75 mx-auto mt-5 mb-5">
              <thead className="thead  tablehead">
                <tr>
                  <th scope="col" className="text-center">
                    Course Name
                  </th>
                  <th scope="col" className="text-center">
                    List Price
                  </th>
                  <th scope="col" className="text-center">
                    Sale Price
                  </th>
                  <th scope="col" className="text-center">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="tablebody">
                <tr>
                  <td data-label="Course Name" className="text-center ">
                    {invoiceData?._id?.title}
                  </td>
                  <td data-label="List Price" className="text-center">
                    ট {invoiceData?._id?.sale_price}
                  </td>
                  <td data-label="Sale Price" className="text-center">
                    ট {invoiceData?.orderId?.totalAmount}
                  </td>
                  <td data-label="Total" className="text-center">
                    {" "}
                    ট {invoiceData?.orderId?.totalAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div style={{ display: "none", minWidth: '1240px' }} ref={pdfContainerRef}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <div>
              <h2
                style={{
                  color: "#093054",
                  opacity: "0.8",
                  fontWeight: "700",
                }}
                className="text-center mt-3 mb-3"
              >
                {" "}
                Invoice
              </h2>
              <h5
                style={{
                  color: "blue",
                  opacity: "0.8",
                  fontWeight: "700",
                }}
                className="text-center mt-3 mb-3"
              >
                Invoice No: {invoiceData?.orderId?._id}
              </h5>
              <h5
                style={{
                  color: "#093054",
                  opacity: "0.8",
                  fontWeight: "700",
                }}
                className="text-center mt-3 mb-3"
              >
                {" "}
                Invoice Date: {invoiceData?.buyingDate?.slice(0, 10)}
              </h5>
              <h5
                style={{
                  color: "#093054",
                  opacity: "0.8",
                  fontWeight: "700",
                }}
                className="text-center mt-3 mb-3"
              >
                {" "}
                Time: {invoiceData?.buyingDate?.slice(11, 16)}
              </h5>
            </div>
          </div>
          <div className="row justify-content-around">
            <div className="d-flex justify-content-center col-6">
              <div>
                <h4>Provided By:</h4>
                <h5>Qlearn</h5>
                <h5>09638-441144</h5>
                <h5>qlearncs@gmail.com</h5>
              </div>
            </div>
            <div className=" d-flex justify-content-center col-6">
              <div>
                <h4>Provided To:</h4>
                <h5>{invoiceData?.orderId?.customerInfo?.cusName}</h5>
                <h5>{invoiceData?.orderId?.customerInfo?.cusPhone}</h5>
                <h5>{invoiceData?.orderId?.customerInfo?.cusEmail}</h5>
              </div>
            </div>
          </div>

          <div>
            <table style={{
              fontSize: "30px"
            }} className="table table-bordered table-hover w-75 mx-auto mt-5 mb-5 display-4">
              <thead className="thead  tablehead">
                <tr>
                  <th scope="col" className="text-center">
                    Course Name
                  </th>
                  <th scope="col" className="text-center">
                    List Price
                  </th>
                  <th scope="col" className="text-center">
                    Sale Price
                  </th>
                  <th scope="col" className="text-center">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody className="tablebody">
                <tr>
                  <td data-label="Course Name" className="text-center ">
                    {invoiceData?._id?.title}
                  </td>
                  <td data-label="List Price" className="text-center">
                    ট {invoiceData?._id?.sale_price}
                  </td>
                  <td data-label="Sale Price" className="text-center">
                    ট {invoiceData?.orderId?.totalAmount}
                  </td>
                  <td data-label="Total" className="text-center">
                    {" "}
                    ট {invoiceData?.orderId?.totalAmount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-center mb-5"><button style={{
          background: '#50c78a',
          padding: '10px 10px',
          fontWeight: '600',
          borderRadius: '5px',
          color: "black"
        }} className="btn" onClick={generatePDF}>Download Invoice</button></div>
      </div>
    </Layout>
  );
};

export default Invoice;
