import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  markAsInCompleted,
  markAsCompleted,
  updateLastLesson,
} from '../../actions/course';
import { message } from 'antd';
import './Watching.css';
import { CheckCircleFilled } from '@ant-design/icons';

function Lesson({
  index,
  les,
  setPath,
  lesId,
  setLesId,
  setUrl,
  setVideoTitle,
  courseId,
  setDescription,
  markedLessons,
  setCompletionsStatus,
  status,
  setStatus,
  setCurrentNumber,
}) {
  const [completed, setCompleted] = useState(false);

  const { user } = useSelector((state) => state.auth);

  console.log("lesson Id", lesId)

  useEffect(() => {
    const findLes =
      markedLessons?.length > 0
        ? markedLessons?.find((l) => l === les?._id)
        : undefined;

    if (findLes && findLes !== undefined) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [les, markedLessons]);

  const handleMark = () => {
    const values = {
      user_id: user?._id,
      lesson_id: les?._id,
      course_id: courseId,
    };

    markAsCompleted(values)
      .then((res) => {
        if (res?.data?.success) {
          // message.success(res?.data?.message);
          setStatus(true);
          setCompletionsStatus(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleUnMark = () => {
    const values = {
      user_id: user?._id,
      lesson_id: les?._id,
      course_id: courseId,
    };

    markAsInCompleted(values)
      .then((res) => {
        if (res?.data?.success) {
          // message.success(res?.data?.message);
          setCompleted(false);
          setCompletionsStatus(true);
        }
      })
      .catch((err) => console.log(err));
  };

  const [downloadUrl, setDownloadUrl] = React.useState(null);

  React.useEffect(() => {
    if (les?.attatchment) {
      const downloadF = async () => {
        await fetch(
          `${process.env.REACT_APP_DEV_URL}/api/largeFile/download/generatePresignedURL/${les?.attatchment}`,
          {
            method: 'GET',
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setDownloadUrl(data?.uploadURL);
          })
          .catch((err) => console.log(err));
      };

      downloadF();
    }
  }, [les]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <div style={{ marginRight: '8px', marginTop: '20px' }}>
          {completed ? (
            <CheckCircleFilled
              onClick={handleUnMark}
              style={{
                fontSize: '25px',
                color: 'green',
                cursor: 'cursor-pointer',
              }}
            />
          ) : (
            <span
              onClick={handleMark}
              style={{
                width: '25px',
                height: '25px',
                border: '2px solid black',
                display: 'block',
                borderRadius: '50%',
                cursor: 'cursor-pointer',
              }}
            ></span>
          )}
        </div>
        <div
          style={{
            flex: 1,
            background: lesId === les?._id ? '#2b8ba0' : 'white',
          }}
          id={`_${les?._id}`}
          onClick={() => {
            if (les?.url === '' || les?.url === undefined) {
              setLesId(les?._id);
              setUrl(les?.url);
              setPath(`_${les?._id}`);
              setVideoTitle(les?.title);
              setDescription(les?.description);

              handleMark();
              window.scrollTo(0, 0);
              updateLastLesson(courseId, user?._id, les?._id)
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
            } else {
              setLesId(les?._id);
              setUrl(les?.url);
              setPath(`_${les?._id}`);
              setVideoTitle(les?.title);

              setDescription(les?.description);
              window.scrollTo(0, 0);
              updateLastLesson(courseId, user?._id, les?._id)
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
            }
          }}
          className="video__name"
        >
          <h4 style={{ color: lesId === les?._id ? 'white' : 'black' }}>
            {les?.title}
          </h4>

          {les?.attatchment &&
            les?.attatchment !== '' &&
            les?.attatchment !== undefined && (
              <div
                style={{
                  marginTop: '10px',
                  textAlign: 'right',
                  fontSize: '13px',
                  marginBottom: '2px',
                }}
              >
                Resource
              </div>
            )}
          {les?.attatchment &&
            les?.attatchment !== '' &&
            les?.attatchment !== undefined && (
              <div style={{ textAlign: 'right' }}>
                <a
                  className="resource_name"
                  style={{
                    background: '#50c78a',
                    padding: '4px 8px',
                    fontWeight: '600',
                    borderRadius: '5px',
                  }}
                  href={downloadUrl}
                >
                  {les?.attatchment_name !== '' &&
                  les?.attatchment_name !== undefined
                    ? les?.attatchment_name
                    : 'Download'}
                </a>
              </div>
            )}
        </div>
      </div>
    </div>
  );
}

export default Lesson;
