import { useState, useEffect } from "react";
import renderHTML from "react-render-html";
import { getInfo } from "../../actions/information";
import Layout from "../../components/Layout";
import support from "../../images/support.png";
import "./Support.css";
import { MobileTwoTone, MailTwoTone } from "@ant-design/icons";
import ReactPlayer from "react-player";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Support = () => {
  const [information, setInformation] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.auth);

  const history = useHistory();
  useEffect(() => {
    getInfo()
      .then((r) => r.json())
      .then((res) => setInformation(res?.data))
      .catch((err) => console.log(err));
  }, []);

  const handleRedirect = () => {
    sessionStorage.setItem(
      "redirectURL",
      `${window.location.pathname}${window.location.search}`
    );
    history.push(`/login`);
  };

  const handleClickSupportTicket = () => {
    history.push("/supportTicket");
  };

  return (
    <Layout footer={true}>
      <Helmet>
        <title>Support</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      {/* <div className="teachers__wrapper__header">
        <h2>Contact Support</h2>
      </div> */}
      <div
        style={{
          paddingTop: "10px",
          marginBottom: "0px",
        }}
        className="container"
      >
        {/* <Link to="/supportTicket">
          <h2
            style={{
              color: "rgb(123, 36, 255)",
              opacity: 0.8
            }}
          >
            Support Ticket
          </h2>
        </Link> */}

        <button
          onClick={isAuthenticated ? handleClickSupportTicket : handleRedirect}
          style={{
            background: "rgb(123, 36, 255)",
            color: "white",
            padding: "6px 16px",
            fontWeight: 600,
            border: "none",
            borderRadius: "4px",
            opacity: "0.5",
          }}
          className="supportTicketButton"
        >
          <h2
            style={{
              color: "white",
              opacity: 0.8,
            }}
          >
            Support Ticket
          </h2>
        </button>
        <div className="support__wrapper">
          <div className="support_card">
            <div className="support_card_1">
              <h3>Contact Through Email</h3>
              <p>You can contact directly with customer care staffs.</p>
              <div>
                <MailTwoTone
                  style={{
                    paddingBottom: "5px",
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                />
                <a href="mailto:qlearncs@gmail.com">qlearncs@gmail.com</a>
              </div>
              {information && renderHTML(information?.information?.email)}

              {/* <p style={{ color: 'red', marginBottom: '0px' }}>
                <a href="mailto:ourprofessorshelp@gmail.com">
                  ourprofessorshelp@gmail.com
                </a>
              </p>
              <p style={{ color: 'red' }}>
                <a href="mailto:admin@geostruc.com">admin@geostruc.com</a>
              </p> */}
            </div>
            <div className="support_card_2">
              <h3>Contact Through Phone</h3>
              <p>You can contact directly with customer care staffs.</p>
              <div style={{ lineHeight: "20px" }}></div>
              <div>
                <MobileTwoTone
                  style={{
                    paddingBottom: "5px",
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                />
                <a href="tel:09638-441144">09638-441144</a>
              </div>

              <div>
                {information && renderHTML(information?.information?.phone)}
              </div>

              {/* <span style={{ color: '#ab1d31' }}>
                <a href="tel:01324433239">013 244 33 239</a>
              </span>
              <br />
              <span style={{ color: '#ab1d31' }}>
                <a href="tel:0188597370">018 85 97 37 70</a>
              </span>
              <br />
              <span style={{ color: '#ab1d31' }}>
                <a href="tel:01845-828253">01845-828253</a> (bKash - Personal)
              </span> */}
            </div>
          </div>
          <div
            className="support__image__wrapper"
            style={{ width: "500px", height: "400px" }}
          >
            <img
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={support}
              alt="Support"
              title="Support"
            />
          </div>
        </div>
      </div>
      <div>
        <div className=" student_manual_div row">
          <div className="student_manual_individual_video col-lg-6 col-md-6 col-12">
            {" "}
            <h5 className="text-center mb-2 fw-bolder">
              নতুন একাউন্ট যেভাবে তৈরি করবেন
            </h5>
            {/* <div className="player-wrap"> */}
            <ReactPlayer
              width="100%"
              height="100%"
              className="react_player"
              controls
              url="https://youtu.be/4sRK7dwATqM"
            />
            {/* </div> */}
          </div>

          <div className="student_manual_individual_video col-lg-6 col-md-6 col-12">
            {" "}
            <h5 className="text-center mb-2 fw-bolder">
              ফ্রি কোর্স যেভাবে ইনরোল করবেন
            </h5>
            <ReactPlayer
              width="100%"
              height="100%"
              className="react_player"
              controls
              url="https://youtu.be/HFFzIjWv3vY"
            />
          </div>

          <div className="student_manual_individual_video col-lg-6 col-md-6 col-12">
            {" "}
            <h5 className="text-center mb-2 fw-bolder">
              যেভাবে একটি Paid কোর্স কিনবেন
            </h5>
            <ReactPlayer
              width="100%"
              height="100%"
              className="react_player"
              controls
              url="https://youtu.be/Tt6B2pvY7cs"
            />
          </div>

          <div className="student_manual_individual_video col-lg-6 col-md-6 col-12">
            {" "}
            <h5 className="text-center mb-2 fw-bolder">
              পাসওয়ার্ড ভুলে গেলে কীভাবে Reset করবেন
            </h5>
            <ReactPlayer
              width="100%"
              height="100%"
              className="react_player"
              controls
              url="https://youtu.be/1f0yR1X7Hw8"
            />
          </div>

          <div className="student_manual_individual_video col-lg-6 col-md-6 col-12">
            {" "}
            <h5 className="text-center mb-2 fw-bolder">
              Certificate কীভাবে নিবেন এবং Quiz দেওয়ার নিয়ম
            </h5>
            <ReactPlayer
              width="100%"
              height="100%"
              className="react_player"
              controls
              url="https://youtu.be/68YA8hxNzZ4"
            />
          </div>

          <div className="student_manual_individual_video col-lg-6 col-md-6 col-12">
            {" "}
            <h5 className="text-center mb-2 fw-bolder">
              Certificate নেওয়ার পরে কীভাবে Verification করবেন
            </h5>
            <ReactPlayer
              width="100%"
              height="100%"
              className="react_player"
              controls
              url="https://youtu.be/OjgmC-tfkQo"
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
