import React, { useRef } from "react";
import Layout from "../../components/Layout";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { message } from "antd";
import { Helmet } from "react-helmet";
import { UploadOutlined } from "@ant-design/icons";
import { publicImageUpload } from "../../actions/fileUpload";

const SupportTicket = () => {
  const { supportTicketId } = useParams();

  const [supportDetails, setSupportDetails] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [certificate, setCertificate] = useState(null);
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(null);
  const [supportImage, setSupportImage] = useState(null);
  const [change, setChange] = useState(true);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DEV_URL}/api/support/details/${supportTicketId}`,
      {
        method: "GET",
      }
    )
      .then((res) => res?.json())
      .then((data) => {
        if (data?.success) {
          setSupportDetails(data?.data);
          setDataLoaded(true);
        } else {
          //   message.error(data?.message, 3, "Support Ticket");
        }
      })
      .catch((err) => {
        // message.error(err?.response?.data?.message, "Review");
      });
  }, [user, change]);

  const handleReply = () => {
    const data = {
      reply: [
        {
          message: userMessage,
          givenBy: "USER",
          image: certificate,
        },
      ],
    };

    fetch(
      `${process.env.REACT_APP_DEV_URL}/api/support/reply/${supportTicketId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    )
      .then((res) => res?.json())
      .then((data) => {
        if (data?.success) {
          message.success(data?.message, 3, "Support Ticket");

          setChange(!change);
          setCertificate(undefined);
          setUserMessage(null);
        } else {
          message.error(data?.message, 3, "Support Ticket");

          setChange(!change);
          setCertificate(undefined);
          setUserMessage(null);
        }
      })
      .catch((err) => {
        message.error(err?.response?.data?.message, "Support Ticket");
      });
  };

  const handleCertificateUpload = (e) => {
    setError(null);
    const extension = e.target.files[0]?.name?.split(".").pop();

    if (
      extension === "png" ||
      extension === "PNG" ||
      extension === "jpg" ||
      extension === "JPG" ||
      extension === "jpeg" ||
      extension === "JPEG" ||
      extension === "pdf" ||
      extension === "PDF"
    ) {
      setStatus(true);
      publicImageUpload(e)
        .then((res) => res.json())
        .then((data) => {
          setSupportImage(data?.Location);
          if (data?.success === undefined) {
            setCertificate(data?.Location);
            setStatus(false);
          } else {
            if (!data?.success) {
              setStatus(false);
              message.error(data?.message);
            } else {
              // setCertificate(data?.key);
              setCertificate(data?.Location);
              setStatus(false);
            }
          }
        })
        .catch((err) => {
          if (err?.response?.data?.message === undefined) {
            message.error(err?.response?.data);
          } else {
            message.error(err?.response?.data?.message);
          }
        });
    } else {
      setError("Unknown type! try to select png, jpg or pdf type!");
    }
  };

  const fileExtension =
    certificate !== null &&
    certificate?.substr(certificate?.lastIndexOf(".") + 1);
  const certificateViewer = () => {
    if (fileExtension !== undefined) {
      if (
        fileExtension === "png" ||
        fileExtension === "PNG" ||
        fileExtension === "jpg" ||
        fileExtension === "JPG" ||
        fileExtension === "jpeg" ||
        fileExtension === "JPEG"
      ) {
        return (
          <img
            // width={200}
            style={{
              width: "100px",
              height: "90px",
            }}
            src={certificate}
            // src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
            alt="SupportImage"
          />
        );
      } else {
        return (
          <>
            <a
              style={{
                background: "#38aba1",
                display: "inline-block",
                color: "white",
                padding: "10px 20px",
                borderRadius: "6px",
              }}
              href={`${process.env.REACT_APP_DEV_URL}/api/image/download/${certificate}`}
              download
            >
              Download Your Support Image
            </a>
          </>
        );
      }
    }
  };

  return (
    <Layout footer={true}>
      <Helmet>
        <title>Support Ticket</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      <div className="supportTicketReplyDiv mt-1 mb-5">
        <div className="supportDetailsDiv">
          <div
            style={{
              borderBottom: "1px solid black",
            }}
            className="w-100"
          >
            <h4 className="text-center">Support Ticket Details</h4>
            <h4 className="text-center">Subject: {supportDetails?.subject}</h4>
          </div>
          <h5 className="text-start">Conversation :</h5>

          <div
            id="myDiv"
            style={{ height: "54vh", overflowY: "scroll" }}
            className="chattingDiv"
          >
            <div className="chattingText">
              <div className="chatMessageRight">
                <div className="singleMessageRight">
                  <h5
                    style={{
                      color: "white",
                    }}
                    className=""
                  >
                    {supportDetails?.message}
                  </h5>
                </div>
              </div>
              <p
                style={{
                  fontSize: "12px",
                }}
                className="text-end"
              >
                Send By User
              </p>
              {supportDetails?.image ? (
                <>
                  <div className="chatMessageRight">
                    <div className="chatImage">
                      <a href={supportDetails?.image} target="new">
                        <img
                          className="img-fluid"
                          src={supportDetails?.image}
                          alt="supportImage"
                          onClick={() => {
                            // window.open({supportDetails?.image})
                          }}
                        />
                      </a>
                    </div>
                  </div>
                  <p
                    style={{
                      fontSize: "12px",
                    }}
                    className="text-end"
                  >
                    Send By User
                  </p>
                </>
              ) : null}

              {supportDetails?.reply?.map((r, i) => {
                return (
                  <div key={i}>
                    {" "}
                    {r?.givenBy === "USER" ? (
                      <>
                        {r?.image ? (
                          <>
                            <div className="chatMessageRight">
                              <div className="chatImage">
                                <a href={r?.image} target="new">
                                  <img
                                    className="img-fluid"
                                    src={r?.image}
                                    alt="supportImage"
                                    onClick={() => {
                                      // window.open({supportDetails?.image})
                                    }}
                                  />
                                </a>
                              </div>
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                              className="text-end"
                            >
                              Send By User
                            </p>
                          </>
                        ) : null}
                        {r?.message ? (
                          <>
                            <div className="chatMessageRight">
                              <div className="singleMessageRight">
                                <h5
                                  style={{
                                    color: "white",
                                  }}
                                  className=""
                                >
                                  {r?.message}
                                </h5>
                              </div>
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                              className="text-end"
                            >
                              Send By User
                            </p>{" "}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <>
                        {r?.image ? (
                          <>
                            <div className="chatMessageLeft">
                              <div className="chatImage">
                                <a href={r?.image} target="new">
                                  <img
                                    className="img-fluid"
                                    src={r?.image}
                                    alt="supportImage"
                                    onClick={() => {
                                      // window.open({supportDetails?.image})
                                    }}
                                  />
                                </a>
                              </div>
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                              className="text-start"
                            >
                              Send By Admin
                            </p>
                          </>
                        ) : null}
                        {r?.message ? (
                          <>
                            <div className="chatMessageLeft">
                              <div className="singleMessageLeft">
                                <h5
                                  style={{
                                    color: "white",
                                  }}
                                  className=""
                                >
                                  {r?.message}{" "}
                                </h5>
                              </div>
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                              }}
                              className="text-start pb-1"
                            >
                              Send By Admin
                            </p>{" "}
                          </>
                        ) : null}
                      </>
                    )}
                  </div>
                );
              })}

              {/* <div className="chatMessageLeft">
                <div className="singleMessageLeft">
                  <h5
                    style={{
                      color: "white",
                    }}
                    className=""
                  >
                    "Aasfdashdfhasdfbas dfasvdfhasbdfhasdh fbashdbfhjawbf wheruh
                    uwerh werh sbdfha"
                  </h5>
                </div>
              </div>
              <p
                style={{
                  fontSize: "12px",
                }}
                className="text-start pb-1"
              >
                Send By Admin
              </p> */}
            </div>
          </div>
          {supportDetails?.solved ? null : (
            <div className="replySendDiv">
              <div>
                <textarea
                  name="Message"
                  onChange={(e) => {
                    setUserMessage(e.target.value);
                  }}
                  rows="3"
                  cols="70"
                  className="input2"
                  type="textarea"
                  placeholder="Your Valuable Question"
                ></textarea>
              </div>

              <div>
                <label htmlFor="certificate">
                  <p className="replyImageUp">
                    <span className="ms-2">
                      <UploadOutlined />
                    </span>
                  </p>
                </label>

                <input
                  onChange={(e) => handleCertificateUpload(e)}
                  style={{ display: "none" }}
                  id="certificate"
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf, .zip"
                />

                {certificate !== undefined ? (
                  certificate !== null ? (
                    <div
                      className="replyImageUpShow"
                      style={{ marginTop: "5px" }}
                    >
                      {certificate !== undefined
                        ? certificate !== null
                          ? certificateViewer()
                          : null
                        : null}
                    </div>
                  ) : null
                ) : null}

                <button onClick={handleReply} className="replySend">
                  Send
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SupportTicket;
