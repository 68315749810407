import React from 'react';
import { CloudDownloadOutlined } from '@ant-design/icons';
import '../../pages/BookDetails/BookDetails.css';

const Download = ({ bookDetails }) => {
  const [downloadUrl, setDownloadUrl] = React.useState(null);

  React.useEffect(() => {
    if (bookDetails) {
      const downloadF = async () => {
        await fetch(
          `${process.env.REACT_APP_DEV_URL}/api/largeFile/download/generatePresignedURL/${bookDetails?.book_file}`,
          {
            method: 'GET',
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setDownloadUrl(data?.uploadURL);
          })
          .catch((err) => console.log(err));
      };

      downloadF();
    }
  }, [bookDetails]);

  return (
    <div className="download">
      <div className="download__wrapper container">
        <div className="download__wrapper__left">
          <h3>Download "{bookDetails?.book_name}" PDF</h3>
          <p>{bookDetails?.book_short_details}</p>
        </div>
        <div className="download__wrapper__right">
          {bookDetails?.book_paid === false && (
            <p>
              <span>{bookDetails?.regular_price} টাকা</span>
              <span>Free</span>
            </p>
          )}
          {bookDetails?.book_paid === true && (
            <p>
              {bookDetails?.regular_price !== bookDetails?.sale_price &&
              bookDetails?.regular_price > bookDetails?.sale_price &&
              bookDetails?.regular_price > 0 ? (
                <span>{bookDetails?.regular_price} টাকা </span>
              ) : (
                <span></span>
              )}
              <span>{bookDetails?.sale_price} টাকা </span>
            </p>
          )}

          <a
            href={downloadUrl}
            // onClick={() => handleDownload(bookDetails?.book_file)}
            download
            style={{ borderRadius: '4px' }}
            className="ant-btn"
            target="_blank"
            rel="noreferrer"
          >
            Download <CloudDownloadOutlined style={{ marginLeft: '10px' }} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Download;
