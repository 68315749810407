import React, { useState, useEffect } from "react";
import Layout from "../../components/Layout";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./Quiz.css";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import useCertificateHandler from "../Watching/useCertificateHandler";
import { getSpecificCourseById } from "../../actions/course";
const PreserveQuiz = () => {
  const [preservedQuizData, setPreservedQuizData] = useState(null);
  const [coursePaid, setCoursePaid] = useState(true);
  const { courseId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { savedCertificate, handleDownloadCertificate, handleGetCertificate } =
    useCertificateHandler(courseId, user);
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DEV_URL}/api/course/quiz/preserved/${courseId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          userId: user?._id,
        }),
      }
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("preservedQuizData", data);
        setPreservedQuizData(data?.data?.preservedQuiz);
      });
  }, [user]);

  useEffect(() => {
    if (courseId) {
      getSpecificCourseById(courseId)
        .then((res) => {
          if (res?.data?.success) {
            setCoursePaid(res.data.message.course_paid);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [courseId]);

  return (
    <Layout footer={true}>
      <h3 className="text-center mt-3">Preserved Quiz</h3>
      <div className="d-flex justify-content-center mb-2">
        {coursePaid ? (
          savedCertificate ? (
            <button
              onClick={handleDownloadCertificate}
              style={{
                background: "#1c659ee0",
                color: "white",
                border: "none",
                padding: "5px 25px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "7px",
              }}
            >
              Download certificate
            </button>
          ) : (
            <button
              onClick={handleGetCertificate}
              style={{
                background: "#1c659ee0",
                color: "white",
                border: "none",
                padding: "5px 25px",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "7px",
              }}
            >
              Get certificate
            </button>
          )
        ) : null}
      </div>
      {user ? (
        <div className="preserverdQuizMainDiv">
          {preservedQuizData?.map((singleQuestion, index) => {
            return (
              <div>
                {singleQuestion?.correct ? (
                  <div className="correctAnsDiv">
                    <h4>Question No: {index + 1}</h4>
                    <h5 style={{ fontWeight: "bold" }}>
                      {singleQuestion?.question}
                    </h5>
                    <p>(a) {singleQuestion?.a}</p>
                    <p>(b) {singleQuestion?.b}</p>
                    <p>(c) {singleQuestion?.c}</p>
                    <p>(d) {singleQuestion?.d}</p>
                    <h5>
                      Your answer :
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        {singleQuestion?.answer}
                      </span>
                    </h5>
                    <div className="d-flex">
                      <CheckCircleOutlined
                        style={{
                          fontSize: "30px",
                          color: "green",
                          marginRight: "10px",
                        }}
                      />

                      <h5>Correct</h5>
                    </div>
                  </div>
                ) : (
                  <div className="wrongAnsDiv">
                    <h4>Question No: {index + 1}</h4>
                    <h5 style={{ fontWeight: "bold" }}>
                      {singleQuestion?.question}
                    </h5>
                    <p>(a) {singleQuestion?.a}</p>
                    <p>(b) {singleQuestion?.b}</p>
                    <p>(c) {singleQuestion?.c}</p>
                    <p>(d) {singleQuestion?.d}</p>
                    <h5>
                      Your answer :
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "5px",
                        }}
                      >
                        {singleQuestion?.answer}
                      </span>
                    </h5>
                    <div className="d-flex">
                      <CloseCircleOutlined
                        style={{
                          fontSize: "30px",
                          color: "red",
                          marginRight: "10px",
                        }}
                      />

                      <h5>Wrong</h5>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      ) : null}
    </Layout>
  );
};

export default PreserveQuiz;
