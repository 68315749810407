import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Layout from "../../components/Layout";
import "./Seminar.css";
import { Helmet } from "react-helmet";

const Seminar = () => {
  const [seminarData, setSeminarData] = useState(null);
  const history = useHistory();
  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_URL}/api/seminar/get-all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("seminar", data);
        setSeminarData(data.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Layout footer={true}>
      <Helmet>
        <title>Free Seminar</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      <div className="seminar_div_wrapper">
        <h2 className="seminar_heading">ফ্রি সেমিনারের সময়সূচি </h2>
        <p className="seminar_deatils">
          Qlearn সকলের জন্য প্রতিমাসে বেশ কিছু ফ্রি সেমিনারের আয়োজন করে থাকে।
          আমাদের কোর্স রিলেটেড এবং পাশাপাশি বিভিন্ন টপিকের উপরে আমাদের সেমিনার
          গুলো অনুষ্ঠিত হয়ে থাকে। টপিক অনুযায়ী আপনি যদি সেমিনার এ আগ্রহী হয়ে
          থাকেন তাহলে সেমিনারের Join বাটনে ক্লিক করে আপনার তথ্য দিয়ে রাখুন।
          সেমিনারের আগেই আপনার কাছে সেমিনারের লিংক চলে যাবে এবং আমাদের
          সেমিনারগুলো সম্পুর্ণ ফ্রিতে হয়ে থাকে।
        </p>
      </div>
      <div className="seminar_join_link_div">
        {seminarData ? (
          seminarData?.map((data) => {
            return (
              <div key={data?._id} className="individual_seminar">
                <div className="individual_seminar_left">
                  <h2 className="individual_seminar_left_date">{data?.date}</h2>
                  <span className="individual_seminar_left_down">
                    <span className="individual_seminar_left_month">
                      {data?.month}
                    </span>
                    <span className="individual_seminar_left_year">
                      {data?.year}
                    </span>
                  </span>
                </div>
                <div className="individual_seminar_right">
                  <div className="individual_seminar_right_left">
                    <h2
                      style={{
                        padding: "10px",
                        fontSize: "25px",
                        fontFamily: "Hind Siliguri",
                        // color: "#2b8ba0",
                        color: "#226e91",
                      }}
                    >
                      {data?.title}
                    </h2>
                    <h3
                      style={{
                        paddingLeft: "10px",
                        paddingTop: "5px",
                        fontSize: "20px",
                      }}
                    >
                      <span
                      // style={{
                      //   fontFamily: "Hind Siliguri",
                      // }}
                      >
                        {data?.type}{" "}
                      </span>
                      <span
                        style={{
                          paddingRight: "10px",
                          paddingLeft: "40px",
                        }}
                      >
                        {data?.zone} : {data?.time} টা
                      </span>
                    </h3>

                    <h2
                      style={{
                        padding: "5px",
                        fontSize: "25px",
                        fontFamily: "Hind Siliguri",
                        paddingLeft: "10px",
                        color: "#267aa1",
                      }}
                    >
                      {data?.speaker}
                    </h2>
                    <h2
                      style={{
                        padding: "5px",
                        fontSize: "25px",
                        fontFamily: "Hind Siliguri",
                        paddingLeft: "10px",
                      }}
                    >
                      {data?.speakerShortInfo}
                    </h2>
                  </div>
                  <div className="individual_seminar_right_right">
                    <button
                      className="individual_seminar_right_right_button"
                      onClick={() => {
                        history.push(`/seminarFom/${data._id}`);
                      }}
                    >
                      Join
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="mt-5 mb-5">
            {" "}
            <h2 className="text-center mt-5 mb-5">
              No seminar available right now
            </h2>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Seminar;
