import axios from 'axios';

export const updateProfile = async (id, values) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  return await axios.put(
    `${process.env.REACT_APP_DEV_URL}/api/student/update/${id}`,
    values,
    config
  );
};
