import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { Checkbox, message } from "antd";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getCurrentUser, studentLogin } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../../actions/types";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../Signup/Auth.css";
import LoginImage from "../../images/loginImage.svg";
import GoogleAuthentication from "../GoogleLogin/GoogleAuthentication";
import GoogleFirebase from "../GoogleFirebase/GoogleFirebase";
import { Helmet } from "react-helmet";

const Login = ({ getCurrentUser }) => {
  const { register, handleSubmit, errors } = useForm();
  const [refreshToken, setRefreshToken] = useState(null);
  const [googleToken, setGoogleToken] = useState(null);

  const msg = "Please verify your email";

  // Checkbox onchange function
  function checkboxOnChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== undefined) {
      setRefreshToken(localStorage.getItem("auth_refresh_token"));
    }
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      setGoogleToken(localStorage.getItem("goole_access_token"));
    }
  }, []);

  const onSubmit = async (data) => {
    studentLogin(data)
      .then((res) => {
        console.log(res?.data);
        if (res?.data?.success) {
          // sessionStorage.getItem('redirectURL')
          //   ? history.push(sessionStorage.getItem('redirectURL')) 
          //   sessionStorage.setItem("redirectURL", "")
          //   : history.push('/');
          if (sessionStorage.getItem('redirectURL')) {
            history.push(sessionStorage.getItem('redirectURL'));
            sessionStorage.setItem("redirectURL", "");
          } else {
            history.push('/');
          }
          
          message.success(res?.data?.message);
          getCurrentUser(res?.data?.data?._id);

          console.log(res?.data);
          typeof window !== undefined &&
            localStorage.setItem("userId", res?.data?.data?._id);
          typeof window !== undefined &&
            localStorage.setItem(
              "auth_access_token",
              res?.headers?.auth_access_token
            );
          typeof window !== undefined &&
            localStorage.setItem(
              "auth_refresh_token",
              res?.headers?.auth_refresh_token
            );

          dispatch({
            type: LOGIN,
            payload: res?.data,
          });
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message === undefined) {
          message.error(err?.response?.data);
        } else {
          message.error(err?.response?.data?.message);
          if (err?.response?.data?.message === msg) {
            history.push(`/verify-email?email=${data?.email}`);
          }
        }
      });
  };

  if (refreshToken) {
    history.push("/");
  }


  return (
    <Layout footer={false}>
      <Helmet>
        <title>Login</title>
        <meta
          property="og:description"
          content=""
          data-react-helmet="true"
        />
      </Helmet>
      <section className="login">
        <div className="login__wrapper container">
          <div className="login__wrapper__left">
            <img
              className="login__wrapper__left__loginImage"
              src={LoginImage}
              alt="Login"
            />
          </div>
          <div className="login__wrapper__right">
            <div className="login__wrapper__right__container">
              <h2>Login to your account</h2>

              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  ref={register({
                    require: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  })}
                  type="text"
                  name="email"
                  placeholder="Enter email"
                  required
                />
                {errors.email && (
                  <span className="error">This field is required</span>
                )}
                <br />
                <input
                  ref={register({
                    require: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  })}
                  type="password"
                  name="password"
                  placeholder="Enter password"
                  required
                />
                {errors.password && (
                  <span className="error">This field is required</span>
                )}
                <br />
                <div className="login__wrapper__right__container__remember">
                  <Checkbox onChange={checkboxOnChange}>Remember me</Checkbox>
                  <p>
                    <Link to="/forgot-password">Forgot password?</Link>
                  </p>
                </div>
                <div>
                  <button
                    style={{ borderRadius: "4px", width: "100%" }}
                    className="button-2"
                    type="submit"
                  >
                    Sign in
                  </button>
                </div>
              </form>
              <p className="login__wrapper__right__container__or">Or </p>
              {/* <div className="login__wrapper__right__container__social__login">
                <button>
                  <img src={colorGoogle} alt="Google" /> Signin with Google
                </button>
                <button>
                  <img src={colorFacebook} alt="Google" /> Signin with Facebook
                </button>
              </div> */}
              <div className="d-flex justify-content-center">
                {/* <GoogleAuthentication></GoogleAuthentication> */}
                <GoogleFirebase></GoogleFirebase>
              </div>
              <p className="login__wrapper__right__container__or">Or </p>
              <p style={{ marginTop: "30px", textAlign: "center" }}>
                Need an account?{" "}
                <Link to="/signup">
                  <div>
                    <span style={{ color: "var(--secondary-color)" }}>
                      Sign up
                    </span>
                  </div>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

Login.prototype = {
  getCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentUser })(Login);
