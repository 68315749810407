import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../../components/Layout";

import "./GiveReview.css";
import { useSelector } from "react-redux";
import { message } from "antd";
const GiveReview = () => {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [ratingError, setRatingError] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const { courseId } = useParams();
  const { user } = useSelector((state) => state.auth);

  const history = useHistory();
  const handleReview = (e) => {
    e.preventDefault();
    console.log(rating, comment, user._id);
    if (!rating) {
      console.log("ratingError");
      setRatingError(true);
      return;
    }
    if (!comment) {
      console.log("commentEror");
      setCommentError(true);
      return;
    }
    const body = {
      userId: user._id,
      rating: rating,
      comment: comment,
      courseId: courseId,
    };
    fetch(`${process.env.REACT_APP_DEV_URL}/api/review/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((res) => res?.json())
      .then((data) => {
        console.log(data);
        if (data?.success) {
          message.success(data?.message, 3, "Review");
          setRating(0);
          setComment("");
          setRatingError(false);
          setCommentError(false);
        } else {
          message.error(data?.message, 3, "Review");
          setRating(0);
          setComment("");
          setRatingError(false);
          setCommentError(false);
        }
        history.push(`/watching/${courseId}`)
      })
      .catch((err) => {
        console.log(err?.response?.data);
        message.error(err?.response?.data?.message, "Review");
      });
  };
  console.log(courseId);
  return (
    <Layout footer={true}>
      <div className="seminar_form_div">
        <h2 className="seminar_form_heading">Give Review</h2>
        <div className="form_div">
          <p className="ratingHeading">Your Rating:</p>
          <form className="rating">
            <label>
              <input
                onChange={(e) => setRating(e.target.value)}
                type="radio"
                name="stars"
                value="1"
              />
              <span className="icon">★</span>
            </label>
            <label>
              <input
                onChange={(e) => setRating(e.target.value)}
                type="radio"
                name="stars"
                value="2"
              />
              <span className="icon">★</span>
              <span className="icon">★</span>
            </label>
            <label>
              <input
                onChange={(e) => setRating(e.target.value)}
                type="radio"
                name="stars"
                value="3"
              />
              <span className="icon">★</span>
              <span className="icon">★</span>
              <span className="icon">★</span>
            </label>
            <label>
              <input
                onChange={(e) => setRating(e.target.value)}
                type="radio"
                name="stars"
                value="4"
              />
              <span className="icon">★</span>
              <span className="icon">★</span>
              <span className="icon">★</span>
              <span className="icon">★</span>
            </label>
            <label>
              <input
                onChange={(e) => setRating(e.target.value)}
                type="radio"
                name="stars"
                value="5"
              />
              <span className="icon">★</span>
              <span className="icon">★</span>
              <span className="icon">★</span>
              <span className="icon">★</span>
              <span className="icon">★</span>
            </label>
          </form>
          <form onSubmit={handleReview}>
            {ratingError ? (
              <p
                style={{
                  color: "red",
                }}
              >
                Please, give a valid review number 0 to 5
              </p>
            ) : null}

            <textarea
              name="comment"
              onChange={(e) => {
                setComment(e.target.value);
              }}
              rows="4"
              cols="50"
              className="input2"
              type="textarea"
              placeholder="Your Valuable Comment"
            ></textarea>

            {commentError ? (
              <p
                style={{
                  color: "red",
                }}
              >
                Please, write your comment
              </p>
            ) : null}
            <button type="submit" className="seminar_join_button">
              Submit
            </button>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default GiveReview;
