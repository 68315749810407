import Layout from '../../components/Layout';
import React from 'react';
import { Collapse, Result } from 'antd';
import './Faq.css';
import { Helmet } from 'react-helmet';

const { Panel } = Collapse;

function Faq() {
  function callback(key) {
    console.log(key);
  }

  return (
    <Layout footer={false}>
    <Helmet>
        <title>FAQ</title>
        <meta
          property="og:description"
          content=""
          data-react-helmet="true"
        />
      </Helmet>
      <div className="teachers__wrapper__header">
        <h2>FAQ</h2>
      </div>
      <div className="container faq">
        <Collapse defaultActiveKey={['1']} onChange={callback}>
          <Panel header="How can I register at qlearn.com.bd?" key="1">
            <div>
              <ul>
                <li>
                  Click on the {}
                  <a
                    href="https://www.ourprofessors.com/signup"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Login {}
                  </a>
                  Button.
                </li>
                <li>
                  If the Signup page appears, provide the necessary information
                  and click on the Signup button. You will receive a
                  confirmation email in the email address you provided. Click on
                  that emails verify link and your account will be activated.
                  You will not be able to login without verifying your email.
                </li>
                <li>
                  Your free account is created and you are now eligible to learn
                  free courses
                </li>
              </ul>
              <div id="kpm-root" class="kpm_LTR">
                <strong>For Registration:</strong>
              </div>
              <ul>
                <li>
                  Click on &#8220;Join for Free&#8221; and Fill up the form
                </li>
                <li>
                  Write &#8220;Full Name&#8221; as per your Academic Certificate
                </li>
                <li>Write &#8220;USERNAME&#8221; as your Name</li>
                <li>
                  Your Email address (You will not be able to change it later)
                </li>
                <li>Your Password (2 Times)</li>
                <li>Your Phone Number</li>
              </ul>
              <p>
                <strong>Edit Your Profile</strong>
              </p>
              <ul>
                <li>
                  Click on Your Profile Avatar from Top Right corner of the
                  screen
                </li>
                <li>Click on &#8220;Profile&#8221;</li>
                <li>
                  Enter your name, NID and other details as per your academic
                  certificates. Please remember that we issue your course
                  certificates using the name you entered in the website.
                </li>
                <li>Scroll to Bottom and Click &#8220;SAVE CHANGES&#8221;</li>
              </ul>
            </div>
          </Panel>
          <Panel header="How Can I Reset Password? " key="2">
            <div>
              <p>
                It is not impossible to forget your password. But if you have
                forgotten your password, don't worry. Just go to Login page,
                click on reset password, provide the email address (the one you
                used to register previously) and submit. You will get a password
                reset email in your email address. Clicking on that email will
                take you to a password reset page, where you can reset your
                password.
              </p>
            </div>
          </Panel>
          <Panel header="How Can I Purchase Course" key="3">
            <div>
              <p>
                Go to courses page from the menu, click on your desired course,
                click on the enroll now button from right hand side card, make
                the payment and you will be automatically enrolled in that
                course. You can find your enrolled course from 'Dashboard'
                section, by clicking on your profile avatar.
              </p>
            </div>
          </Panel>
          <Panel header="What are Package Offers?" key="4">
            <div>
              <p>
                Through package offer, you can buy a lot of courses as a bundle.
                To buy courses as bundle, just click on bundle courses from
                courses page. For further info just click on support page and
                contact support through email or phone.
              </p>
            </div>
          </Panel>
          <Panel header="How Can I Talk with an Agent" key="5">
            <div>
              <p>
                Click on 'Support' menu from top bar. You will be taken to
                support page where you can find details about contacting
                support. Or just contact through this number: +880 1885-973767
              </p>
            </div>
          </Panel>
        </Collapse>
      </div>
    </Layout>
  );
}

export default Faq;
