import React, { useState, useEffect } from "react";
import { useSignInWithGoogle } from "react-firebase-hooks/auth";
import goAuth from "../../firebase.init";
import { message } from "antd";
import { getCurrentUser, studentRegisterWithGoogle } from "../../actions/auth";
import { useHistory } from "react-router-dom";
import { GET_CURRENT_USER } from "../../actions/types";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const GoogleFirebase = () => {
  

  const history = useHistory();

  const dispatch = useDispatch();

  const [signInWithGoogle, user, loading, error] = useSignInWithGoogle(goAuth);
  const handleSignInWithGoogle = () => {
    signInWithGoogle();
    
  };

  useEffect(() => {
    console.log("googleLoginHandle");

    console.log(user?.user);
    if (user?.user) {
      console.log("content");
      const content = {
        
        fullName: user?.user?.displayName,
        email: user?.user?.email,
        phoneNumber: user?.user?.phoneNumber
          ? user?.user?.phoneNumber
          : "",
        password: "op_q!11@nt",
      };

      if (user?.user?.email) {
        console.log("studentRegisterWithGoogle");
        studentRegisterWithGoogle(content)
          .then((res) => {
            console.log(res);
            if (res?.data?.success) {
              message.success(res?.data?.message);
              console.log(res?.data?.data?._id);

              getCurrentUser(res?.data?.data?._id);
              typeof window !== undefined &&
                localStorage.setItem("userId", res?.data?.data?._id);

              dispatch({
                type: GET_CURRENT_USER,
                payload: res?.data?.data,
              });

              sessionStorage.getItem("redirectURL")
                ? history.push(sessionStorage.getItem("redirectURL"))
                : history.push("/");
              
            }
          })
          .catch((err) => {
            if (err?.response?.data?.message === undefined) {
              message.error(err?.response?.data);
            } else {
              message.error(err?.response?.data?.message);
            }
          });
      }
    }
  }, [user]);

  return (
    <div>
      <button
        style={{
          borderRadius: "4px",
          width: "100%",
          marginTop: "20px",
          border: "2px solid black",
        }}
        className="button-1"
        onClick={() => handleSignInWithGoogle()}
      >
        <img src="https://img.icons8.com/color/32/000000/google-logo.png" alt = 'google' className="me-3"/>
        <b>Continue With Google</b>
      </button>
    </div>
  );
};

GoogleFirebase.prototype = {
  getCurrentUser: PropTypes.func.isRequired,
};

export default connect(null, { getCurrentUser })(GoogleFirebase);
