import Layout from '../../components/Layout';
import React from 'react';
import BlogCard from '../../components/BlogCard';
import { useEffect, useState } from 'react';
import { getBlogList } from '../../actions/blog';
import './Blogs.css';
import { Helmet } from 'react-helmet';

function Blogs() {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getBlogList()
      .then((res) => {
        if (res?.data?.success) {
          if (res?.data?.message?.length > 0) {
            const blogs = res?.data?.message?.filter(
              (bl) => bl.published === true
            );
            setBlogs(blogs);
            setLoading(false);
          } else {
            setBlogs(res?.data?.message);
            setLoading(false);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Layout footer={false}>
      <Helmet>
        <title>Blogs</title>
        <meta name="description" property="og:description" content="" />
      </Helmet>
      <div className="container blog__wrapper">
        <div
          style={{
            padding: "50px 0px",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          {loading ? (
            "Loading..."
          ) : blogs?.length > 0 ? (
            blogs?.map((blog) => <BlogCard key={blog?._id} blog={blog} />)
          ) : (
            <div>No blogs found</div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Blogs;
