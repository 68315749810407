import React from 'react';
import '../../pages/LandingPage/LandingPage.css';
import EducationImage from '../../images/education.svg';

const Education = () => {
  return (
    <div className="education">
      <img src={EducationImage} alt="Education" />
    </div>
  );
};

export default Education;
