import Layout from "../../components/Layout";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./Quiz.css";
import toastr from "toastr";
import { useSelector } from "react-redux";
import Countdown from "react-countdown";
import useCertificateHandler from "../Watching/useCertificateHandler";
import { getSpecificCourseById } from "../../actions/course";
import { Helmet } from "react-helmet";

const Completionist = () => {
  return (
    <div
      style={{
        height: "600px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h3>Exam Time Finished</h3>
    </div>
  );
};

const Quiz = () => {
  const [questions, setQuestions] = useState({});
  const [isloading, setLoading] = useState(true);
  const [duration, setDuration] = useState("");
  const [ans, setAns] = useState([{}]);
  const [length, setLength] = useState(0);
  const [isSubmited, setIsSubmited] = useState(false);
  const [result, setResult] = useState(null);
  const [data, setData] = useState({});
  const [coursePaid, setCoursePaid] = useState(true);
  const { courseID } = useParams();
  const { user } = useSelector((state) => state.auth);
  const { savedCertificate, handleDownloadCertificate, handleGetCertificate } =
    useCertificateHandler(courseID, user);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_URL}/api/course/quiz/get/${courseID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setDuration(data.data.quizDuration);
        setLength(data.data.quiz.length);
        setQuestions(data.data.quiz);
        setLoading(false);
      });
  }, [isloading, courseID]);

  console.log(data?.quiz);
  useEffect(() => {
    if (courseID) {
      getSpecificCourseById(courseID)
        .then((res) => {
          if (res?.data?.success) {
            setCoursePaid(res.data.message.course_paid);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [courseID]);

  useEffect(() => {
    for (let i = 0; i < length; i++) {
      ans[i] = { question: data.quiz[i].question, answer: "" };
      setAns(ans);
    }
  }, [length]);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      const handleInputChange = (e, index, question) => {
        const { name, value } = e.target;
        const list = [...ans];
        list[index]["question"] = question;
        list[index]["answer"] = value;
        setAns(list);
      };

      const handleSubmit = (e) => {
        const userId = user._id;
        const body = {
          quiz: ans,
          userId: userId,
        };
        setIsSubmited(true);
        const id = courseID;
        console.log(ans);
        fetch(
          `${process.env.REACT_APP_DEV_URL}/api/course/quiz/check-pass/${id}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(body),
          }
        )
          .then((res) => res?.json())
          .then((data) => {
            setResult(data);
            if (data?.success) {
              toastr.success(data?.message, "Quiz");
            } else {
              toastr.error(data?.message, "Quiz");
            }
          })
          .catch((err) => toastr.error(err?.response?.data?.message, "Quiz"));
      };

      return (
        <div>
          <div>
            <div className="quizHeadingDiv">
              <div className="row">
                <div className="col-lg-1 col-md-0 col-sm-0"></div>
                <div className=" d-flex justify-content-center col-lg-6 col-md-7 col-sm-12 quizInstruction">
                  <p>
                    You are taking "The Final Quiz” as a timed exam. The timer
                    on the right shows the time remaining for the exam. To
                    receive grades for problems, you must select "Submit" before
                    the timer ends.
                  </p>
                </div>
                <div className="d-flex justify-content-center col-lg-5 col-md-5 col-sm-12 quizTime">
                  Time Remaining :
                  <span>
                    {hours}:{minutes}:{seconds}{" "}
                  </span>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-lg-2 col-md-0 col-sm-0"></div>
              <div className="col-lg-5 col-md-6 col-sm-12 total">
                <h5 className="totalMarks">
                  Total Marks: {data.quizTotalMarks}
                </h5>{" "}
              </div>
              {/* <div className="col-lg-5 col-md-6 col-sm-12 total">
                <h5 className="totalMarks">Pass Marks:{data.quizPassMarks}</h5>{" "}
              </div> */}
              <div className="col-lg-5 col-md-6 col-sm-12 total">
                <h5 className="totalMarks">Pass Marks Percentage: {data.quizPassPercentage ?? 40}%</h5>{" "}
              </div>
              <div className="col-lg-2 col-md-0 col-sm-0"></div>
            </div>

            <div className="questionDiv">
              <div className="row">
                <div className="col-xs-1 col-sm-2 col-md-2 col-lg-2"></div>
                <div className="col-xs-10 col-sm-9 col-md-9 col-lg-8">
                  {questions?.map((question, index) => {
                    return (
                      <div className="mt-4 individualQuestionDiv">
                        <h5>Question No: {index + 1} </h5>
                        <h5 className="questionItem">{question.question}</h5>
                        <div className="singleAnswerDiv">
                          <div className="singleAnswer">
                            <input
                              type="radio"
                              name={question.question}
                              value={question.a}
                              className=""
                              onChange={(e) =>
                                handleInputChange(e, index, question.question)
                              }
                            />
                            <label className="" htmlFor="vehicle1">
                              {question.a}
                            </label>
                          </div>
                        </div>
                        <div className="singleAnswerDiv">
                          <div className="singleAnswer">
                            <input
                              type="radio"
                              name={question.question}
                              value={question.b}
                              onChange={(e) =>
                                handleInputChange(e, index, question.question)
                              }
                            />
                            <label htmlFor="vehicle1">{question.b}</label>
                          </div>
                        </div>
                        <div className="singleAnswerDiv">
                          <div className="singleAnswer">
                            <input
                              type="radio"
                              name={question.question}
                              value={question.c}
                              onChange={(e) =>
                                handleInputChange(e, index, question.question)
                              }
                            />
                            <label htmlFor="vehicle1">{question.c}</label>
                          </div>
                        </div>
                        <div className="singleAnswerDiv">
                          <div className="singleAnswer">
                            <input
                              type="radio"
                              name={question.question}
                              value={question.d}
                              onChange={(e) =>
                                handleInputChange(e, index, question.question)
                              }
                            />
                            <label htmlFor="vehicle1">{question.d}</label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="col-xs-1 col-sm-1  col-md-1 col-lg-2 "></div>
              </div>
            </div>

            <div className="d-flex justify-content-center mb-3">
              <button
                className="quizEvaluationButton"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <Helmet>
        <title>Quiz</title>
        <meta property="og:description" content="" data-react-helmet="true" />
      </Helmet>
      {isloading ? (
        <></>
      ) : isSubmited ? (
        // result?.message === "Congratulations. You passed the quiz." ? (
        result?.data?.pass ? (
          <>
            <Layout footer={true}>
              <div className="d-flex justify-content-center imageDiv flex-direction-row">
                {/* <img src={congratulationsPic} alt="congratulations"></img> */}
                <div className="quizResultText">
                  <h2 className="text-center">
                    You have earned a{" "}
                    <span style={{ color: "red" }}> Passing Grade </span> on
                    your exam.
                  </h2>
                  <h2 className="text-center">{result?.message}</h2>
                  <div className="d-flex justify-content-center">
                    {coursePaid ? (
                      savedCertificate ? (
                        <button
                          onClick={handleDownloadCertificate}
                          style={{
                            background: "#1c659ee0",
                            color: "white",
                            border: "none",
                            padding: "5px 25px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            marginTop: "7px",
                          }}
                        >
                          Download certificate
                        </button>
                      ) : (
                        <button
                          onClick={handleGetCertificate}
                          style={{
                            background: "#1c659ee0",
                            color: "white",
                            border: "none",
                            padding: "5px 25px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            marginTop: "7px",
                          }}
                        >
                          Get certificate
                        </button>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </Layout>
          </>
        ) : (
          <>
            <Layout footer={true}>
              <div className="d-flex justify-content-center imageDiv2">
                {/* <img className="image" src={failurePic} alt="failure"></img> */}
                <div className="quizResultText">
                  <h2 className="text-center">
                    You are
                    <span style={{ color: "red" }}> Failed</span> to earn a
                    passing grade on your exam.
                  </h2>
                  <h2 className="text-center">{result?.message}</h2>
                </div>
              </div>
            </Layout>
          </>
        )
      ) : data?.quiz.length > 0 ? (
        <Layout footer={true}>
          <Countdown
            date={Date.now() + parseInt(duration) * 60 * 1000}
            renderer={renderer}
          />
        </Layout>
      ) : (
        <Layout footer={true}>
          <div
            style={{
              height: "350px",
              width: "100%",
              textAlign: "center",
              marginTop: "100px",
            }}
          >
            <h1>No quiz available at the moment</h1>
          </div>
        </Layout>
      )}
    </div>
  );
};

export default Quiz;
