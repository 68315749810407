import React, { useEffect, useState } from "react";

const Countdown = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = new Date(targetDate) - new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
            return timeLeft;
        }

        else if (difference === 0 || difference < 0) {
            timeLeft = {
                days: 0,
                hours: 0,
                minutes: 0,
                seconds: 0,
            };
            return timeLeft;
        }

        
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    const addLeadingZero = (value) => {
        return value.toString().padStart(2, "0");
    };

    return (
        <div>
            {timeLeft.days} days, {addLeadingZero(timeLeft.hours)}:
            {addLeadingZero(timeLeft.minutes)}:{addLeadingZero(timeLeft.seconds)}
        </div>
    );
};

export default Countdown;
