import React from 'react';
import Layout from '../../components/Layout';
import Education from '../../components/SharedComponents/Education';
import { getAllBooks } from '../../actions/book';
import '../LandingPage/LandingPage.css';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';

const AllPDF = () => {
  const [books, setBooks] = React.useState([]);

  React.useEffect(() => {
    getAllBooks().then((res) => {
      if (res?.data?.success) {
        res?.data?.message?.map((b) => {
          if (b?.book_publish === true) {
            setBooks((pb) => [...pb, b]);
          }
        });
      }
    });
  }, []);

  const history = useHistory();

  const handleRedirect = (slug) => {
    history.push(`/book-details/${slug}`);
  };
  const BookComponents = (id, slug, path, title, author) => (
    <div style={{ width: "400px", display: "flex", justifyContent: "center" }}>
      <Helmet>
        <title>All Pdf</title>
        
      </Helmet>
      <div onClick={() => handleRedirect(slug)} className="singleBook">
        <div style={{ width: "100%", height: "200px", overflow: "hidden" }}>
          <img src={path} alt={title} width={120} height={120} />
        </div>

        <h5>{title}</h5>
        <p>{author}</p>
      </div>
    </div>
  );

  return (
    <Layout footer={true}>
      <div className="course">
        <div className="course__wrapper container">
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            {books?.length > 0 ? (
              books?.map((book) =>
                BookComponents(
                  book?._id,
                  book?.slug,
                  `${process.env.REACT_APP_DEV_URL}/api/book/download/${book?.book_cover}`,
                  book?.book_name,
                  book?.author_name
                )
              )
            ) : (
              <p style={{ width: '100%', textAlign: 'center' }}>
                No books found
              </p>
            )}
          </div>
          {/* <div style={{ marginTop: '20px' }}>
            <Pagination defaultCurrent={1} total={50} />
          </div> */}
          <Education />
        </div>
      </div>
    </Layout>
  );
};
export default AllPDF;
