import React, { useState, useEffect } from 'react';
import { Collapse } from 'antd';
import { YoutubeOutlined } from '@ant-design/icons';
import { getSpecificSection } from '../../actions/course';
import '../../pages/CourseDetails/CourseDetails.css';

const { Panel } = Collapse;
const CourseContent = ({ courseDetails }) => {
  const [sectionDetails, setSectionDetails] = useState([]);
  const [sections, setSections] = useState([]);

  const content = (number, title) => (
    <div
      style={{
        fontSize: '16px',
        color: '#169340',
        display: 'flex',
        margin: '0',
        cursor: 'pointer',
      }}
    >
      <YoutubeOutlined
        style={{ color: 'gray', fontSize: '23px', marginRight: '6px' }}
      />{' '}
      {number}. {title}
    </div>
  );

  // Get Section Details

  useEffect(() => {
    courseDetails?.chapter?.map((sec) => {
      getSpecificSection(sec).then((res) => {
        if (res?.data?.success) {
          setSectionDetails((prevSec) => [...prevSec, res?.data?.message]);
        }
      });
    });
  }, [courseDetails]);

  useEffect(() => {
    const key = '_id';
    const arrayUniqueByKey = [
      ...new Map(sectionDetails.map((item) => [item[key], item])).values(),
    ];

    setSections(
      arrayUniqueByKey.sort(function (a, b) {
        var dateA = new Date(a.updated_at),
          dateB = new Date(b.updated_at);
        return dateA - dateB;
      })
    );
  }, [sectionDetails]);

  return (
    <div className="courseContent">
      <div className="courseContent__wrapper">
        <h3>Course Details</h3>

        <div className="courseContent__body">
          <Collapse accordion>
            {sections?.map((sec) => (
              <Panel header={sec?.chapter_title} key={sec?.chapter_id}>
                {sec?.lessons?.map((les, i) => content(i + 1, les?.title))}
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
