import React from "react";
import Layout from "../../components/Layout";
import { MailTwoTone } from "@ant-design/icons";
import { Helmet } from "react-helmet";
const PrivacyPolicy = () => {
  return (
    <Layout footer={true}>
    <Helmet>
        <title>Privacy Policy</title>
        <meta
          property="og:description"
          content=""
          data-react-helmet="true"
        />
      </Helmet>
      <div className="teachers__wrapper__header">
        <h2>Privacy Policy</h2>
      </div>
      <div className="container">
        <div style={{ padding: "20px 20px 80px 20px" }}>
          <h1>Privacy Policy for Qlearn</h1>
          <p>
            "QLearn" shall mean and refer to the creators of this privacy
            policy. "You", "Your", "Yourself", and "User" shall mean and refer
            to natural and legal individuals who use the website. "Website"
            shall mean and refer to the QLearn website created by QLearn. "App"
            shall mean and refer to the QLearn app created by QLearn. "Personal
            Information" shall mean and refer to any personally identifiable
            information that QLearn may collect from you. "Third Parties" refer
            to any website, company, or individual apart from the user and the
            creator of the website.
          </p>

          <p>
            We commit to respecting your online data privacy and recognize the
            need for appropriate protection and management of any Personal
            Information you share with us. Information that is considered
            personal about you by us includes, but is not limited to, your name,
            address, email address, phone number, or other contact information.
            In order to purchase any product from the website or app, you may be
            required to provide certain information such as your name, date of
            birth, mobile number, banking details, educational information, and
            email address. This privacy policy also applies to data we collect
            from users who are not registered as members of this site or the
            app, including, but not limited to, browsing behaviour and pages
            viewed. The website and app may also request permissions to link
            your QLearn account with your social media accounts. If you grant
            these permissions, information will be collected directly from your
            social media account.
          </p>

          <p>
            QLearn reserves the right to change the terms of this privacy policy
            and the terms of service at any time without notice to you. It is
            recommended that you regularly review these terms. If you do not
            accept any modifications or amendments to these terms, you may
            terminate your use of the QLearn website and app immediately.
          </p>

          <p>
            QLearn may collect and store personal information provided by you,
            including contact information and demographic information. As a
            member, you may be required to provide a valid email address at
            registration and choose a name that represents your identity on the
            QLearn website and app. We may also collect and store information
            about your interaction with the website and app, such as the URLs
            you visit, your browser information, your IP address, and other
            information associated with your use of the website and app.
          </p>

          <p>
            We may use the personal information collected from you to provide
            the services you have requested, communicate necessary account and
            product/service-related information, offer customer care services,
            prevent fraud and money laundering, and comply with applicable laws
            and regulations. We may also use your personal information to
            provide you with information and offers on products and services
            that may be of interest to you. If a service requested by you
            involves a third party, we may share necessary information with that
            third party in order to facilitate the requested service.
          </p>

          <p>
            QLearn may use third-party service providers to collect, store, and
            process personal information on our behalf. These service providers
            are bound by strict confidentiality agreements and are not allowed
            to use your personal information for any purpose other than as
            instructed by QLearn.
          </p>

          <p>
            QLearn takes reasonable steps to protect the security of your
            personal information. However, we cannot guarantee the complete
            security of your personal information as no method of electronic
            transmission or storage is completely secure.
          </p>

          <p>
            This privacy policy only applies to QLearn and does not apply to any
            third-party websites or services that you may access through the
            QLearn website or app. We encourage you to review the privacy
            policies of any third-party websites or services you access.
          </p>

          <p>
            QLearn collects personal information for specific purposes and only
            uses this information for those purposes, unless we obtain consent
            from the individual or as required by law. We will only retain
            personal information for as long as necessary to fulfil these
            purposes. QLearn collects personal information through lawful and
            fair means and, where appropriate, with the knowledge or consent of
            the individual. The personal information we collect is relevant to
            the purposes for which it is used and is accurate, complete, and
            up-to-date.
          </p>

          <p>
            QLearn may use "cookies" on certain pages of our website and app.
            These small files on your hard drive assist us in providing
            customised services and certain features that are only available
            through the use of a cookie. Cookies may also be used to identify
            logged in or registered users and to target ads based on your
            interests. You may visit the website of the third party and choose
            to opt out of the use of cookies for interest-based advertising if
            the third party offers this option. QLearn has enabled Google
            Analytics Advertising, which allows Google to collect data about
            users on our website, in addition to Google advertising cookies and
            anonymous identifiers. You may choose to opt out of this by
            downloading and installing the Google Analytics opt-out add-on.
          </p>

          <p>
            The QLearn website may include hyperlinks to other websites or
            resources. QLearn is not responsible for the availability of these
            external sites or resources and does not endorse any advertising,
            products, or other materials on or available from these websites or
            resources. QLearn is not liable for any loss or damage that may be
            incurred as a result of the availability of these external sites or
            resources or as a result of any reliance on the completeness,
            accuracy, or existence of any advertising, products, or other
            materials on or available from these websites or resources. These
            third-party service providers and websites may have their own
            privacy policies governing the storage and retention of your
            personal information. We recommend that you review the privacy
            policy of any third-party website you visit as it relates to
            safeguarding your personal information. QLearn may use third-party
            advertising companies to serve ads when you visit our website.
          </p>

          <p>
            QLearn may use your contact information to contact you when
            necessary and your IP address to diagnose problems with our server,
            administer our website and app, identify you, gather broad
            demographic information, and protect ourselves and our partners from
            fraud. We may enhance our security procedures as new technology
            becomes available. If QLearn is acquired by or merged with another
            company, we may transfer information about you. In this event, we
            will notify you by email or by posting a prominent notice on our
            website.
          </p>

          <p>
            QLearn may share your personal information with third parties in
            order to provide the services you have requested, such as processing
            payments or facilitating communication. We may also share your
            personal information with third parties in order to comply with
            legal requirements, to enforce our policies, or to protect the
            rights, property, or safety of QLearn, our users, or others. QLearn
            may also share your personal information with third parties for
            marketing purposes, but only with your consent.
          </p>

          <p>
            QLearn takes reasonable steps to protect the security of your
            personal information, but we cannot guarantee the complete security
            of your personal information as no method of electronic transmission
            or storage is completely secure. This privacy policy only applies to
            QLearn and does not apply to any third-party websites or services
            that you may access through the QLearn website or app. We encourage
            you to review the privacy policies of any third-party websites or
            services you access.
          </p>

          <p>
            QLearn may be required to disclose your personal information to
            government agencies or law enforcement, or third parties in certain
            circumstances. We cannot guarantee that all of your private
            communications and other personal information will never be
            disclosed in ways not described in this privacy policy. We use
            industry standard practices to protect your privacy, but we cannot
            guarantee that your personal information will always remain private.
            QLearn does not sell or rent personal information to third parties.
            However, the following describes some situations in which your
            personal information may be disclosed:
          </p>

          <p>
            External Service Providers: There may be optional services offered
            by external service providers that help you use our website. If you
            choose to use these services and disclose information to the
            external service providers or grant them permission to collect
            information about you, their use of your information will be
            governed by their privacy policy. Other Corporate Entities: We may
            share your data, including personal information, with our parent and
            subsidiary companies for the purpose of enhancing your browsing
            experience and providing our services to you. If QLearn or any of
            its subsidiaries merge with or are acquired by another business
            entity, you can expect that we will share some or all of your
            information to continue providing the service. You will be notified
            of such an event.
          </p>
          <p>
            Law and Order: We may disclose any information about you to law
            enforcement and government officials as necessary or appropriate in
            connection with an investigation of fraud, intellectual property
            infringements, or other illegal activity that may expose us or you
            to legal liability.
          </p>
          <p>
            You can review and change your personal information after
            registration. We may keep track of your old information and cannot
            completely remove all of your personal information from our
            databases due to technical and legal constraints, including stored
            "backup" systems.
          </p>
          <p>
            Other parties, such as websites or services that you access through
            QLearn, may also collect your personal information. We encourage you
            to review the privacy policies of any third-party websites or
            services you access.
          </p>
          <p>
            QLearn may use your personal information to send you promotional
            materials or offers, or to contact you for market research purposes.
            If you do not want to receive these materials or be contacted for
            market research, you can opt out of these communications.
          </p>
          <p>
            QLearn may update this privacy policy from time to time. We
            encourage you to review our privacy policy regularly to stay
            informed about how we are protecting your personal information and
            the choices you have. If you have any questions or concerns about
            this privacy policy or the handling of your personal information,
            please contact us at:{" "}
            <MailTwoTone
              style={{
                paddingBottom: "5px",
                marginRight: "10px",
                fontSize: "20px",
              }}
            />
            <a href="mailto:qlearncs@gmail.com">qlearncs@gmail.com</a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
