import React, { useState, useEffect } from "react";
import {
  enrollCourse,
  enrollPaidCourse,
  applyCoupon,
} from "../../actions/course";
import { message } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import "../../pages/CourseDetails/CourseDetails.css";

const PriceCard = ({ courseDetails, bundle, price, applied, coupon, setCoupon, handleApply }) => {
  const [enrolled, setEnrolled] = useState(false);
  const [manual, setManual] = useState(false);
  // const [coupon, setCoupon] = useState("");
  // const [applied, setApplied] = useState(false);
  // const [price, setPrice] = useState(
  //   courseDetails?.sale_price !== undefined
  //     ? courseDetails?.sale_price
  //     : courseDetails?.price
  // );
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  // useEffect(() => {
  //   setPrice(
  //     courseDetails?.sale_price !== undefined
  //       ? courseDetails?.sale_price
  //       : courseDetails?.price
  //   );
  // }, [courseDetails]);

  const history = useHistory();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DEV_URL}/api/client/payment`)
      .then((r) => r.json())
      .then((res) => {
        if (res?.success) {
          if (res?.message === true) {
            setManual(res?.message);
          } else if (res?.message === false) {
            setManual(res?.message);
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // Get enrolled course

  useEffect(() => {
    if (bundle === "true") {
      user?.enrolled_bundle_courses?.length > 0 &&
        user?.enrolled_bundle_courses?.map((course) => {
          if (course?._id === courseDetails?._id) {
            setEnrolled(true);
          }
        });
    } else {
      user?.enrolled_courses?.length > 0 &&
        user?.enrolled_courses?.map((course) => {
          if (course?._id === courseDetails?._id) {
            setEnrolled(true);
          }
        });
    }
  }, [user, courseDetails, bundle]);

  // const handleApply = () => {
  //   if (coupon) {
  //     applyCoupon(coupon, user?.email, courseDetails?._id)
  //       .then((res) => {
  //         if (res?.data?.success) {
  //           console.log("amount", res?.data?.message);
  //           let discount = res?.data?.message;
  //           let amount = res?.data?.amount ?? 0;
  //           discount = parseInt(discount.split(/(\s+)/)[2]);
  //           discount = Number(price) * (discount / 100);

  //           const finalPrice = Math.ceil(
  //             Number(price) - Number(discount) - Number(amount)
  //           );
  //           if (finalPrice <= 10) {
  //             message.error(
  //               "Course Price can not be less than 10tk even after using coupon. Contact support."
  //             );
  //           } else {
  //             setPrice(finalPrice);
  //             setApplied(true);
  //             message.success(`You have get ${discount + amount} TK discount`);
  //           }
  //         } else {
  //           message.error(res?.data?.message);
  //         }
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // };

  // Handle enroll course
  const handleEnroll = () => {

    let affiliateNumber = null;
    if (typeof sessionStorage !== 'undefined') {
      const aff = sessionStorage.getItem('affiliateNumber');
      if (aff !== null) {
        // Do something with the retrieved value
        console.log("aff",aff);
        affiliateNumber = aff;
      }
    }
    
    const paidCourseValues = {
      affiliateNumber: affiliateNumber,
      totalAmount: price,
      cartItems:
        courseDetails?.title !== undefined
          ? courseDetails?.title
          : courseDetails?.name,
      deliveryMethod: "No",
      numItem: 1,
      customerInfo: {
        cusName: user?.fullName,
        cusEmail: user?.email,
        cusAdd1: "Chittagong",
        cusAdd2: "Chittagong",
        cusCity: "Chittagong",
        cusState: "Chittagong",
        cusPostcode: "Chittagong",
        cusCountry: "Chittagong",
        cusPhone: user?.phoneNumber,
        cusFax: "Chittagong",
      },
      shippingInfo: {
        name: "Chittagong",
        shippingAdd1: "Chittagong",
        shippingAdd2: "Chittagong",
        shippingCity: "Chittagong",
        shippingState: "Chittagong",
        shippingPostcode: "Chittagong",
        shippingCountry: "Chittagong",
      },
    };

    if (bundle === "true") {
      enrollPaidCourse(courseDetails?._id, paidCourseValues)
        .then((res) => {
          console.log("enroll course", res);
          if (res?.data) {
            window.location.replace(res?.data);

            sessionStorage.removeItem("affiliateNumber")
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (courseDetails?.course_paid === true) {
        enrollPaidCourse(courseDetails?._id, paidCourseValues)
          .then((res) => {
            console.log("paid enroll course", res);
            window.location.replace(res?.data);
            sessionStorage.removeItem("affiliateNumber")
          })
          .catch((err) => console.log(err));
      } else {
        enrollCourse(courseDetails?._id, user?.email)
          .then((res) => {
            message.success(res?.data?.message);
            setEnrolled(true);
            window.location.replace(`/watching/${courseDetails?._id}`);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleRedirect = () => {
    sessionStorage.setItem(
      "redirectURL",
      `${window.location.pathname}${window.location.search}`
    );
    history.push(`/login`);
  };

  console.log(courseDetails);

  return (
    <div className="priceCard">
      <div className="courseInfo__mobile_card">
        <span style={{ color: "var(--primary-color)" }}>
          {courseDetails?.course_paid === false ? "Free" : <>৳ {price} </>}
          {courseDetails?.regular_price !== 0 &&
            courseDetails?.regular_price !== courseDetails?.sale_price && (
              <span
                style={{
                  textDecoration: "line-through",
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginLeft: "4px",
                  color: "red",
                }}
              >
                {courseDetails?.regular_price}
              </span>
            )}
        </span>

        {enrolled ? (
          <button
            disabled={enrolled}
            style={{
              background: "rgb(188, 196, 193)",
              color: "white",
              padding: "6px 16px",
              fontWeight: 600,
              border: "none",
              borderRadius: "4px",
            }}
          >
            Enrolled
          </button>
        ) : (
          courseDetails?.enrollOff ? <button style={{ background: "#908a8a", cursor: "not-allowed" }} disabled={true}>Enroll Off Now</button> :
            <button
              onClick={isAuthenticated ? handleEnroll : handleRedirect}
              style={{
                background: "rgb(252, 112, 112)",
                color: "white",
                padding: "6px 16px",
                fontWeight: 600,
                border: "none",
                borderRadius: "4px",
              }}
            >
              Enroll Now
            </button>
        )}
        {/* <button
          disabled={enrolled}
          onClick={isAuthenticated ? handleEnroll : handleRedirect}
          style={{
            background: 'rgb(252, 112, 112)',
            color: 'white',
            padding: '6px 16px',
            fontWeight: 600,
            border: 'none',
            borderRadius: '4px',
          }}
        >
          {enrolled ? 'Enrolled' : 'Enroll now'}
        </button> */}
      </div>

      <div className="priceCard__wrapper">
        <h2>
          <span style={{ color: "#3FC3A2" }}></span>
          <span style={{ color: "var(--primary-color)" }}>
            {courseDetails?.course_paid === false ? "Free" : <>৳ {price} </>}
            {courseDetails?.regular_price !== 0 &&
              courseDetails?.regular_price !== courseDetails?.sale_price && (
                <span
                  style={{
                    textDecoration: "line-through",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginLeft: "4px",
                    color: "red",
                  }}
                >
                  {courseDetails?.regular_price}
                </span>
              )}
          </span>
        </h2>

        {!enrolled && (courseDetails?.course_paid || bundle === "true") ? (
          <div className="coupon">
            {/* <div className="coupon__text">
              <p>Do you have coupon?</p>{' '}
              <p onClick={() => setApply(true)} className="apply">
                Apply now
              </p>
            </div> */}
            {
              <div className="applyComponent">
                <div className="input">
                  <input
                    className="w-50"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                    placeholder="If you have any COUPON"
                    type="text"
                  />
                  <button
                    style={{ cursor: applied ? "not-allowed" : "pointer" }}
                    disabled={applied ? true : false}
                    onClick={handleApply}
                  >
                    {applied ? "Applied" : "Apply"}
                  </button>
                </div>
              </div>
            }
          </div>
        ) : null}

        <div className="priceCard__button">
          {enrolled ? (
            <button style={{ background: "#908a8a", cursor: "not-allowed" }}>
              Enrolled
            </button>
          ) : (
            <> {courseDetails?.enrollOff ? <button style={{ background: "#908a8a", cursor: "not-allowed" }} disabled={true}>Enroll Off Now</button> : <button onClick={isAuthenticated ? handleEnroll : handleRedirect}>
              Enroll Now
            </button>}

              {manual && courseDetails?.course_paid === true && (
                <a
                  style={{ textAlign: "center" }}
                  rel="noreferrer"
                  target="_blank"
                  href="https://forms.gle/1zkX3yHMFDdpKuEZ7"
                >
                  Installment Payment
                </a>
              )}
            </>
          )}
          {enrolled && !courseDetails?.enrollOff ? (
            <button
              onClick={() => history.push(`/watching/${courseDetails?._id}`)}
            >
              Start Now
            </button>
          ) : ""}
        </div>
        <div className="priceCard__info">
          <h3>This course includes:</h3>

          {courseDetails?.benefits?.length > 0 ? (
            <ul className="corseInfo">
              {courseDetails?.benefits?.map((benefit, i) => {
                console.log(benefit, i);
                return <li key={i}>{benefit.benifit}</li>;
              })}
            </ul>
          ) : (
            <ul className="corseInfo">
              {bundle !== "true" ? <li>
                {bundle !== "true" && (
                  <span>{courseDetails?.course_duration} on demand video</span>
                )}
              </li> : <></>}

              <li> Life time access </li>
              <li> Certificate of completion </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default PriceCard;
