import { async } from "@firebase/util";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import "./CourseReview.css";
const CourseReview = ({ review }) => {
  console.log(review);
  const { reviews, reviewStatistics } = review;
  console.log("review",reviews, reviewStatistics);
  return (
    <div className="review_div mb-5">
      <div className="d-flex">
        <h4>
          <span
            style={{
              color: "#E59819",
              marginLeft: "20px",
            }}
            className="review_star_icon"
          >
            ★
          </span>{" "}
          {reviewStatistics?.average} Course Rating{" "}
        </h4>
        <h4 className="ms-5"> {reviewStatistics?.count} Ratings</h4>
      </div>

      <div className="row row-cols-1 row-cols-md-2 g-4">
        {reviews?.map((review) => {
          console.log(review);
          return (
            <div className="col" key={review?._id}>
              <div
                style={{
                  height: "280px",
                }}
                className="card"
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50px",
                      backgroundColor: "black",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "10px",
                      marginLeft: "10px",
                    }}
                  >
                    <h5
                      style={{
                        marginTop: "10px",
                        color: "white",
                      }}
                    >
                      {review.shortName}
                    </h5>
                  </div>
                  <div
                    style={{
                      marginTop: "12px",
                      marginLeft: "35px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {review?.student?.fullName}
                    </span>
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      {review?.rating === 5 ? (
                        <div>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                        </div>
                      ) : null}
                      {review?.rating === 4 ? (
                        <div>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span>★</span>
                        </div>
                      ) : null}
                      {review?.rating === 3 ? (
                        <div>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span>★</span>
                          <span>★</span>
                        </div>
                      ) : null}
                      {review?.rating === 2 ? (
                        <div>
                          <span className="review_star_icon">★</span>
                          <span className="review_star_icon">★</span>
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                        </div>
                      ) : null}
                      {review?.rating === 1 ? (
                        <div>
                          <span className="review_star_icon">★</span>
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                        </div>
                      ) : null}
                      <div
                        style={{
                          marginLeft: "35px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {review.createdDate.slice(0, 10)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  {review?.comment.length > 260
                    ? review?.comment.slice(0, 260) + "...."
                    : review?.comment}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourseReview;
