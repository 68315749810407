import { Result, Button, Divider } from 'antd';
import { Link } from 'react-router-dom';

function NoMatch() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Link to="/">
          <button
            style={{
              border: 'none',
              background: 'var(--primary-color)',
              color: 'white',
              padding: '10px 30px',
              borderRadius: '3px',
            }}
            type="primary"
          >
            Back Home
          </button>
        </Link>
      }
    />
  );
}

export default NoMatch;
