import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import './BlogDetails.css';
import { useParams } from 'react-router-dom';
import { getSpecificBlog } from '../../actions/blog';
import moment from 'moment';
import renderHTML from 'react-render-html';
import { Helmet } from 'react-helmet';

function BlogDetails() {
  const { slug } = useParams();
  const [loading, setLoading] = useState(true);

  const [blogDetails, setBlogDetails] = useState(null);

  useEffect(() => {
    if (slug) {
      getSpecificBlog(slug)
        .then((res) => {
          if (res?.data?.success) {
            setBlogDetails(res?.data?.data);
            setLoading(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [slug]);

  return (
    <Layout footer={true}>
      <Helmet>
        <title>{blogDetails?.title}</title>
        <meta name="description" property="og:description" content="" />
      </Helmet>
      <div className="blog__wrapper">
        <div className="container ">
          {loading ? (
            "Loading..."
          ) : (
            <>
              <div className="blog__author__title__wrapper">
                <h1>{blogDetails?.title}</h1>
                <div className="blog__author__title">
                  <p>{blogDetails?.author_name}</p>
                  <p>
                    PUBLISHED ON{" "}
                    {moment(BlogDetails?.createdDate).format("MMM Do YY")}
                  </p>
                </div>
              </div>
              <div className="blog__image__wrapper">
                <img
                  src={`${process.env.REACT_APP_DEV_URL}/api/image/download/${blogDetails?.upload_featured_image}`}
                  alt=""
                />
              </div>

              <div className="blog__details__wrapper">
                {" "}
                {renderHTML(blogDetails ? blogDetails?.post_description : "")}
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default BlogDetails;
