import React, { useState, useEffect } from 'react';
import { Progress, Tooltip } from 'antd';
import {
  getCourseCompletion,
  getSpecificCourseById,
} from '../../actions/course';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../SharedComponents/CourseCard.css';

const EnrolledCourse = ({ course }) => {
  const [courseDetails, setCourseDetails] = useState(null);
  const [courseCompletion, setCourseCompletion] = useState(0);

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    getSpecificCourseById(course?._id)
      .then((res) => {
        if (res?.data?.success) {
          setCourseDetails(res?.data?.message);
        }
      })
      .catch((err) => console.log(err));
  }, [course]);

  useEffect(() => {
    if (user?._id !== undefined && courseDetails?._id !== undefined) {
      getCourseCompletion(user?._id, courseDetails?._id).then((res) => {
        if (res?.data?.success) {
          setCourseCompletion(
            res?.data?.message === 'NaN' ? 0 : res?.data?.message
          );
        }
      });
    }
  }, [courseDetails, user]);

  const history = useHistory();

  const name =
    courseDetails?.title !== undefined
      ? courseDetails?.title
      : courseDetails?.name;
  const text = name;
  const cutName = name?.length > 0 ? name.substr(0, 56) : '';

  return courseDetails?.course_publish ? (
    <Tooltip placement="right" title={text}>
      <div
        onClick={() => history.push(`/watching/${courseDetails?._id}`)}
        className="courseCard enrolledCourse"
      >
        <div className="courseCard__wrapper">
          <div className="courseCard__wrapper__image">
            <img
              src={
                courseDetails?.upload_featured_image !== undefined
                  ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${courseDetails?.upload_featured_image}`
                  : courseDetails?.image !== undefined
                  ? `${process.env.REACT_APP_DEV_URL}/api/image/download/${courseDetails?.image}`
                  : '/course.jpg'
              }
              alt="Blog"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
            <div className="overlay"></div>
            {courseDetails?.course_paid === false && <p>Free</p>}
          </div>
          <div className="courseCard__wrapper__info">
            <div className="courseCard__wrapper__info__button__wrapper">
              <button className="button-2">Watch now</button>
            </div>

            <div style={{ margin: '14px 0px 10px 0px' }}></div>
            {courseDetails?.categoryName && (
              <p style={{ marginTop: '30px' }} className="badge">
                {courseDetails?.categoryName}
              </p>
            )}
            <div className="courseCard__name__wrapper">
              <h4>{`${cutName} ${name?.length > 56 ? '.....' : ''} `}</h4>
            </div>
          </div>
          <div style={{ padding: '20px 20px' }}>
            <Progress
              strokeColor={{
                '0%': '#108ee9',
                '100%': '#87d068',
              }}
              percent={courseCompletion}
            />
          </div>
          {/* <div className="courseCard__wrapper__action">
            <hr />
            <div className="courseCard__wrapper__action__info">
              <p>
                <span>৳</span>
                <span>
                  {courseDetails?.courseDetails_paid === false
                    ? 'Free'
                    : courseDetails?.sale_price !== undefined
                    ? courseDetails?.sale_price
                    : courseDetails?.price}
                </span>
                {courseDetails?.regular_price !== 0 &&
                  courseDetails?.regular_price !==
                    courseDetails?.sale_price && (
                    <span
                      style={{
                        textDecoration: 'line-through',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        marginLeft: '4px',
                        color: 'red',
                      }}
                    >
                      {courseDetails?.regular_price}
                    </span>
                  )}
              </p>
              {!course?.bundle && (
                <>
                  {' '}
                  <p>
                    <span>
                      <UsergroupAddOutlined />
                    </span>
                    <span>{courseDetails?.enrollement_count}</span>
                  </p>
                  <p>
                    <span>
                      <FieldTimeOutlined />
                    </span>
                    <span>{courseDetails?.course_duration}</span>
                  </p>
                </>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </Tooltip>
  ) : null;
};

export default EnrolledCourse;
