import React from 'react';
import { RightCircleOutlined } from '@ant-design/icons';
import Carousel from 'react-multi-carousel';
import { useHistory } from 'react-router-dom';
import renderHTML from 'react-render-html';
import { getAllBooks } from '../../actions/book';
import '../../pages/LandingPage/LandingPage.css';

const Book = ({ title, subtitle, seeAll }) => {
  const history = useHistory();
  const [books, setBooks] = React.useState([]);

  React.useEffect(() => {
    getAllBooks().then((res) => {
      if (res?.data?.success) {
        res?.data?.message?.map((b) => {
          if (b?.book_publish === true) {
            setBooks((pb) => [...pb, b]);
          }
        });
      }
    });
  }, []);

  const handleRedirect = (slug) => {
    history.push(`/book-details/${slug}`);
  };

  const BookComponents = (id, slug, path, title, author) => (
    <div onClick={() => handleRedirect(slug)} className="singleBook">
      <div style={{ width: '100%', height: '200px', overflow: 'hidden' }}>
        <img src={path} alt={title} width={120} height={120} />
      </div>
      <div>
        <h5>{title}</h5>
        <p>{author}</p>
      </div>
    </div>
  );

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="book">
      <div className="book__wrapper container">
        <div className="book__wrapper__intro">
          {renderHTML(title)}
          {subtitle && (
            <p>
              We believe that a reader has to learn only as much technology as
              it’s needed to fully enjoy the magic of reading. <br /> So, there
              are some magical books for you.
            </p>
          )}
        </div>
        {seeAll && books?.length > 4 && (
          <div className="book__seeAll__top">
            <button
              onClick={() => history.push('/books')}
              className="see-all book__seeAll"
            >
              See All <RightCircleOutlined style={{ fontSize: '17px' }} />
            </button>
          </div>
        )}
        <div className="book__wrapper__body">
          {books?.length > 0 ? (
            <Carousel
              infinite={true}
              renderArrowsWhenDisabled={true}
              responsive={responsive}
            >
              {books?.length > 0 &&
                books?.map((book) =>
                  BookComponents(
                    book?._id,
                    book?.slug,
                    `${process.env.REACT_APP_DEV_URL}/api/book/download/${book?.book_cover}`,
                    book?.book_name,
                    book?.author_name
                  )
                )}
            </Carousel>
          ) : (
            <p style={{ width: '100%', textAlign: 'center' }}>No books found</p>
          )}
        </div>
        <div className="book__seeAll__down">
          {books?.length > 4 && (
            <button
              onClick={() => history.push('/books')}
              className="see-all book__seeAll"
            >
              See All <RightCircleOutlined style={{ fontSize: '17px' }} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Book;
