import axios from "axios";
import { GET_CURRENT_USER, LOGOUT } from "./types";
import { signOut } from 'firebase/auth';
import goAuth from "../firebase.init";
/**
 * * This is for the auth functionality
 * * Registration
 * * Login
 */

/**
 * * Get Current User
 */

export const getCurrentUser = (id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };
  const res = await axios.get(
    `${process.env.REACT_APP_DEV_URL}/api/student/get/${id}`,
    config
  );

  if (res?.data?.success) {
    dispatch({
      type: GET_CURRENT_USER,
      payload: res?.data?.message,
    });
  }
};

/**
 * * Student Register
 */

export const studentRegister = async (content) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const body = content;
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/auth/signup/student`,
    body,
    config
  );
};

export const studentRegisterWithGoogle = async (content) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const body = content;
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/auth/google/signup/student`,
    body,
    config
  );
};

/**
 * * Student Login
 */

export const studentLogin = async (content) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "token",
  };
  const body = content;
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/auth/signin/student`,
    body,
    config
  );
};

/**
 * * Get Student Refresh Token
 */

export const studentRefreshToken = async (content) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const body = content;
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/auth/refreshtoken/student`,
    body,
    config
  );
};

/**
 * * Student Logged out
 */

export const loggedOut = () => async (dispatch) => {
  localStorage.removeItem("auth_refresh_token");
  localStorage.removeItem("auth_access_token");
  sessionStorage.removeItem("redirectURL");
  localStorage.removeItem("userId");
  dispatch({
    type: LOGOUT,
    payload: null,
  });
};

export const loggedOutFromGoogle = () => async (dispatch) => {
  localStorage.removeItem("userId");
  signOut(goAuth);
  dispatch({
    type: LOGOUT,
    payload: null,
  });

  // console.log('logout');
  // localStorage.removeItem('oauth2_ss::http://localhost:3000::1::DEFAULT::_ss_');
  // caches.keys().then((names) => {
  //   names.forEach((name) => {
  //     caches.delete(name);
  //   });
  // });
  // alert('Complete Cache Cleared')

  // var cookies = document.cookie.split(";");

  // for (var i = 0; i < cookies.length; i++) {
  //     var cookie = cookies[i];
  //     var eqPos = cookie.indexOf("=");
  //     var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
  //     document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  // }

  // Cookies.remove('G_AUTHUSER_H')

  //   const auth2 = gapi.auth2.getAuthInstance();

  // if (auth2 != null) {
  //     auth2.signOut().then(
  //          auth2.disconnect().then(console.log('LOGOUT SUCCESSFUL'))
  //      )
  //    }

  // dispatch({
  //   type: LOGOUT,
  //   payload: null,
  // })
};

//** Forgot Password Mail send endpoint */
export const forgotPassword = async (email) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const body = { email };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/password-forget`,
    body,
    config
  );
};

//** Forgot Password Mail send endpoint */
export const authResetPassword = async (email, code, newPassword) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const body = { email, code, newPassword };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/password-reset`,
    body,
    config
  );
};

//** Verify Email */
export const verifyEmail = async (token) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/email-verification/${token}`,
    config
  );
};

//** Verify Email */
export const ResendEmail = async (email) => {
  const config = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  const body = { email };
  return await axios.post(
    `${process.env.REACT_APP_DEV_URL}/api/email-verification`,
    body,
    config
  );
};
